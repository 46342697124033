import React from "react";
import { fetchApi } from "../../Network/NetworkService";
import { notif } from "../Ui/Notification";
import { Grid, Row, Col, Panel, Tabs, Tab } from "react-bootstrap";
import ProfilePanel from "../Ui/ProfilePanel";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default class TestDetails extends React.Component {
  constructor() {
    super();

    this.state = {
      test: null,
      logs: [],
      charge: {},
      testers: [],
      biologist: "",
    };

    this.handleRefund = this.handleRefund.bind(this);
    this.handleResult = this.handleResult.bind(this);
  }

  componentDidMount() {
    fetchApi(`/tests/${this.props.match.params.id}`)
      .then((res) => {
        this.setState({ test: res.test });
        if (this.state.test.testerId) {
          fetchApi(`/charges/${this.state.test.transactionId}`)
            .then((res) => {
              this.setState({ charge: res.charge });
            })
            .catch((err) => console.error(err.message));
        }
      })
      .catch((err) =>
        notif.addNotification(
          "Erreur",
          "Erreur de recuperation des donnees docteur: " + err,
          "warning"
        )
      );

    fetchApi("/testers")
      .then((res) =>
        this.setState({
          testers: res.testers.filter((e) => e.activation === true),
        })
      )
      .catch((err) =>
        notif.addNotification(
          "Erreur",
          "Erreur de recuperation des donnees docteur: " + err,
          "warning"
        )
      );
  }

  handleCancel() {
    const swalWithBootstrapButtons = Swal.mixin({
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    });

    swalWithBootstrapButtons({
      title: "Annulation d'une demande",
      text: `Voulez vous vraiment annuler cette requete ? Cette operation est irreversible!`,
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, modifier",
      cancelButtonText: "Non, annuler!",
      reverseButtons: true,
    }).then((result) => {
      fetchApi(`/tests/${this.state.test._id}`, {
        method: "PUT",
        body: JSON.stringify({
          state: "canceled",
        }),
      }).then(() => {
        let newtest = this.state.test;
        newtest.state = "canceled";
        this.setState({
          test: newtest,
        });

        swalWithBootstrapButtons("Modifié!", "Requete annulé", "success");
      });
    });
  }

  handleRefund() {
    fetchApi(`/charges/refunds/${this.state.test.transactionId}`, {
      body: JSON.stringify({ reqId: this.state.test._id }),
      method: "POST",
    })
      .then(() => {
        const items = this.state.charge;
        items.refunded = true;
        this.setState({ charge: items });

        notif.addNotification(
          "Derniere connextion",
          "Remboursement bien effectué !"
        );
      })
      .catch((err) => {
        notif.addNotification(
          "Erreur",
          "Erreur de remboursement : " + err,
          "warning"
        );
      });
  }

  handleResult() {
    const swalWithBootstrapButtons = Swal.mixin({
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false,
    });

    swalWithBootstrapButtons({
      title: "Évaluation d'un test",
      text: `Ce test est-il positive ?`,
      input: "select",
      inputOptions: ["Oui", "Non"],
      type: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui, modifier",
      cancelButtonText: "Non, annuler!",
      reverseButtons: true,
    }).then((result) => {
      const positiveResult = parseInt(result.value) === 0;
      fetchApi(`/tests/${this.state.test._id}`, {
        method: "PUT",
        body: JSON.stringify({
          positiveResult,
          biologist: this.state.biologist,
        }),
      }).then(() => {
        let newtest = this.state.test;
        newtest.positiveResult = positiveResult;
        this.setState({
          test: newtest,
        });

        swalWithBootstrapButtons(
          "Modifié!",
          "L'utilisateur a été informé par SMS",
          "success"
        );
      });
    });
  }

  render() {
    if (!this.state.test) {
      return <div>Loading...</div>;
    }

    const test = this.state.test;

    let acceptedTime = null;
    if (test.acceptedAt) {
      acceptedTime = new Date(test.acceptedAt) - new Date(test.createdAt);

      let seconds = Math.floor(acceptedTime / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      acceptedTime = `${days} jours, ${hours % 24} heures, ${minutes %
        60} minutes et ${seconds % 60} secondes`;
    }

    return (
      <div className="pT-25">
        <Row>
          <Col lg={3} xs={12}>
            <button
              onClick={() => this.props.history.goBack()}
              className="backBtn"
            >
              <i className="icon-arrow-left" />
            </button>
          </Col>
        </Row>
        <Row>
          <Col lg={3} xs={12}>
            <h2>Patient</h2>
            <ProfilePanel {...test.userId}>
              <Grid fluid={true}>
                {test.positiveResult !== undefined && (
                  <Row>
                    <h3>
                      {test.positiveResult === false ? "Négative" : "Positive"}
                    </h3>
                  </Row>
                )}
                <Row>
                  <Col md={12}>
                    <table className="table">
                      <tbody>
                        <tr>
                          <td>Sexe</td>
                          <td>{test.userId.gender}</td>
                        </tr>
                        <tr>
                          <td>Age</td>
                          <td>
                            {!test.userId.birth_date ||
                              new Date(
                                new Date() - new Date(test.userId.birth_date)
                              ).getFullYear() - 1970}
                          </td>
                        </tr>
                        <tr>
                          <td>Tel</td>
                          <td>{test.userId.phone}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>{test.userId.email}</td>
                        </tr>
                        {test.userId.vitalCard && (
                          <tr>
                            <td>Numéro de carte vitale</td>
                            <td>{test.userId.vitalCard}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Grid>
            </ProfilePanel>
          </Col>
          <Col lg={9} xs={12}>
            <h2>Test</h2>
            <Panel>
              <Panel.Body style={{ paddingTop: 0 }}>
                <Tabs defaultActiveKey={1} className="custom" id="tabs">
                  <Tab eventKey={1} title="Details">
                    <Row>
                      <Col md={12}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Date du test</td>
                              <td>
                                {dateFormat(test.dateStart, "dd/mm/yy HH:MM")}
                              </td>
                            </tr>
                            <tr>
                              <td>Date de creation</td>
                              <td>
                                {dateFormat(test.createdAt, "dd/mm/yy HH:MM")}
                              </td>
                            </tr>
                            <tr>
                              <td>Derniere mise a jour</td>
                              <td>
                                {dateFormat(test.updatedAt, "dd/mm/yy HH:MM")}
                              </td>
                            </tr>
                            <tr>
                              <td>Type</td>
                              <td>{test.type}</td>
                            </tr>
                            <tr>
                              <td>Nombre de test</td>
                              <td>{test.quantity}</td>
                            </tr>
                            <tr>
                              <td>Statut</td>
                              <td>{test.state}</td>
                            </tr>
                            <tr>
                              <td>Testeur</td>
                              <td>
                                {!test.testerId || (
                                  <Link to={`/testers/${test.testerId._id}`}>
                                    {test.testerId.FName} {test.testerId.LName}
                                  </Link>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Adresse</td>
                              <td>{test.address.value}</td>
                            </tr>
                            <tr>
                              <td>Etage</td>
                              <td>{test.address.floorFlat}</td>
                            </tr>
                            <tr>
                              <td>Code</td>
                              <td>{test.address.doorCode}</td>
                            </tr>
                            <tr>
                              <td>Informations supplémentaires de livraison</td>
                              <td>{test.address.deliveryInstructions}</td>
                            </tr>
                            <tr>
                              <td>Temps d'acceptation</td>
                              <td>{acceptedTime}</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey={2} title="Preuve Test">
                    <Row>
                      {test.resultProof ? (
                        <Col md={12}>
                          {test.positiveResult === undefined && (
                            <Row>
                              <Col md={12}>
                                <input
                                  type="text"
                                  name="biologist"
                                  style={{
                                    outline: 0,
                                    background: "#f2f2f2",
                                    width: "100%",
                                    border: 0,
                                    margin: "0 0 15px",
                                    padding: "15px",
                                    boxSizing: "border-box",
                                    fontSize: "14px",
                                  }}
                                  placeholder="Prénom et nom de l'évaluateur"
                                  value={this.state.biologist}
                                  onChange={(e) =>
                                    this.setState({ biologist: e.target.value })
                                  }
                                />
                                <button
                                  className="EvaluateTest"
                                  onClick={this.handleResult}
                                >
                                  Évaluer le test
                                </button>
                              </Col>
                            </Row>
                          )}
                          <img src={test.resultProof} alt="Resultat du test" />
                        </Col>
                      ) : (
                        <Col md={12}>
                          <h2>Ce test n'a pas encore été prouvé.</h2>
                        </Col>
                      )}
                    </Row>
                  </Tab>
                  <Tab eventKey={3} title="Fichiers">
                    <Row>
                      <Col md={12}>
                        <table className="table">
                          <tbody>
                            {(() => {
                              if (test.attachments.length > 0) {
                                return test.attachments.map(
                                  (element, index) => {
                                    return (
                                      <tr key={element.name}>
                                        <td>{element.type}</td>
                                        <td>
                                          <a href={element.path}>
                                            {element.name}
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  }
                                );
                              }
                              return (
                                <tr>
                                  <td>
                                    Il n'y a pas de fichiers lié à ce test
                                  </td>
                                </tr>
                              );
                            })()}
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Tab>
                  <Tab eventKey={4} title="Paiement">
                    <Row>
                      <Col md={12}>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>Status</td>
                              <td>
                                {(() => {
                                  if (this.state.charge.refunded) {
                                    return "Remboursé";
                                  }
                                  if (this.state.charge.paid) {
                                    return "Payé";
                                  } else {
                                    return "Non Payé";
                                  }
                                })()}
                              </td>
                            </tr>
                            <tr>
                              <td>Montant</td>
                              <td>{this.state.charge.amount / 100 + " €"}</td>
                            </tr>
                            <tr>
                              <td>Email de facturation</td>
                              <td>{this.state.charge.receipt_email}</td>
                            </tr>
                            <tr>
                              <td>Facture n°</td>
                              <td>{this.state.charge.receipt_number}</td>
                            </tr>
                            <tr
                              className={
                                this.state.charge.dispute ? "" : "hidden"
                              }
                            >
                              <td>Opposition Bancaire</td>
                              <td>
                                L'utilisateur a fait opposition sur le paiement
                              </td>
                            </tr>
                            <tr>
                              <td>Remboursement</td>
                              <td>
                                <button
                                  className={
                                    this.state.charge.refunded
                                      ? "user-lock lock disable"
                                      : "user-lock lock"
                                  }
                                  disabled={this.state.charge.refunded}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Êtes-vous sûr de vouloir rembourser cet utilisateur ?"
                                      )
                                    )
                                      this.handleRefund();
                                  }}
                                >
                                  Rembourser l'utilisateur
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                    </Row>
                  </Tab>

                  <Tab eventKey={5} title="Modification">
                    <Row>
                      <Col md={12}>
                        <table className="table">
                          <tbody>
                            {test.testerId && (
                              <tr>
                                <td>Testeur attribué</td>
                                <td>
                                  {test.testerId.LName} {test.testerId.FName}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <button
                          className={
                            this.state.test.state === "approved" ||
                            this.state.test.state === "canceled"
                              ? "user-lock lock disable"
                              : "user-lock lock"
                          }
                          disabled={
                            this.state.test.state === "approved" ||
                            this.state.test.state === "canceled"
                          }
                          onClick={() => {
                            this.handleCancel();
                          }}
                        >
                          Annuler la demande
                        </button>
                      </Col>
                    </Row>
                  </Tab>
                </Tabs>
              </Panel.Body>
            </Panel>
          </Col>
        </Row>
      </div>
    );
  }
}
