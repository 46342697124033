import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Grid } from 'react-bootstrap';

import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from '../Pages/Dashboard';
import Footer from './Footer';
import { NotificationManager, notif } from '../Ui/Notification';
import Doctors, { WaitingDoctors } from '../Pages/Doctors';
import Tests, { TestsWaiting, TestsApproving } from '../Pages/Tests';
import TestDetail from '../Pages/TestDetail';

import DoctorProfile from '../Pages/DoctorProfile';
import Testers, { WaitingTesters } from '../Pages/Testers';
import TesterProfile from '../Pages/TesterProfile';
import withAuth from '../../Services/withAuth';
import AuthService from '../../Services/AuthService';

import Users from '../Pages/Users';
import UserProfile from '../Pages/UserProfile';
import { fetchApi } from '../../Network/NetworkService';
import dateFormat from 'dateformat';
import Requests from '../Pages/Requests';
import RequestDetails from '../Pages/RequestDetails';
import Pharmacy from '../Pages/Pharmacy';
import Notification from '../Pages/Notification';
import DoctorActive from '../Pages/DoctorActive';
import Pharmacist from '../Pages/Pharmacist';
import CustomerValue from '../Ui/CustomerValue';
import RequestAnalytics from '../Pages/RequestAnalytics';
import CohorteClients from '../Pages/CohorteClients';
import UserAnalytics from '../Ui/UserAnalytics';
import { TrackingIncomplete } from '../Ui/TrackingIncomplete';
import { TrackingWaiting } from '../Ui/TrackingWaiting';
import PrePharmacy from '../Pages/PrePharmacy';
import { CohortDoctor } from '../Ui/CohortDoctor';
import Newsletters from '../Pages/Newsletters';
import CampagneSdr from '../Pages/CampagneSdr';
import Acquisition from '../Pages/Acquisition';
import ConversionTunnel from '../Pages/ConversionTunnel';
import { TrackingCancel } from '../Ui/TrackingCancel';
import TrackingSuivi from '../Pages/TrackingSuivi';
import Feedback from '../Pages/Feedback';
import FeedbackDetails from '../Pages/FeedbackDetails';

class Layout extends React.Component {
    constructor() {
        super();

        this.state = {
            minHeight: 0
        };

        this.Auth = new AuthService();

    }


    componentDidMount() {

        fetchApi(`/lastConnection`).then((res) => {
            notif.addNotification(
                'Derniere connextion',
                res.user.username + ' a ' + dateFormat(res.user.login, 'dd/mm/yy HH:MM')
            );
        });
    }

    render() {
        const profile = this.Auth.getProfile();
        const role = profile.role || 2;
        return (
            <div>
                <Header history={this.props.history} />
                <Sidebar role={role} />
                <div
                    className="wrapper"
                    style={{ minHeight: '100vh' }}
                >
                    {
                        role === 5 ?
                        <div className="ctr">
                            <Grid>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        component={() => <Dashboard role={role} utm_source={profile.utm_source} />}
                                    />
                                    <Route exact path="/requests/detail/:id" component={RequestDetails} />
                                    <Route exact path="/requests/:page?" component={() => <Requests match={this.props.match} history={this.props.history} role={role} utm_source={profile.utm_source} />} />

                                </Switch>
                            </Grid>
                        </div>
                        :
                        <div className="ctr">
                        <Grid>
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    component={() => <Dashboard role={role} utm_source={profile.utm_source} />}
                                />
                                <Route
                                    exact
                                    path="/doctors/waiting"
                                    component={WaitingDoctors}
                                />
                                <Route exact path="/doctors/active" component={DoctorActive} />
                                <Route exact path="/doctors/detail/:id" component={DoctorProfile} />
                                <Route exact path="/users/detail/:id" component={UserProfile} />
                                <Route exact path="/requests/detail/:id" component={RequestDetails} />
                                <Route exact path="/feedback" component={Feedback} />
                                <Route exact path="/feedback/detail/:id" component={FeedbackDetails} />

                                {/* <Route exact path="/feedback/detail/:id" component={feed} /> */}
                                <Route
                                    exact
                                    path="/tests/toapproved"
                                    component={TestsApproving}
                                />
                                <Route exact path="/tests/:id" component={TestDetail} />
                                <Route exact path="/testers" component={Testers} />
                                <Route
                                    exact
                                    path="/testers/waiting"
                                    component={WaitingTesters}
                                />
                                <Route exact path="/campagne-sdr" component={CampagneSdr}  />
                                <Route exact path="/acquisition" component={Acquisition}  />
                                <Route exact path="/suivi" component={() => <TrackingSuivi {...this.props}/>}  />
                                <Route exact path="/testers/:id" component={TesterProfile} />
                                <Route exact path="/requests/:page?" component={() => <Requests match={this.props.match} history={this.props.history} role={role} utm_source={profile.utm_source} />} />
                                <Route exact path="/doctors/:page?" component={Doctors} />
                                <Route exact path="/users/:page?" component={Users} />
                                <Route exact path="/pharmacy" component={Pharmacy} />
                                <Route exact path="/pharmacy/pre-inscri" component={PrePharmacy} />
                                <Route exact path="/notification" component={Notification} />
                                <Route exact path="/newsletters" component={() => <Newsletters history={this.props.history} />} />
                                <Route exact path="/pharmacist" component={Pharmacist} />
                                <Route exact path="/analytics/request" component={() => <RequestAnalytics history={this.props.history} />} />
                                <Route exact path="/analytics/user" component={() => <UserAnalytics history={this.props.history} />} />
                                <Route exact path="/analytics/customer-value" component={() => <CustomerValue history={this.props.history} />} />
                                <Route exact path="/analytics/cohorte-clients" component={() => <CohorteClients history={this.props.history} />} />
                                <Route exact path="/analytics/tracking-incomplete" component={() => <TrackingIncomplete history={this.props.history} />} />
                                <Route exact path="/analytics/tracking-waiting" component={() => <TrackingWaiting history={this.props.history} />} />
                                <Route exact path="/analytics/tracking-cancel" component={() => <TrackingCancel history={this.props.history} />} />
                                <Route exact path="/analytics/doctor" component={() => <CohortDoctor history={this.props.history} />} />
                                <Route exact path="/conversion-tunnel" component={ConversionTunnel} />
                            </Switch>
                        </Grid>
                    </div>

                    }

                    <Footer />
                </div>
            </div>
        );
    }
}

export default withAuth(Layout);
