import React, {useState, useEffect} from 'react';
import {  Panel, Row, Col } from 'react-bootstrap';
import isoDate from '../../Services/toIsoDate.js';
import { notif } from './Notification';
import { fetchApi } from '../../Network/NetworkService.jsx';


import dateFormat from 'dateformat';
import IconTooltip from './IconTooltip';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactEcharts from 'echarts-for-react';

const sources = ['google', 'naturel', 'skycall', 'skycall-voice', 'skycall-api', 'crechmed', 'bing', 'medifil', 'gescall', , 'cortel'];
const titles = [
    { value: "not-online", label: "Impossible à distance" },
    { value: "by-doctor", label: "Par médecin" },
    { value: "by-patient-with-fee", label: "Par patient avant délais" },
    { value: "by-patient-no-fee", label: "Par patient après délais" },
]

export const TrackingCancel = props => {

    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [comparison, setComparison] = useState({value: 'last-month', label: 'Mois précédent'});
    const [doctors, setDoctors] = useState({});
    const [canceledRequests, setCanceledRequests] = useState([]);
    const [requests, setRequests] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);

    const setOptions = (valueDatas, nameDatas) => {
        let option = {
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            series: [
                {
                  name: '',
                  type: 'pie',
                  radius: '50%',
                  data: [],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
        };
        if (!valueDatas) {
            return option;
        }
       const datas = valueDatas.map((el, i) => {
        return {value: el, name: nameDatas[i]}
    })
   
        option.series[0].data = datas
       return option;
    };

    const getRequests = () => {

        let startDate = range[0];
        let endDate= range[1];

        fetchApi(`/requests/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}`)
            .then((res) => {
                const data = res.requests.filter(el => el.state === "canceled" || el.notOnlineReason);

                setCanceledRequests(data);
                setRequests(res.requests);
                setPage(1);
                setTotalPage(Math.ceil(data.filter(el => el.notOnlineReason).length / 10))

                const doctorList = {};
                data.filter(el => el.doctorId).map(el => {
                    if (doctorList[`${el.doctorId.FName} ${el.doctorId.LName}`]) {
                        doctorList[`${el.doctorId.FName} ${el.doctorId.LName}`]++;
                    } else {
                        doctorList[`${el.doctorId.FName} ${el.doctorId.LName}`] = 1
                    };
                });   
                setDoctors(doctorList); 
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
    }

    useEffect(() => {
        getRequests();
    }, []);

    const getData = (value) => {
        if (value === 'by-doctor') {
            return canceledRequests.filter(el => !el.notOnlineReason && el.canceledByDoctor).length
        }
        if (value === 'by-patient-with-fee') {
            return canceledRequests.filter(el => !el.notOnlineReason && !el.canceledByDoctor && el.paidAmount === "10.00").length
        }
        if (value === 'by-patient-no-fee') {
            return canceledRequests.filter(el => !el.notOnlineReason && !el.canceledByDoctor && el.paidAmount === "0").length
        }
        if (value === "not-online") {
            return canceledRequests.filter(el => el.notOnlineReason).length
        }
    }

    const getDataBySource = (arr, source) => {
        const data = [...arr];
        if (source === 'total') {
            return data.length;
        } else if (source === "skycall") {
            return data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice")).length;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).length;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").length;
        } else if (source === "naturel") {
            return data.filter(el=>el.utm_source === null && el.utm_pharmacy === null).length;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).length;
        } else {
            return data.filter(el=>el.utm_source === source).length
        }
    }

    const displayedDatas = () => {
        const data = [...canceledRequests.filter(el => el.notOnlineReason)].slice((page-1)*10, (page-1)*10+10);
        return data;
    }



    return (
        <div className='r-analytics'>
        <Panel>
            <Panel.Heading className='heading'>
                <h2>Tracking Requêtes Annulées</h2>
                <div>
                    <h6>Compteur du {' '}</h6>
                        <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                         <button className='submit-button' onClick={() => {
                     getRequests();
                    }}>Chercher</button>
                </div>
               
                <div>          
                </div>
            </Panel.Heading>
            <Panel.Body>
                <div>
                    <h1>Total: {canceledRequests.length}<span>{'  '}({(canceledRequests.length / requests.length * 100).toFixed(2)} % sur total)</span></h1>
                </div>
                
                <div>
                    <h1>Détail</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Nombre</th>
                                        <th>Taux</th>
                                    </tr>
                                   
                                    
                                </thead>
                                <tbody>
                                    {
                                        titles.map(el => <tr key={el.value}>
                                            <td>{el.label}</td>
                                            <td>{getData(el.value)}</td>
                                            <td>{canceledRequests.length > 0 ? (getData(el.value) / canceledRequests.length *100).toFixed(2): 0} %</td>
                                        </tr>)
                                    }
                                    <tr>
                                        <td>Autres</td>
                                        <td>{canceledRequests.length - titles.reduce((acc,val) => acc + getData(val.value),0)}</td>
                                        <td>{canceledRequests.length > 0 ? ((canceledRequests.length - titles.reduce((acc,val) => acc + getData(val.value),0)) / canceledRequests.length *100).toFixed(2): 0} %</td>
                                    </tr>
                                
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions(
                                    titles.map(el => getData(el.value) / canceledRequests.length),
                                   [...titles.map(el => {return el.label})]
                                )}
                                style={{ height: '300px', width: '100%' }}
                            />  
                        </Col> 
                    </Row>
                </div>

                <div>
                    <h1>Par source d'acquisition</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <thead>
                                    <tr>
                                        <th>Source</th>
                                        <th>Nombre</th>
                                        <th>Taux</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sources.filter(el => getDataBySource(canceledRequests, el) > 0).map(el => <tr key={el}>
                                            <td>{el}</td>
                                            <td>{getDataBySource(canceledRequests, el)}</td>
                                            <td>{canceledRequests.length > 0 ? (getDataBySource(canceledRequests, el) / canceledRequests.length *100).toFixed(2): 0} %</td>
                                        </tr>)
                                    }                             
                                </tbody>
                            </table>  
                        </Col>  
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions(sources.map(el => getDataBySource(canceledRequests, el) / canceledRequests.length), [...sources])}
                                style={{ height: '300px', width: '100%' }}
                            />   
                        </Col> 
                    </Row>
                </div>
                <div>
                    <h1>Par médecin</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <thead>
                                    <tr>
                                        <th>Médecin</th>
                                        <th>Nombre</th>
                                        <th>Taux</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(doctors).map(el => <tr key={el}>
                                            <td>{el}</td>
                                            <td>{doctors[el]}</td>
                                            <td>{canceledRequests.length > 0 ? (doctors[el] / canceledRequests.filter(el => el.doctorId).length *100).toFixed(2): 0} %</td>
                                        </tr>)
                                    }                             
                                </tbody>
                            </table>  
                        </Col>  
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={
                                    setOptions(Object.keys(doctors).map(el => doctors[el] / canceledRequests.filter(el => el.doctorId).length),
                                    [...Object.keys(doctors)])
                                }
                                style={{ height: '300px', width: '100%' }}
                            />   
                        </Col> 
                    </Row>
                </div>
                {
                    displayedDatas().length > 0 &&
                    <div>
                        <h1>Listes des raisons</h1>
                        <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>
                            <p>Page <strong>{page}</strong> /{' '}{totalPage}</p>               
                            <div>
                                {page > 1 && (
                                    <IconTooltip
                                        icon="fa fa-chevron-left"
                                        text="Page précédente"
                                        onClick={() => setPage(page - 1)}
                                    />
                                )}
                                {page < totalPage && (
                                    <IconTooltip
                                        icon="fa fa-chevron-right"
                                        text="Page suivante"
                                        onClick={() => setPage(page + 1)}
                                    />
                                )}
                            </div>

                            
                                        
                        </div>
                        <div className="table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Utilisateur</th>
                                                    <th>Date</th>
                                                    <th>Médecin</th>
                                                    <th>Raison</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayedDatas().map((d) => (
                                                    <tr key={d._id}>
                                                        <td>
                                                            {d.userId.FName} {d.userId.LName}
                                                        </td>
                                                        <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                        <td>{d.doctorId.FName} {d.doctorId.LName}</td>
                                                        <td>{d.notOnlineReason}</td>
                                                    
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                    </div>
}
                
                
            </Panel.Body>
        </Panel>
        </div>
    );
};
  
