import FileSaver from 'file-saver';
import { utils, write } from 'xlsx';

export const exportAsExcel = (apiData, fileName, cols) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
  
    const exportToCSV = (datas, file) => {
        const ws = utils.json_to_sheet(datas);
        const wsrows =  [];
        const wscols = cols;        
        
        for(let i = 0; i <= datas.length; i++ ) { 
            const wsrObj = { hpt: 30 };

            if (i === 0) {
                wsrObj.hpt = 20;
            }

            wsrows.push(wsrObj);
        }

        ws['!rows'] = wsrows;
        ws['!cols'] = wscols;        

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, file + fileExtension);
    };

    return exportToCSV(apiData, fileName);
};