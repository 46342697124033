import React from 'react';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { Grid, Row, Col, Panel, Tabs, Tab } from 'react-bootstrap';
import ProfilePanel from '../Ui/ProfilePanel';
import Swal from 'sweetalert2';
import dateFormat from 'dateformat';

const TesterData = props => (
    <div className="DoctorData">
        <table className="table">
            <tbody>
                <tr>
                    <td
                        colSpan="2"
                        style={{
                            textAlign: 'center',
                            textDecoration: 'underline'
                        }}
                    >
                        <strong>Informations Générale</strong>
                    </td>
                </tr>
                <tr>
                    <td>Prénom</td>
                    <td>{props.FName}</td>
                </tr>
                <tr>
                    <td>Nom</td>
                    <td>{props.LName}</td>
                </tr>
                <tr>
                    <td>Type de testeur</td>
                    <td>{props.type}</td>
                </tr>

                <tr>
                    <td>Email</td>
                    <td>{props.email}</td>
                </tr>
                <tr>
                    <td>Téléphone</td>
                    <td>{props.phone}</td>
                </tr>
                <tr>
                    <td>Adresse Postale</td>
                    <td>{props.address}, {props.zipcode} {props.city}, {props.country}</td>
                </tr>
                <tr>
                    <td>Derniere connexion</td>
                    <td>{dateFormat(props.lastLoginDate, 'dd/mm/yy HH:MM')}</td>
                </tr>
                <tr>
                    <td>Date de creation du compte</td>
                    <td>{dateFormat(props.createdAt, 'dd/mm/yy HH:MM')}</td>
                </tr>
            </tbody>
        </table>
    </div>
);

const testerActive = (
    <div className="doctorActive">
        <i className="fa fa-check" />
    </div>
);

const TesterWaiting = props => (
    <button className="DoctorWaiting" onClick={props.handleClick}>
        Activer
    </button>
);

export default class TesterProfile extends React.Component {
    constructor() {
        super();

        this.state = {
            tester: null
        };

        this.handleActivate = this.handleActivate.bind(this);
    }

    componentDidMount() {
        fetchApi(`/testers/${this.props.match.params.id}`)
            .then(res => this.setState({ tester: res.tester }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees testeur: ' + err,
                    'warning'
                )
            );
    }

    handleActivate() {
        if (!this.state.tester) {
            return;
        }

        const tester = this.state.tester;

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment activer ${tester.FName} ${
                tester.LName
            }?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi('/testers/activate', {
                    method: 'PUT',
                    body: JSON.stringify({
                        testerId: tester._id,
                        status: true
                    })
                }).then(() => {
                    tester.activation = true;

                    this.setState({
                        tester: tester
                    });

                    swalWithBootstrapButtons(
                        'Active!',
                        'Testeur active',
                        'success'
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Operation annulee',
                    'error'
                );
            }
        });
    }

    render() {
        if (!this.state.tester) {
            return <div>Loading...</div>;
        }

        return (
            <div className="pT-25">
                <Row>
                    <Col lg={3} xs={12}>
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="backBtn"
                        >
                            <i className="icon-arrow-left" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <ProfilePanel {...this.state.tester}>
                            <Grid fluid={true}>
                                <Row>
                                    <Col xs={12}>
                                        {this.state.tester.activation ? (
                                            testerActive
                                        ) : (
                                            <TesterWaiting
                                                handleClick={
                                                    this.handleActivate
                                                }
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Grid>
                        </ProfilePanel>
                    </Col>
                    <Col lg={9} xs={12}>
                        <Panel>
                            <Panel.Body style={{ paddingTop: 0 }}>
                                <Tabs
                                    defaultActiveKey={1}
                                    className="custom"
                                    id="tabs"
                                >
                                    <Tab eventKey={1} title="Profil">
                                        <Row>
                                            <Col md={12}>
                                                <TesterData
                                                    {...this.state.tester}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
