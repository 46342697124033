import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import dateFormat from 'dateformat';


import '../../Assets/Pages/campagneSdr.css';

import { fetchApi } from '../../Network/NetworkService';
import toIsoDate from '../../Services/toIsoDate';
import { notif } from '../Ui/Notification';
import { endpoint, rqState } from '../../Constants';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import IconTooltip from '../Ui/IconTooltip';
import AuthService from '../../Services/AuthService';

const typeStates = [
    { value: '', label: 'Tous' },
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

const userOpt = [
    { value: 'new', label: 'Nouveau' },
    { value: 'recurrent', label: 'Récurrent' },
]

const CampagneSdr = () => {
    const [option, setOption] =  useState('');
    const [mailOption, setmailOption] =  useState('');
    const [reqState, setReqState] = useState('waiting');
    const [reqType, setReqType] = useState('');
    const [dateRange, setDateRange] = useState([new Date('2022-01-01').toISOString(), new Date('2022-02-01').toISOString()])
    const [doctorList, setDoctorList] = useState([])
    const [userList, setUserList] = useState([]);
    const [relanceHist, setRelanceHist] = useState([])
    const [docHistSel, setDocHistSel] = useState(new Set())
    const [dateHistSel, setDateHistSel] = useState(new Map())


    const [doctorSelected, setDoctorSelected] = useState(null);
    const [userType, setUserType] = useState('new');
    const [value, setValue] = useState({
        email: '',
        phone: '',
        subj: '',
        msg: ''
    });
    const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
    const [loading, setLoading] = useState(false);
    const [userLoading, setUserLoading] = useState(false);
    const [sendProgress, setSendProgress] = useState('0');

    const reset = () => {
        setValue({
            email: '',
            phone: '',
            msg: '',
            subj: ''
        })
        setDoctorSelected([]);
        setmailOption('')
    }

    const getDoctors = () => {
        fetchApi(`/campagne-sdr/doctorList`)
        .then((res) => {
            setDoctorList(res.doctors)
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setLoading(false);
        });
    }

    const getUsers = () => {
        setUserLoading(true)
        fetchApi(`/campagne-sdr/userList`, {
            method: 'POST',
            body: JSON.stringify({
                date: dateRange,
                doctorSelected,
            })
        })
        .then((res) => {
            setUserList(res.users)
            setUserLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur de recuperation',
                'warning'
            )
            setUserLoading(false);
        });
    }

    const getRelanceHist = (id, dateId) => {
        
        setUserLoading(true)
        fetchApi(`/campagne-sdr/relanceHistory${id ? `/${id}` : ''}${dateId ? `?dateId=${dateId}` : ''}`)
        .then((res) => {
            if (!id) {
                setRelanceHist(res.history)
            }
            else {
                const arr = new Map(dateHistSel);
                if (arr.has(dateId)) {
                    arr.delete(dateId)
                }
                else arr.set(dateId, { id, show: true, users: res.users, page: 1, countPage: res.countPage, countNewReq: res.countNewReq })

                setDateHistSel(arr)
            }
            setUserLoading(false);
        })
        .catch((e) => {
            console.log(e);
            
            notif.addNotification(
                'Erreur',
                'Erreur de recuperation',
                'warning'
            )
            setUserLoading(false);
        });
    }

    useEffect(() => {
        getDoctors()
    }, [])

    const memoDoctorSelected = useMemo(() => doctorSelected, [doctorSelected])
    const memoDateRange = useMemo(() => dateRange, [dateRange])

    useEffect(() => {                
        if ((option === 'relance' && !Array.isArray(memoDoctorSelected ) && !!memoDateRange)) {
            getUsers()
        } else {
            setUserList([])
        }
        
    }, [memoDoctorSelected, memoDateRange, option])    

    const sendRelance = async () => {
        setLoading(true)
        const sendEvent = new EventSource(`${endpoint}/campagne-sdr/sendMailProgress?token=${new AuthService().getToken()}`)
        sendEvent.onmessage = (ev) => {
            if (ev.data === 'start') {
                fetchApi(`/campagne-sdr/sendMessage/relance`, {
                    method: 'POST',
                    body: JSON.stringify({
                        users: userList,
                        doctorSelected,
                        ...value
                    })
                })
                .catch((e) => {
                    notif.addNotification(
                        'Erreur',
                        'Erreur d\'envoie !',
                        'warning'
                    )
                    sendEvent.close()
                })
            }
            else {
                setSendProgress(ev.data)

                if (ev.data === '100') {
                    setLoading(false)
                    setSendProgress('0')
                    notif.addNotification(
                        'Success',
                        'Message envoyé !',
                        'success'
                    )
                    sendEvent.close()
                }
            }
        }

        sendEvent.onerror = () => {
            setLoading(false)
            setSendProgress('0')
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
        }
    }

    const send = () => {
        setLoading(true);
        fetchApi(`/campagne-sdr/sendMessage/${option}${mailOption ? `/${mailOption}` : ''}`, {
            method: 'POST',
            body: JSON.stringify({
                ...value,
                ...(mailOption === 'perRequest' ? { state: reqState, date: toIsoDate(date, 0, 1), type: reqType } : {}),
                ...(mailOption === 'perUser' ? { userType, date: toIsoDate(date + '-01', 0, 1) } : {}),
            })
        })
        .then(() => {
            notif.addNotification(
                'Success',
                'Message envoyé !',
                'success'
            )
            setLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Erreur d\'envoie !',
                'warning'
            )
            setLoading(false);
        });
    }

    const handleChange = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }                

    const renderUserData = (id) => {

        const obj = dateHistSel.get(id)
        
        
        const { page, countPage: totalPage, users, countNewReq } = obj        

        const indexOfLastRow = page * 10;
        const indexOfFirstRow = indexOfLastRow - 10;

        const currentRows = users.slice(indexOfFirstRow, indexOfLastRow);

        return (
            <>
                <p>
                Liste des utilisateurs (Page <strong>{page}</strong> /{' '}
                    {totalPage})
                </p>

                <div>
                    {page > 1 && (
                        <IconTooltip
                            icon="fa fa-chevron-left"
                            text="Page précédente"
                            onClick={() => {
                                const arr = new Map(dateHistSel);    
                                
                                const obj = arr.get(id)

                                obj.page -= 1

                                arr.set(id, obj)

                                setDateHistSel(arr)
                            }}
                        />
                    )}
                    {page < totalPage && (
                        <IconTooltip
                            icon="fa fa-chevron-right"
                            text="Page suivante"
                            
                            onClick={() => {
                                const arr = new Map(dateHistSel);    
                                
                                const obj = arr.get(id)

                                obj.page += 1

                                arr.set(id, obj)

                                setDateHistSel(arr)
                            }}
                        />
                    )}
                </div>
                <div className="table-responsive">
                    <table className="table table-hover mb-0">
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Email</th>
                                <th>Date d'inscripton</th>
                                <th>Nb de nouveau requete</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentRows.map((r) =>  {
                                return (
                                    <tr key={r._id}>               
                                        <td> {r.FName} {r.LName}</td>
                                        <td>{r.email}</td>
                                        <td>{dateFormat(r.createdAt, 'dd/mm/yy')}</td>
                                        <td>{r.newReq}</td>
                                        <td>
                                            <IconTooltip
                                                icon="fas fa-file-medical"
                                                text="Details"
                                                onClick={() =>
                                                    props.history.push(
                                                        '/users/detail/' + r.userId._id
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }    

    
    return (
        <div className='campagne-sdr'>
            <p>Campagne SDR</p>
            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                <span>Envoyer un :</span>
                <select onChange={(e) => {
                    setOption(e.target.value);
                    reset();
                }}>
                    <option value="">choisir</option>
                    <option value="email">email</option>
                    <option value="sms">sms</option>
                    <option value="relance">relance</option>
                </select>
            </div>
            {option === 'email' ? (
                <>
                    <div className='sdr-block'>
                        <select onChange={(e) => {
                            setmailOption(e.target.value);
                        }}>
                            <option value="email">par email</option>
                            <option value="perRequest">par requete</option>
                            <option value="perUser">par type d'user</option>
                        </select>
                        {mailOption === 'perRequest' && (
                            <>
                                <select onChange={(e) => {
                                    setReqState(e.target.value);
                                }}>

                                    {rqState.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <select onChange={(e) => {
                                    setReqType(e.target.value);
                                }}>

                                    {typeStates.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Debut : <input value={date} max={new Date().toISOString().split('T')[0]} onChange={(e) => setDate(e.target.value)} type="date" name="date" />
                                </div>
                            </>
                        )}
                        {mailOption === 'perUser' && (
                            <>
                                <select onChange={(e) => {
                                    setUserType(e.target.value);
                                }}>

                                    {userOpt.map((v) => (
                                        <option value={v.value}>{v.label}</option>
                                    ))}
                                </select>
                                <div>
                                    Mois de : <input  
                                        max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                        min="2021-01"
                                        name="month" 
                                        onChange={(e) => setDate(e.target.value)}
                                        type="month"
                                        value={date}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    {mailOption === 'email' && (
                        <input value={value.email} onChange={handleChange} name='email' type="text" className='input-search' placeholder='Email' />
                    )}
                    <input value={value.subj} onChange={handleChange} name='subj' type="text" className='input-search' placeholder='Sujet' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={(mailOption === 'email' && !value.email) || !value.subj || !value.msg || loading}>Envoyer</button>
                </>
            ) : option === 'sms' ? (
                <>
                    <input value={value.phone} onChange={handleChange} name='phone' type="text" className='input-search' placeholder='Téléphone' />
                    <textarea value={value.msg} onChange={handleChange} name='msg' type="text" className='input-search' placeholder='Contenu' />
                    <button onClick={send} disabled={!value.phone || !value.msg || loading}>Envoyer</button>
                </>
            ) : option === 'relance' && (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                    <Modal
                        show={userLoading}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        className="modal-spinner"
                    >
                        <span className="loader" />
                        {userLoading && <p>Récupération des utilisateurs...</p>}
                    </Modal>
                    <p>Relance des non recurrents</p>
                    <div>
                        Date : <DateRangePicker locale="fr-FR" onChange={value => setDateRange(value)} value={dateRange} maxDate={new Date()}/>
                    </div>
                    <div style={{ width: 400 }}>
                        <Select
                            value={doctorSelected}
                            isClearable
                            onChange={(v) => setDoctorSelected(v)}
                            options={doctorList.map((v) => ({ value: v._id, label: `${v.FName} ${v.LName}`}))}
                            isMulti={false}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black',
                            })}}
                            role="checkbox"
                            placeholder="Selection de docteur" 
                        />
                    </div>
                    {doctorSelected.value ? <p>Nombre d'user : {userList.length}</p> : null}
                    <textarea value={value.subj} onChange={handleChange} name='subj' type="text" className='input-search' placeholder='Contenu' />
                    {loading ? <p style={{ textAlign: 'center'}}>Envoie en cours : {sendProgress} %</p> : <button onClick={sendRelance} disabled={!doctorSelected || !dateRange || !value.subj || loading}>Envoyer</button>}
                    <div style={{ marginTop: 30 }}>
                        <button onClick={() => getRelanceHist()}>Recuperer les historiques</button>
                        {relanceHist.map((v) => (
                            <div key={v._id}>
                                <p onClick={() => {
                                    const arr = new Set(docHistSel);

                                    if (arr.has(v.doctorId._id)) {
                                        arr.delete(v.doctorId._id)
                                    }
                                    else arr.add(v.doctorId._id)                                    
                                    
                                    setDocHistSel(arr)
                                }} style={{ marginTop: 10, cursor: 'pointer' }}>
                                    Dr {v.doctorId.FName} {v.doctorId.LName} {'>'}
                                </p>
                                
                                {docHistSel.has(v.doctorId._id) && v.date.map((d) => {
                                    return (
                                        <div key={d._id}>
                                            <p onClick={() => {
                                                
                                                if (!dateHistSel.has(d._id)) {
                                                    getRelanceHist(v._id, d._id)
                                                }
                                                else {
                                                    
                                                    const arr = new Map(dateHistSel)  

                                                    const obj = arr.get(d._id)
                                                    
                                                    obj.show = !obj.show         

                                                    arr.set(d._id, obj)                                                      
                                                    
                                                    setDateHistSel(arr)
                                                }
                                            }} style={{ marginTop: 2, cursor: 'pointer' }}>
                                                &nbsp; &nbsp; {moment(d.start).format('DD/MM/YYYY')} - {dateFormat(d.end, 'dd/mm/yy')} {'>'}
                                            </p>
                                            {dateHistSel.has(d._id) && dateHistSel.get(d._id).show && renderUserData(d._id)}
                                        </div>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default CampagneSdr;