import React from 'react';
import { Row, Col, Panel,  SplitButton, ButtonToolbar, MenuItem } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';
import debounce from '../../Services/debounce';
import { exportAsExcel } from '../../Services/exportAsExcel';

const options = [
    { value: 'simple', label: 'Liste simple' },
    { value: 'nbReq', label: 'Par nombre de demande' }
];

export default class Doctors extends React.Component {
    constructor() {
        super();

        this.state = {
            doctors: null,
            totalPage: 0,
            sort: true,
            input: '',
            option: { value: 'simple', label: 'Liste simple' },
            isNbReq: 0,
            startDate: null,
            isExporting: true,
            endDate: null

        };

        this.handleRemove = this.handleRemove.bind(this);
        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
        this.getDoctors = this.getDoctors.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);

        this.debounceSearch = debounce(this.getDoctors, 500);
    }

    getDoctors(page = this.props.match.params.page || 1) {
        fetchApi(`/doctors?page=${page}${this.state.input.trim() ? `&search=${this.state.input}` : ''}&isNbReq=${this.state.isNbReq}${this.state.startDate ? `&startDate=${this.state.startDate}` : ''}${this.state.endDate ? `&endDate=${this.state.endDate}` : ''}`)
            .then((res) => {
                if (this.state.isNbReq) {
                    res.doctors.sort((a, b) => b.request.length - a.request.length);
                }

                this.setState({ doctors: res.doctors, totalPage: res.countPages, isExporting: false }, () => {
                    this.props.history.push({
                        pathname: `/doctors/${page.toString()}`
                    });    
                });
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getDoctors();
    }

    updatePage(page) {
        this.setState({ users: null }, () => {
            this.getDoctors(page);
        });
    }

    handleRemove(id) {
        const doctor = this.state.doctors.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment dire adieu a ${doctor.FName} ${
                doctor.LName
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi('/doctors', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: doctor._id
                    })
                }).then(() => {
                    this.setState({
                        doctors: this.state.doctors.filter(({ _id }) => _id !== id)
                    });

                    swalWithBootstrapButtons('Supprime!', 'Docteur supprime', 'success');
                });
            } else if (
            // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons('Annule', 'Docteur en securite :)', 'error');
            }
        });
    }

    sortInt(sortKey, sortSecond) {
        const doctors = this.state.doctors;

        if (this.state.sort) {
            doctors.sort((a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]);
        } else {
            doctors.sort((a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]);
        }

        this.setState({ doctors: doctors, sort: !this.state.sort });
    }

    onSort(sortKey) {
        const data = this.state.doctors;
        if (this.state.sort) {
            data.sort((a, b) => {
                if (!this.state.isNbReq) {
                    return a[sortKey].localeCompare(b[sortKey]);
                }

                return sortKey === 'total' ? a.request.length - b.request.length : a.request.filter((v) => v.state === sortKey).length - b.request.filter((v) => v.state === sortKey).length;
            });
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => {
                if (!this.state.isNbReq) {
                    return b[sortKey].localeCompare(a[sortKey]);
                }

                return sortKey === 'total' ? b.request.length - a.request.length : b.request.filter((v) => v.state === sortKey).length - a.request.filter((v) => v.state === sortKey).length;
            });
            this.setState({ sort: true });
        }
        this.setState({ doctors: data });
    }

    handleSelect(e) {
        this.setState({ option: e, doctors: null, isNbReq: e.value === 'simple' ? 0 : 1 }, () => this.getDoctors());
    }

    handleDateChange(e) {
        this.setState({ [e.target.name]: e.target.value, doctors: null }, () => this.getDoctors());
    }


    exportToExcel() {

        this.setState({ isExporting: true }, () => {

            fetchApi('/doctors/exportActivated')
            .then((res) => {

                const toExport = res.doctors.map((v) => ({
                    ID: v._id,
                    Nom: v.FName,
                    'Prénom': v.LName,
                    Email: v.email,
                    Téléphone: v.phone,
                    Addresse: v.address,
                    Rpps: v.rpps
                }));
        
                const cls = [
                    { wch: 25},
                    { wch: 15 },
                    { wch: 15 },
                    { wch: 25},
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 15 },
                ];
        
                exportAsExcel(toExport, `doctors_${new Date().toISOString()}`, cls);
                
                notif.addNotification(
                    'Success !',
                    '',
                    'success'
                );
    
                this.setState({ isExporting: false });
                
            })
            .catch((err) => {

                this.setState({ isExporting: false });

                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur',
                    'warning'
                )

            });

        })

    }

    render() {
        if (!this.state.doctors) {
            return <div>Récupération des données en cours...</div>;
        }
        const page = this.props.match.params.page
            ? parseInt(this.props.match.params.page)
            : 1;
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <div style={{ margin: '10px 0' }}>
                            <ButtonToolbar>
                                <SplitButton
                                    id="option-list"
                                    rootCloseEvent="mousedown"
                                    title={this.state.option.label}
                                >   
                                    {
                                        options.map((v, i) => (
                                            <MenuItem
                                                eventKey={v}
                                                active={v.value === this.state.option.value}
                                                onSelect={v.value === this.state.option.value ? undefined : this.handleSelect}
                                                key={`item-${i}`}
                                            >
                                                {v.label}
                                            </MenuItem>
                                        ))
                                    }
                                </SplitButton>
                            </ButtonToolbar>
                        </div>
                    </Col>
                </Row>
                { !!this.state.isNbReq && (
                    <Row>
                        <Col md={12}>
                            <div style={{ margin: '10px 0' }}>
                            De <input max={this.state.endDate || new Date().toISOString().split('T')[0]} onChange={this.handleDateChange} type="date" name="startDate" value={this.state.startDate} /> &nbsp; à &nbsp; <input max={new Date().toISOString().split('T')[0]} min={this.state.startDate} onChange={this.handleDateChange} type="date" name="endDate" value={this.state.endDate} />
                            </div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Docteurs</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>
                                    <div>
                                        <button disabled={this.state.isExporting} style={{ border: 'none', background: '#58585878', padding: 5, fontSize: 15, color: 'white' }} onClick={this.exportToExcel}>{this.state.isExporting ? 'Chargement ...' : 'Exporter les activés'}</button>
                                    </div>



                                    <div>
                                        <input
                                            type="text"
                                            className={'input-search'}
                                            placeholder="Taper le nom d'un docteur"
                                            value={this.state.input}
                                            onChange={(e) =>
                                                this.setState({ input: e.target.value }, () => 
                                                    this.debounceSearch()  
                                                )
                                            }
                                        />
                                    </div>


                                    <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>

                                        <p>
                        Liste des docteurs (Page <strong>{page}</strong> /{' '}
                                            {this.state.totalPage})
                                        </p>
                                        
                                        <div>
                                            {page > 1 && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-left"
                                                    text="Page précédente"
                                                    onClick={() => this.updatePage(page - 1)}
                                                />
                                            )}
                                            {page < this.state.totalPage && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-right"
                                                    text="Page suivante"
                                                    onClick={() => this.updatePage(page + 1)}
                                                />
                                            )}
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('FName')}
                                                >
                          Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                {this.state.isNbReq ? (
                                                    <>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('total')}
                                                        >
                          Total
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('approved')}
                                                        >
                          Approuvé
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('reviewed')}
                                                        >
                          Examiné
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('terminated')}
                                                        >
                          Terminé
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                    </>
                                                ) : (
                                                    <>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('createdAt')}
                                                        >
                          Date d'inscription
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                        <th
                                                            className="sortable"
                                                            onClick={() => this.onSort('lastLoginDate')}
                                                        >
                          Derniere connexion
                                                            <i
                                                                className={
                                                                    this.state.sort
                                                                        ? 'zmdi zmdi-caret-down'
                                                                        : 'zmdi zmdi-caret-up'
                                                                }
                                                            />
                                                        </th>
                                                    </>
                                                )}
                                                <th>Numéro de téléphone</th>
                                                <th>Active</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.doctors.map((d) => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.FName} {d.LName}
                                                    </td>
                                                    {this.state.isNbReq ? (
                                                        <>
                                                            <td>{d.request.length}</td>
                                                            <td>{d.request.filter((v) => v.state === 'approved').length}</td>
                                                            <td>{d.request.filter((v) => v.state === 'reviewed').length}</td>
                                                            <td>{d.request.filter((v) => v.state === 'terminated').length}</td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                            <td>
                                                                {dateFormat(d.lastLoginDate, 'dd/mm/yy HH:MM')}
                                                            </td>
                                                        </>
                                                    )}
                                                    <td>{d.phone}</td>
                                                    <td>
                                                        {d.activation ? (
                                                            <i className="fa fa-check success" />
                                                        ) : (
                                                            <i className="fa fa-close danger" />
                                                        )}
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-user"
                                                            text="Voir le profil"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/doctors/detail/' + d._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() => this.handleRemove(d._id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export class WaitingDoctors extends React.Component {
    constructor() {
        super();

        this.state = {
            doctorsWaiting: [],
            sort: true
        };

        this.onSort = this.onSort.bind(this);
    }

    componentDidMount() {
        fetchApi('/doctors?filter=waitingActivation')
            .then((res) => this.setState({ doctorsWaiting: res.doctors }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    onSort(event, sortKey) {
        const data = this.state.doctorsWaiting;
        if (this.state.sort) {
            data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
            this.setState({ sort: true });
        }
        this.setState({ doctors: data });
    }

    handleRemove(id) {
        const doctor = this.state.doctors.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment dire adieu a ${doctor.FName} ${
                doctor.LName
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi('/doctors', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: doctor._id
                    })
                }).then(() => {
                    this.setState({
                        doctors: this.state.doctors.filter(({ _id }) => _id !== id)
                    });

                    swalWithBootstrapButtons('Supprime!', 'Docteur supprime', 'success');
                });
            } else if (
            // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons('Annule', 'Docteur en securite :)', 'error');
            }
        });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Docteurs non actives</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>
                  Liste des docteurs en attente d'approbation par un
                  administrateur.
                                </p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={(e) => this.onSort(e, 'FName')}
                                                >
                          Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={(e) => this.onSort(e, 'createdAt')}
                                                >
                          Date d'inscription
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Numéro de téléphone</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.doctorsWaiting.map((d) => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.FName} {d.LName}
                                                    </td>
                                                    <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                    <td>{d.phone}</td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-user"
                                                            text="Voir le profil"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/doctors/detail/' + d._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
