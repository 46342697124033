import React from 'react';
import { Panel } from 'react-bootstrap';
import Select from 'react-select';

import '../../Assets/Ui/RequestCounter.css';

const FeedbackCounter = (props) => {


    const handleMonthChange = (e) => {
        props.setMonth(e.target.value);
    };

    const handleSelectType = (e) => {
        props.setType(e);
    };

    return (
        <Panel>
            <Panel.Heading>
                <h6>Compteur et filtre</h6>
            </Panel.Heading>
            <Panel.Body>
                <div className="counter">
                    <div className="filter-block">
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20}}>
                            <div>
                                Mois de : <input  
                                    max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                    min="2021-01"
                                    name="month" 
                                    onChange={handleMonthChange} 
                                    type="month"
                                    value={props.month}
                                />
                            </div>
                            <div style={{ width: 400 }}>
                                <Select
                                    value={props.doctorValue}
                                    isClearable
                                    onChange={props.handleSelectDoctor}
                                    options={props.doctorList}
                                    isMulti={false}
                                    styles={{ option: (s) => ({
                                        ...s,
                                        color: 'black',
                                    })}}
                                    role="checkbox"
                                    placeholder="Filtre par docteur" 
                                />
                            </div>
                            <div style={{ width: 400 }}>
                                <Select
                                        value={props.type}
                                        isClearable
                                        onChange={props.setType}
                                        options={props.typeList}
                                        isMulti={false}
                                        styles={{ option: (s) => ({
                                            ...s,
                                            color: 'black',
                                        })}}
                                        role="checkbox"
                                        placeholder="Filtre par type" 
                                    />
                            </div>
                            
                        </div>
                    </div>

                    <div className="nb-block">
                        <Panel>
                            <Panel.Heading>
                                <h3>Total</h3>
                                {props.loading ? (
                                    <span className="loader" />
                                ) : (
                                    <h2>{props.count}</h2>
                                )}
                            </Panel.Heading>
                        </Panel>
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};

export default FeedbackCounter;
