import React, { useEffect, useState } from 'react';
import {  Modal, Panel } from 'react-bootstrap';
import Select from 'react-select';

import '../../Assets/Ui/RequestCounter.css';
import '../../Assets/Ui/RequestAnalytics.css';
import { fetchApi } from '../../Network/NetworkService';
import isoDate from '../../Services/toIsoDate.js';
import { notif } from '../Ui/Notification';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { comparisonArr, priorityStates, rqState, typeStates } from '../../Constants.jsx';


const clientTypeStates = [
    { value: 'all', label: 'Tous les clients' },
    { value: 'new', label: 'Nouveau client' },
    { value: 'old', label: 'Récurrent' },
];

const sortingObject = (obj) => {
    const sortable = Object.entries(obj)
        .sort(([, a], [, b]) => b-a)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    
    return sortable;
}

const sources = ['google', 'skycall', 'skycall-voice', 'skycall-api', 'crechmed', 'naturel', 'bing', 'medifil', 'gescall', , 'cortel', 'pharmacy']
const RequestAnalytics = (props) => {
    const [total, setTotal] = useState(0);
    const [totalArr, setTotalArr] = useState([]);
    const [state, setState] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [motifs, setMotifs] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [comparison, setComparison] = useState( {value: 'yesterday', label: "Jour précédent"} );
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [priorityState, setPriorityState] = useState(null);
    const [clientFilter, setClientFilter] = useState({ value: 'all', label: 'Tous les clients' });
    const [displayedDatas, setDisplayedDatas] = useState([]);
    const [displayedDatasCompare, setDisplayedDatasCompare] = useState([]);
    const [countNew, setCountNew] = useState([]);
    const [countOld, setCountOld] = useState([]);
    const [countNewCompare, setCountNewCompare] = useState([]);
    const [countOldCompare, setCountOldCompare] = useState([]);
    const [loading, setLoading] = useState(false);

    const getCount = () => {
        setTotal(-1);
        setDisplayedDatas([]);
        setLoading(true);

        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;

        if (comparison && comparison.value === 'yesterday') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 1));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -1));
        };
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }
       const apis = [fetchApi(`/requests/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}`),
                     fetchApi(`/requests/analytics?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}`)];
        Promise.all(apis)
            .then(responses => {
                let newClients = [];
                let oldClients = [];
                let newUsersArr = [];
                let oldUsersArr = [];

                responses[0].requests.map(el=>{
                    if (new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59))) {
                        newClients.push(el);
                        if (newUsersArr.findIndex(element => element.userId._id === el.userId._id) === -1) newUsersArr.push(el)
                    }  
                    if (new Date (el.userId?.createdAt) < new Date(isoDate(startDate, 0, 0))) {
                        oldClients.push(el);
                        if (oldUsersArr.findIndex(element => element.userId._id === el.userId._id) === -1) oldUsersArr.push(el)
                    }
                });

                setTotal( responses[0].countRequests);
                setTotalArr( responses[0].requests);

                if (clientFilter?.value === "new") {
                    setDisplayedDatas(newClients);
                } else if (clientFilter?.value === "old") {
                    setDisplayedDatas(oldClients);
                } else {
                    setDisplayedDatas(responses[0].requests);
                }
                setCountNew(newClients);
                setCountOld(oldClients);

                let newClientsCompare = [];
                let oldClientsCompare = [];

                responses[1].requests.map(el=>{
                    if (new Date (el.userId?.createdAt) >= new Date(isoDate(startDateCompare, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDateCompare, 23, 59))) {
                        newClientsCompare.push(el);
                    }  
                    if (new Date (el.userId?.createdAt) < new Date(isoDate(startDateCompare, 0, 0))) {
                        oldClientsCompare.push(el);
                    }             
                });
              
                if (clientFilter?.value === "new") {
                    setDisplayedDatasCompare(newClientsCompare);
                } else if (clientFilter?.value === "old") {
                    setDisplayedDatasCompare(oldClientsCompare);
                } else {
                    setDisplayedDatasCompare(responses[1].requests);
                }
                setCountNewCompare(newClientsCompare);
                setCountOldCompare(oldClientsCompare);
                setLoading(false);
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    const getMotifGeneraliste = () => {
        setMotifs([]);
        fetchApi(`/requests/analytics/specificPathology?start=${isoDate(range[0], 0, 0)}&end=${isoDate(range[1], 23, 59)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}&typeState=generaliste`)
            .then(res => {
                let motifsArr = [];
                res.requests.map(el => {
                    if (el.state !=="incomplete" && el.decisionTree && el.decisionTree.questionOne) motifsArr.push(el.decisionTree.questionOne);
                });
                setMotifs(motifsArr.reduce((acc, x, i) => {
                    const idx = Math.floor(i / 3);
                    acc[idx] = [...(acc[idx] || []), x];
                    return acc;
                  }, []));
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    const countRequestBySource = (source, array) => {
        const data = [...array];
        if (source === 'total') {
            return data.length;
        } else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            return data.filter(el=>el.utm_source === source).length
        } else if (source === "skycall") {
            return data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice")).length;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).length;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").length;
        } else if (source === "naturel") {
            return data.filter(el=>el.utm_source === null && el.utm_pharmacy === null).length;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).length;
        }

    }

    const calculateCABySource = (source, array) => {
        const data = [...array];
        let total = 0;
        let arr = []
        if (source === 'total') {
            arr = [...data]
        }else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            arr = data.filter(el=>el.utm_source === source);
        } else if (source === "skycall") {
            arr = data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice"));
        } else if (source === "skycall-voice") {
            arr = data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice);
        } else if (source === "skycall-api") {
            arr =  data.filter(el=> el.createdBySkycall && el.utm_source === "skycall-api");
        } else if (source === "naturel") {
            arr = data.filter(el=>el.utm_source === null && el.utm_pharmacy === null);
        } else if (source === "pharmacy") {
            arr = data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy'));
        }

        arr && arr.length > 0 && arr.map(el => {
            if (el.state === "approved" || el.state === "reviewed" || el.state === "terminated") {
                el.type === "dermatologie" || el.type === "acne" ? total +=45 : el.priority ? total += 35 : total+=30
            }
        })
        return total;

    }

    const calculateCostBySource = (source, array) => {
        const data = [...array];
        if (source === "google" || source === "bing") {
            return 0;
        } else if ( source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            return data.filter(el=>el.utm_source === source).length *10;
        } else if (source === "skycall") {
            return data.filter(el=>el.utm_source === "skycall" && !el.utm_content?.includes("voice")).length *15;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).length*15;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").length*15;
        } else if (source === "naturel") {
            return 0;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).length * 10;
        }

    }

    const calculateDoctorFeeBySource = (source, array) => {
        const data = [...array];
        let total = 0;
        let arr = []
        if (source === 'total') {
            arr = [...array];
        } else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            arr = data.filter(el=>el.utm_source === source);
        } else if (source === "skycall") {
            arr = data.filter(el=>el.utm_source === "skycall" && !el.utm_content?.includes("voice"));
        } else if (source === "skycall-voice") {
            arr = data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice);
        } else if (source === "skycall-api") {
            arr = data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api");
        } else if (source === "naturel") {
            arr = data.filter(el=>el.utm_source === null && el.utm_pharmacy === null);
        } else if (source === "pharmacy") {
            arr = data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy'));
        }

        arr && arr.length > 0 && arr.map(el => {
            if (el.state === "approved" || el.state === "reviewed" || el.state === "terminated") {
                el.type === "dermatologie" ? total +=20  : total+=15
            }
        })
        return total;

    }

    const getValue = (arr) => {
        let total = 0;
        arr && arr.length > 0 && arr.map(el => {
            if (el.state === "approved" || el.state === "reviewed" || el.state === "terminated") {
                el.type === "dermatologie" ? total +=45 : el.priority ? total += 35 : total+=30
            }
        })
        return total;
    }

    useEffect(() => {
        getCount();
        getMotifGeneraliste();
    }, []);

    const countSource = (arr, source) => { 
        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).length;
        } else if (source === "naturel") {
            result = arr.filter(el => el.utm_pharmacy === null && el.utm_source === null).length;
        } else {
            result = arr.filter(el => el.utm_source === source).length;
        }
   
        return result;
    };

    const countStatus = (arr, status) => { 
        let result 
        if (status === 'valide') {
            result = arr.filter(el => el.state === 'approved' || el.state === 'terminated' || el.state === 'reviewed').length;
        } else {
            result = arr.filter(el => el.state === status).length;
        }
        return result;
    };

    const getIcon = (data, dataCompare) => {       
        if (data > dataCompare) {
            return '↗️';
        } else if (data < dataCompare) {
            return '↘️';
        } else {
            return '';
        }
    }

    const getPathology = () => {
        let pathologyObj = {};
        displayedDatas.map(el => {
            if (pathologyObj[el.type]) {
                pathologyObj[el.type]++;
            }
            if (!pathologyObj[el.type]) {
                pathologyObj[el.type] = 1;
            }
        })
        return sortingObject(pathologyObj);
    }

    const getPathologyCompare = () => {
        let pathologyObj = {};
        displayedDatas.map(el => {
            if (pathologyObj[el.type]) {
                pathologyObj[el.type]++;
            }
            if (!pathologyObj[el.type]) {
                pathologyObj[el.type] = 1;
            }
        })

        let pathologyObjCompare = {};

        Object.keys(sortingObject(pathologyObj)).map(path => pathologyObjCompare[path] = displayedDatasCompare.filter(el=>el.type === path).length);
        return (pathologyObjCompare);
    }

    const getDoctor = () => {
        let doctorObj = {};
        displayedDatas.map(el => {
            if (!el.doctorId && doctorObj['En attente'] && el.state === 'waiting') {
                doctorObj['En attente']++;
            }
            if (!el.doctorId && !doctorObj['En attente'] && el.state === 'waiting') {
                doctorObj['En attente'] = 1;
            }

            if (el.doctorId && doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]) {
                doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]++;
            }
            if (el.doctorId && !doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]) {
                doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`] = 1;
            }
        })
        return sortingObject(doctorObj);
    }

    const getDoctorCompare = () => {
        let doctorObj = {};
        displayedDatas.map(el => {
            if (!el.doctorId && doctorObj['En attente'] && el.state === 'waiting') {
                doctorObj['En attente']++;
            }
            if (!el.doctorId && !doctorObj['En attente'] && el.state === 'waiting') {
                doctorObj['En attente'] = 1;
            }

            if (el.doctorId && doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]) {
                doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]++;
            }
            if (el.doctorId && !doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`]) {
                doctorObj[`${el.doctorId.LName} ${el.doctorId.FName}`] = 1;
            }
        });
        let doctorObjCompare = {};
        Object.keys(sortingObject(doctorObj)).map(doc => {
            if (doc === "En Attente") {
                doctorObjCompare[doc] = displayedDatasCompare.filter( el=>!el.doctorId && el.state === 'waiting' ).length
            } else {
                doctorObjCompare[doc] = displayedDatasCompare.filter( el=> el.doctorId && `${el.doctorId.LName} ${el.doctorId.FName}` === doc).length
            }
        });
        return doctorObjCompare;
    }

    const getStepObj = () => {
        let stepObj = {};
        displayedDatas.map(el => {
            if (stepObj[el.incompleteTracking]) {
                stepObj[el.incompleteTracking]++;
            } else {
                stepObj[el.incompleteTracking] = 1;
            };
        })

        return stepObj 
    }

    const countPriorityRequestBySource = (source, array) => {
        const data = [...array];
        if (source === 'total') {
            return data.filter(el => el.priority).length;
        } else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            return data.filter(el=>el.utm_source === source).filter(el => el.priority).length
        } else if (source === "skycall") {
            return data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice")).filter(el => el.priority).length;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).filter(el => el.priority).length;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").filter(el => el.priority).length;
        } else if (source === "naturel") {
            return data.filter(el=>el.utm_source === null && el.utm_pharmacy === null).filter(el => el.priority).length;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).filter(el => el.priority).length;
        }
    }

    const countNotPriorityRequestBySource = (source, array) => {
        const data = [...array];
        if (source === 'total') {
            return data.filter(el => !el.priority).length;
        } else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            return data.filter(el=>el.utm_source === source).filter(el => !el.priority).length
        } else if (source === "skycall") {
            return data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice")).filter(el => !el.priority).length;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).filter(el => !el.priority).length;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").filter(el => !el.priority).length;
        } else if (source === "naturel") {
            return data.filter(el=>el.utm_source === null && el.utm_pharmacy === null).filter(el => !el.priority).length;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).filter(el => !el.priority).length;
        }
    }


    return (
        <div className='r-analytics'>
            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modal-spinner"
            >
                <span className="loader" />
                {loading && <p>Récupération des données en cours...</p>}
            </Modal>
        <Panel>
            <Panel.Heading className='heading'>
                <h2>Analyses des requêtes</h2>
                <div>
                    <h6>Compteur du {' '} </h6>
                    <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>

                    <h6>{' '} Par rapport {' '}</h6>
                    <Select
                        value={comparison}
                        onChange={(v) => setComparison(v)}
                        options={comparisonArr}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        className='filter-status'
                    />
                    {
                        comparison.value === 'personalized' &&
                        <DateRangePicker onChange={value => setRangeCompare(value)} value={rangeCompare} maxDate={new Date()}/>
                    }   
                </div>
                <div>
                    <Select
                        value={clientFilter}
                        onChange={(v) => setClientFilter(v)}
                        options={clientTypeStates}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par type de client ..." 
                        className='filter-status'
                    />
                    <Select
                        value={state}
                        onChange={(v) => setState(v)}
                        options={rqState}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par statut ..." 
                        className='filter-status'
                    />
                    <Select
                        value={typeState}
                        onChange={(v) => setTypeState(v)}
                        options={typeStates}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par pathologie ..." 
                        className='filter-status'
                    />
                    <Select
                        value={priorityState}
                        onChange={(v) => {
                           setPriorityState(v)
                        }}
                        options={priorityStates}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black',
                        })}}
                        role="checkbox"
                        placeholder="Filtre consultation urgente ..." 
                        className='filter-status'
                    />
                    <button className='submit-button' onClick={() => {
                        getCount();
                        getMotifGeneraliste();
                    }}>Chercher</button>
                </div>
            </Panel.Heading>
            <Panel.Body>
            <div className='source-table'>
                    <h1>Par priorité</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                <th rowSpan={2}>Source</th>
                                <th rowSpan={2}>Nombre total de requêtes</th>
                                <th colSpan={2}>Urgente</th>
                                <th colSpan={2}>Non-urgentes</th>
                            </tr>
                            <tr>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                <td>Total</td>
                                <td>{displayedDatas.length >=0 ? displayedDatas.length : 'Loading...'} {getIcon(displayedDatas.length, displayedDatasCompare.length)}</td>
                                <td>{displayedDatas.length>=0 ? displayedDatas.filter(el => el.priority).length : 'Loading...'} {getIcon(displayedDatas.filter(el => el.priority).length, displayedDatasCompare.filter(el => el.priority).length)}</td>      
                                <td>100%</td>  
                                <td>{displayedDatas.length>=0 ? displayedDatas.filter(el => !el.priority).length : 'Loading...'}{getIcon(displayedDatas.filter(el => el.priority).length, displayedDatasCompare.filter(el => el.priority).length)}</td>
                                <th>100%</th>
                            </tr> 
                            {
                                sources.filter(el => countRequestBySource(el, displayedDatas) > 0).map(source => <tr className='data'>
                                    <td>{source}</td>
                                    <td>{countRequestBySource(source, displayedDatas)} {getIcon(countRequestBySource(source, displayedDatas), countRequestBySource(source, displayedDatasCompare))}</td>
                                    <td>{countPriorityRequestBySource(source, displayedDatas)} {getIcon(countPriorityRequestBySource(source, displayedDatas), countPriorityRequestBySource(source, displayedDatasCompare))}</td>
                                    <td>{((countPriorityRequestBySource(source, displayedDatas)/displayedDatas.filter(el => el.priority).length) *100).toFixed(2)} %</td>
                                    <td>{countNotPriorityRequestBySource(source, displayedDatas)}{getIcon(countNotPriorityRequestBySource(source, displayedDatas), countNotPriorityRequestBySource(source, displayedDatasCompare))}</td>
                                    <td>{((countNotPriorityRequestBySource(source, displayedDatas)/displayedDatas.filter(el => !el.priority).length) *100).toFixed(2)} %</td>
                                
                                </tr>)
                            }   
                            <tr className='compare'>
                           
                            </tr>                       
                        </tbody>
                    </table>
                </div>
                
                <div className='source-table'>
                    <h1>Par Source</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                <th>Source</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>CA</th>
                                <th>Honoraises des médecins</th>
                                <th>Revenu</th>
                                <th>Cout d'acquisition</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Total</td>
                                <td>{displayedDatas.length}{countRequestBySource('total', displayedDatas) > countRequestBySource('total', displayedDatasCompare) ? '↗️' : countRequestBySource('total', displayedDatas) < countRequestBySource('total', displayedDatasCompare) ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                <td>100%</td>
                                <td>{getValue(displayedDatas)}</td>
                                <td>{calculateDoctorFeeBySource('total', displayedDatas)}</td>
                                <td>{getValue(displayedDatas) - calculateDoctorFeeBySource('total', displayedDatas)}</td>
                                <td>0</td>
                            </tr>
                            {
                                sources.filter(el => countRequestBySource(el, displayedDatas) > 0).map((source, i) => {
                                    return [
                                        <tr key={i}>
                                            <td rowSpan={2}>{source}</td>
                                            <td>{countRequestBySource(source, displayedDatas)} {getIcon(countRequestBySource(source, displayedDatas), countRequestBySource(source, displayedDatasCompare))}</td>
                                            <td>{displayedDatas.length>=0 ? `${(countRequestBySource(source, displayedDatas)/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                            <td>{calculateCABySource(source, displayedDatas)}</td>
                                            <td>{calculateDoctorFeeBySource(source, displayedDatas)}</td>
                                            <td>{calculateCABySource(source, displayedDatas) - calculateDoctorFeeBySource(source, displayedDatas)}</td>
                                            <td>{calculateCostBySource(source, displayedDatas)}</td>
                                        </tr>,
                                        <tr key={`${i}-compare`} className='compare'>
                                            <td>{countRequestBySource(source, displayedDatasCompare)}</td>
                                            <td>{total>=0 ? `${(countRequestBySource(source, displayedDatasCompare)/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                            <td>{calculateCABySource(source, displayedDatasCompare)}</td>
                                            <td>{calculateCostBySource(source, displayedDatasCompare)}</td>
                                            <td>{calculateDoctorFeeBySource(source, displayedDatasCompare)}</td>
                                            <td>{calculateCABySource(source, displayedDatasCompare) - calculateCostBySource(source, displayedDatasCompare) - calculateDoctorFeeBySource(source, displayedDatasCompare)}</td>
                                        </tr>
                                    ]
                                                                   
                                })
                            }
                        </tbody>
                    </table>
                    <p>*Zone gris: Donnés du {comparison.label}</p>

                </div>

                <div className='source-table'>
                    <h1>Par Client</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                <th colSpan={2}>Source</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>CA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowSpan={2}>Total</td>
                                <td>Nouveau</td>
                                <td>{countNew.length} {countNew.length > countNewCompare.length ? '↗️' : countNew.length < countNewCompare.length ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                <td>{total>=0 ? `${(countNew.length/total * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                <td>{getValue(countNew).toLocaleString()}</td>
                                
                            </tr>
                            <tr>
                                <td>Récurrent</td>
                                <td>{countOld.length} {countOld.length > countOldCompare.length ? '↗️' : countOld.length < countOldCompare.length ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                <td>{total>=0 ? `${(countOld.length/total * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                <td>{getValue(countOld).toLocaleString()}</td>
                                
                            </tr>

                            {
                                sources.filter(el => countRequestBySource(el, countNew) > 0 || countRequestBySource(el, countOld) > 0).map((source, i) => {
                                    if (clientFilter.value === "new") {
                                        return (   
                                            <tr key={i}>
                                               <td rowSpan={1}>{source}</td>
                                               <td>Nouveau</td>
                                               <td>{countRequestBySource(source, countNew)} {countRequestBySource(source, countNew) > countRequestBySource(source, countNewCompare) ? '↗️' : countRequestBySource(source, countNew) < countRequestBySource(source, countNewCompare) ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                               <td>{total>=0 ? `${(countRequestBySource(source, countNew)/countNew.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                               <td>{calculateCABySource(source, countNew)}</td>
                                            </tr>)
                                    } else if (clientFilter.value === "old") {
                                        return (
                                            <tr key={`${i}-compare`}>
                                                <td rowSpan={1}>{source}</td>
                                                <td>Récurrent</td>
                                                <td>{countRequestBySource(source, countOld)} {countRequestBySource(source, countOld) > countRequestBySource(source, countOldCompare) ? '↗️' : countRequestBySource(source, countOld) < countRequestBySource(source, countOldCompare) ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                                <td>{total>=0 ? `${(countRequestBySource(source, countOld)/countOld.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                                <td>{calculateCABySource(source, countOld)}</td> 
                                            </tr>
                                        )
                                    } else {
                                        return [
                                            <tr key={i}>
                                                <td rowSpan={2}>{source}</td>
                                                <td>Nouveau</td>
                                                <td>{countRequestBySource(source, countNew)} {countRequestBySource(source, countNew) > countRequestBySource(source, countNewCompare) ? '↗️' : countRequestBySource(source, countNew) < countRequestBySource(source, countNewCompare) ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                                <td>{total>=0 ? `${(countRequestBySource(source, countNew)/countNew.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                                <td>{calculateCABySource(source, countNew)}</td>
                                               
                                            </tr>,
                                            <tr key={`${i}-compare`}>
                                                <td>Récurrent</td>
                                                <td>{countRequestBySource(source, countOld)} {countRequestBySource(source, countOld) > countRequestBySource(source, countOldCompare) ? '↗️' : countRequestBySource(source, countOld) < countRequestBySource(source, countOldCompare) ? <span style={{backgroundColor: 'rouge'}}>↘️</span>: ''}</td>
                                                <td>{total>=0 ? `${(countRequestBySource(source, countOld)/countOld.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                                                <td>{calculateCABySource(source, countOld)}</td>
                                               
                                            </tr>
                                        ]
                                    }                              
                                })
                            }
                        </tbody>
                    </table>
                    <p>*Nouveau: Nombre de consultation réalisé par nouveau client</p>
                    <p>*Récurrent: Nombre de consultation réalisé par ancien client</p>
                </div>

                <div className='source-table'>
                    <h1>Par status</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                <th colSpan={2}>Incomplète</th>
                                <th colSpan={2}>En attente</th>
                                <th colSpan={2}>Validé</th>
                                <th colSpan={2}>Remboursé</th>
                                <th colSpan={2}>Annulé</th>
                                <th colSpan={2}>Non à distance</th>
                            </tr>
                            <tr>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                                <th>Nombre</th>
                                <th>Taux</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                               <td>{countStatus(displayedDatas, 'incomplete')} {getIcon(countStatus(displayedDatas, 'incomplete'), countStatus(displayedDatasCompare, 'incomplete'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'incomplete')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatas, 'waiting')} {getIcon(countStatus(displayedDatas, 'waiting'), countStatus(displayedDatasCompare, 'waiting'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'waiting')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatas, 'valide')} {getIcon(countStatus(displayedDatas, 'valide'), countStatus(displayedDatasCompare, 'valide'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'valide')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatas, 'refunded')} {getIcon(countStatus(displayedDatas, 'refunded'), countStatus(displayedDatasCompare, 'refunded'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'refunded')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatas, 'canceled')} {getIcon(countStatus(displayedDatas, 'canceled'), countStatus(displayedDatasCompare, 'canceled'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'canceled')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatas, 'notOnline')} {getIcon(countStatus(displayedDatas, 'notOnline'), countStatus(displayedDatasCompare, 'notOnline'))}</td>
                               <td>{displayedDatas.length >= 0  ? `${(countStatus(displayedDatas, 'notOnline')/displayedDatas.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                            </tr>

                            <tr className='compare'>
                               <td>{countStatus(displayedDatasCompare, 'incomplete')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'incomplete')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatasCompare, 'waiting')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'waiting')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatasCompare, 'valide')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'valide')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatasCompare, 'refunded')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'refunded')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatasCompare, 'canceled')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'canceled')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                               <td>{countStatus(displayedDatasCompare, 'notOnline')}</td>
                               <td>{displayedDatasCompare.length >= 0  ? `${(countStatus(displayedDatasCompare, 'notOnline')/displayedDatasCompare.length * 100 ).toFixed(2)}%` : 'Loading...'}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className='source-table'>
                    <h1>Les incomplètes</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        
                        <tbody>
                            <tr className='data'>
                                <th>Total</th>
                                <th>{total}</th>
                                <th>100%</th>
                            </tr>
                            <tr className='data'>
                                <th>Les incomplètes</th>
                                <th>{countStatus(displayedDatas, 'incomplete')}</th>
                                <th>{(countStatus(displayedDatas, 'incomplete') / total *100).toFixed(2)} %</th>
                            </tr>
                           {clientFilter.value === "all" && <tr className='data'>
                                <th>Récurrent</th>
                                <th>{countStatus(countOld, 'incomplete')}</th>
                                <th>{(countStatus(countOld, 'incomplete') / countStatus(displayedDatas, 'incomplete') *100).toFixed(2)} %</th>
                            </tr>} 
                            {clientFilter.value === "all" && <tr className='data'>
                                <th>Nouveau</th>
                                <th>{countStatus(countNew, 'incomplete')}</th>
                                <th>{(countStatus(countNew,'incomplete') / countStatus(displayedDatas, 'incomplete') *100).toFixed(2)} %</th>
                            </tr>}
                        </tbody>
                    </table>
                </div>

                <div className='source-table'>
                    <h1>Les incomplètes par étape</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(getStepObj()).map(p => <th colSpan={2} key={p}>Étape {Number(p) +1}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(getStepObj()).map(p => [
                                    <th key={`n-${p}`}>Nombre</th>,
                                    <th key={`t-${p}`}>Taux</th>,
                                ])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                {Object.keys(getStepObj()).map(p => [
                                    <td key={`v-${p}`}>{getStepObj()[p]}</td>,
                                    <td key={`p-${p}`}>{countStatus(displayedDatas, 'incomplete') > 0 ? (getStepObj()[p] / countStatus(displayedDatas, 'incomplete') *100).toFixed(2): 0} %</td>
                                ] )}  
                            </tr>                         
                        </tbody>
                    </table>
                </div>

                <div className='source-table'>
                    <h1>Par pathologie</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(getPathology()).map(p => <th colSpan={2} key={p}>{p}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(getPathology()).map(p => [
                                    <th key={`n-${p}`}>Nombre</th>,
                                    <th key={`t-${p}`}>Taux</th>,
                                ])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                {Object.keys(getPathology()).map(p => [
                                    <td key={`v-${p}`}>{getPathology()[p]}{getIcon(getPathology()[p], getPathologyCompare()[p])}</td>,
                                    <td key={`p-${p}`}>{displayedDatas.length > 0 && (getPathology()[p] / displayedDatas.length *100).toFixed(2)} %</td>
                                ] )}  
                            </tr>    
                        </tbody>
                    </table>
                </div>
                
                {
                    Object.keys(getDoctor()).length > 0 && 
                    <div className='source-table'>
                        <h1>Par médecin</h1>
                        <table className="table table-hover mb-0 analytic-table">
                            <thead>
                                <tr>
                                    {Object.keys(getDoctor()).map(p => <th colSpan={2} key={p}>{p}</th>)}
                                </tr>
                                <tr>
                                    {Object.keys(getDoctor()).map(p => [
                                        <th key={`n-${p}`}>Nombre</th>,
                                        <th key={`t-${p}`}>Taux</th>,
                                    ])}
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='data'>
                                    {Object.keys(getDoctor()).map(p => [
                                        <td key={`v-${p}`}>{getDoctor()[p]} {getIcon(getDoctor()[p], getDoctorCompare()[p])}</td>,
                                        <td key={`p-${p}`}>{displayedDatas.length > 0 && (getDoctor()[p] / displayedDatas.length *100).toFixed(2)} %</td>
                                    ] )}  
                                </tr>   
                            </tbody>
                        </table>
                    </div>
                }

                {
                    (typeState.indexOf(el => el.value === "generaliste") !== -1 || typeState.length === 0)  && motifs.length > 0 &&
                    <div className='source-table'>
                        <h1>Motifs des consultations générales</h1>
                        <table className="table table-hover mb-0 analytic-table">
                            
                            {showMore && 
                                <tbody>
                                    {motifs && motifs.map((el, i) => <tr key={`motif-${i}`}>{el.map(m =><td>{m}</td>)}</tr> )}
                                </tbody>
                            }
                            {!showMore && 
                                <tbody>
                                    <tr key={`m1`}>
                                        {motifs[0] && motifs[0].map((m, i) =><td key={`m1-${i}`}>{m}</td>)}
                                    </tr> 
                                    <tr key={`m2`}>
                                        {motifs[1] && motifs[1].map((m, i) =><td key={`m2-${i}`}>{m}</td>)}
                                    </tr> 
                                    <tr key={`m3`}>
                                        {motifs[2] && motifs[2].map((m, i) =><td key={`m3-${i}`}>{m}</td>)}
                                    </tr> 
                                </tbody>
                            }
                        </table>
                        
                        <button onClick={() => setShowMore(!showMore)}>{showMore ? 'Voir moins' : 'Voir plus'}</button>
                    </div> 
                }               
            </Panel.Body>
        </Panel>
        </div>
    );
};

export default RequestAnalytics;
