import React, { useState } from 'react';

import '../../Assets/Pages/notification.css';

import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';

const Notification = () => {
    const [inputValue, setInputValue] = useState({
        title: '',
        body: ''
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInputValue({ ...inputValue, [name]: value });
    };

    const onSend = () => {
        setLoading(true);
        fetchApi('/sendNotification', {
            method: 'POST',
            body: JSON.stringify(inputValue)
        })
          .then(() => {
              setLoading(false);
              notif.addNotification('Success', 'Notification envoye !');
          })
          .catch(() => {
            setLoading(false);
            notif.addNotification('Erreur', 'Notification non envoye ! ')
          }
        )
    };

    return (
        <div className="sendNotification">
            <p>Envoyer une notification a tout les clients</p>

            <div className="input-block">
                <input 
                    type="text"
                    onChange={handleChange}
                    name="title"
                    placeholder="Titre ..."
                    value={inputValue.title}
                />
            </div>

            <div className="input-block">
                <input 
                    type="text" 
                    name="body"
                    onChange={handleChange}
                    placeholder="Contenu ..."
                    value={inputValue.body}
                />
            </div>

            <button disabled={loading} className="send" onClick={onSend}>
                Envoyer
            </button>
        </div>
    );
};

export default Notification;
