import React, { useEffect, useState } from 'react';

import '../../Assets/Pages/notification.css';
import {  Panel, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactEcharts from 'echarts-for-react';
import isoDate from '../../Services/toIsoDate';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import { rqState } from '../../Constants';

const typeStates = [
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'kit-voyage-et-vaccination', label: 'kit-voyage-et-vaccination' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'renouvellement-ordonnance', label: 'renouvellement-ordonnance' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];
const sourceAcquisition = [
    { value: 'google', label: 'Google' },
    { value: 'bing', label: 'Bing' },
    { value: 'skycall', label: 'Skycall (sauf Skycall Voice)' },
    { value: 'skycall-voice', label: 'Skycall Voice' },
    { value: 'gescall', label: 'Gescall' },
    { value: 'medifil', label: 'Medifil' },
    { value: 'crechmed', label: 'Crechmed' },
    { value: null, label: 'No source' },
    { value: 'cortel', label: 'Cortel' },
    { value: 'pharmacy', label: 'Pharmacie' },

]

const types = [
    { value: 'urgent', label: 'Urgent' },
    { value: 'non', label: 'Normal' },
]

const Acquisition = () => {
    const [range, setRange] = useState([new Date(), new Date()]);
    const [state, setState] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [requests, setRequests] = useState([]);
    const [filteredRequests, setFilteredRequests] = useState('');
    const [filteredSource, setFilteredSource] = useState(null);


    const setOptions = (valueDatas, nameDatas) => {
        let option = {
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            series: [
                {
                  name: '',
                  type: 'pie',
                  radius: '50%',
                  data: [],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
        };
        if (!valueDatas) {
            return option;
        }
       const datas = valueDatas.map((el, i) => {
        return {value: el, name: nameDatas[i]}
    })
   
        option.series[0].data = datas
       return option;
    };

    const getRequests = () => {
        let startDate = range[0];
        let endDate= range[1];

        fetchApi(`/requests/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${filteredSource ? `&utm_source=${filteredSource.value}`: ''}`)
            .then(response => {
                setRequests(response.requests)
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    const getFilteredRequests = () => {
        let startDate = range[0];
        let endDate= range[1];

        fetchApi(`/requests/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${filteredSource ? `&utm_source=${filteredSource.value}`: '' }`)
            .then(response => {
                setFilteredRequests(response.requests)
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    useEffect(() => {
        getRequests();
    }, [])

    const filterSource = (arr, source) => {
        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).length;
        } else if (source ==="skycall-voice") {
            result = arr.filter(el => (el.utm_source === "skycall" && el.utm_content && el.utm_content.includes("voice")) || el.skycallVoice).length;
        } else if (source === "skycall") {
            result = arr.filter(el => el.utm_source === "skycall" && el.utm_content && !el.utm_content.includes("voice")).length;
        } else {
            result = arr.filter(el => el.utm_source === source).length;
        }
   
        return result;
    }

    const filterRecurrent = (arr, source) => {
        let startDate = range[0];
        let endDate= range[1];

        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else if (source ==="skycall-voice") {
            result = arr.filter(el => (el.utm_source === "skycall" && el.utm_content && el.utm_content.includes("voice")) || el.skycallVoice).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else if (source === "skycall") {
            result = arr.filter(el => el.utm_source === "skycall" && el.utm_content && !el.utm_content.includes("voice")).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        } else {
            result = arr.filter(el => el.utm_source === source).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        }

        if (source === 'medifil') {
            result = arr.filter(el => el.utm_source === source).filter(el => new Date (el.userId?.createdAt) >= new Date(isoDate(startDate, 0, 0)) && new Date (el.userId?.createdAt) <= new Date(isoDate(endDate, 23, 59)) && el.userId.request.length > 1).length;
        }
   
        return result;
    }

    const filterType = (arr, type) => {
        let result;
        if (type === "urgent") {
            result = arr.filter(el => el.priority).length
        } else {
            result = arr.filter(el => !el.priority).length
        }
        return result;
    }

    return (
        <div className="r-analytics">
            <Panel>
                <Panel.Heading className='heading'>
                    <h2>Tracking d'acquisitions</h2>
                    <div>
                        <h6>Compteur du {' '} </h6>
                        <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                    </div>
                    <div>
                        <Select
                            value={sourceAcquisition.filter(function(option) {
                                return option === filteredSource;
                              })}
                            defaultValue={filteredSource?.value || ''}
                            onChange={(v) => {
                                setFilteredSource(v)
                            }}
                            options={sourceAcquisition}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par source d'acquisition ..." 
                            className='filter-status'
                        />
                        <Select
                            value={state}
                            onChange={(v) => setState(v)}
                            options={rqState}
                            isMulti
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par status ..." 
                            className='filter-status'
                        />
                        <Select
                            value={typeState}
                            onChange={(v) => setTypeState(v)}
                            options={typeStates}
                            isMulti
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            placeholder="Filtre par pathologie ..." 
                            className='filter-status'
                        />
                        <button className='submit-button' onClick={() => {
                            getRequests();
                            getFilteredRequests();
                        }}>Chercher</button>
                    </div>
                </Panel.Heading>
                <Panel.Body>
                    <div>
                        <h3>Total: {requests.length}</h3>
                        <h3>Consultation Urgent</h3>
                        <Row>
                            <Col lg={7} md={6} sm={12} xs={12}>
                                <table className="table table-hover mb-0 analytic-table">
                                    <thead>
                                        <th>Type</th>
                                        <th>Nombre de requêtes</th>
                                        <th>Pourcentage</th>
                                    </thead>
                                    <tbody>
                                        {
                                            types.map(type => <tr key={type.value}>
                                                <td>{type.label}</td>
                                                <td>{filterType(requests, type.value)}</td>
                                                <td>{((filterType(requests, type.value) / requests.length) * 100).toFixed(2)} %</td>
                                          
                                            </tr>)
                                        }                            
                                    </tbody>
                                </table>
                            </Col>
                            <Col lg={5} md={6} sm={12} xs={12}>
                                <ReactEcharts
                                    option={setOptions(
                                        types.map(type => (filterType(requests, type.value) / requests.length)),
                                        types.map(type => type.label)
                                    )}
                                    style={{ height: '250px', width: '100%' }}
                                />  
                            </Col>
                        </Row>
                        <h3>Par source d'acquisition</h3>
                        <Row>
                            <Col lg={7} md={6} sm={12} xs={12}>
                                <table className="table table-hover mb-0 analytic-table">
                                    <thead>
                                        <th>Source d'acquisition</th>
                                        <th>Nombre de requêtes</th>
                                        <th>Pourcentage</th>
                                        <th>Récurrence</th>
                                        <th>Pourcentage</th>
                                    </thead>
                                    <tbody>
                                        {
                                            sourceAcquisition.sort(function(a,b) {
                                                return filterSource(requests, b.value) - filterSource(requests, a.value)
                                            }).map(source => <tr key={source.value}>
                                                <td>{source.label}</td>
                                                <td>{filterSource(requests, source.value)}</td>
                                                <td>{((filterSource(requests, source.value) / requests.length) * 100).toFixed(2)} %</td>
                                                <td>{filterRecurrent(requests, source.value)}</td>
                                                <td>{((filterRecurrent(requests, source.value)/filterSource(requests, source.value))*100).toFixed(2) || 0} % </td>
                                            </tr>)
                                        }                            
                                    </tbody>
                                </table>
                            </Col>
                            <Col lg={5} md={6} sm={12} xs={12}>
                                <ReactEcharts
                                    option={setOptions(
                                        sourceAcquisition.map(source => (filterSource(requests, source.value) / requests.length)),
                                        sourceAcquisition.map(source => source.label)
                                    )}
                                    style={{ height: '350px', width: '100%' }}
                                />  
                            </Col>
                        </Row>
                    </div>
                    {filteredSource && <h1>{filteredSource.label}</h1>}
                    {
                        filteredRequests.length > 0 &&
                        <div className="table-responsive">
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th>Utilisateur</th>
                                        <th>Email</th>
                                        <th>Date de creation</th>
                                        <th>Type</th>
                                        <th>Etat</th>
                                        <th>Docteur</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredRequests.map((r) => (
                                        <tr key={r._id}>               
                                            <td> {r.userId?.FName} {r.userId?.LName}</td>
                                            <td>{r.userId?.email}</td>
                                            <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                                            <td>{r.type}</td>
                                            <td>{r.state === "waiting" && r.notOnlineReason ? 'Indisponible à distance' : rqState.find(el => el.value === r.state)?.label}</td>
                                            <td>
                                                {r.doctorId
                                                    ? r.doctorId.FName + ' ' + r.doctorId.LName
                                                    : ''}
                                            </td>
                                            <td>
                                                <IconTooltip
                                                    icon="fas fa-file-medical"
                                                    text="Details"
                                                    onClick={() =>
                                                        this.props.history.push(
                                                            '/requests/detail/' + r._id
                                                        )
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default Acquisition;
