import React from 'react';

import '../../Assets/Ui/Notification.css';

// Singleton design pattern
export let notif = null;

export class NotificationManager extends React.Component {
    constructor() {
        super();

        this.state = {
            notifications: []
        };

        notif = this;

        this.counter = 0;
    }

    addNotification(title, message, type) {
        let instance = null;

        const remove = () => {
            if (instance === null) {
                return;
            }

            this.setState({
                notifications: this.state.notifications.filter(
                    n => n !== instance
                )
            });
        };

        const n = (
            <Notification
                key={this.counter++}
                title={title}
                type={type}
                message={message}
                remove={remove}
            />
        );

        instance = n;

        this.setState({ notifications: [n] });
    }

    render() {
        return (
            <div className="NotificationManager">
                {this.state.notifications}
            </div>
        );
    }
}

class Notification extends React.Component {
    constructor() {
        super();

        this.state = {
            closing: false
        };

        this.handleClose = this.handleClose.bind(this);

        this.to = null;
        this.toClose = null;
    }

    handleClose() {
        clearTimeout(this.toClose);
        this.setState({ closing: true });
        this.to = setTimeout(this.props.remove, 750);
    }

    componentDidMount() {
        this.toClose = setTimeout(this.handleClose, 5000);
    }

    componentWillUnmount() {
        clearTimeout(this.to);
    }

    render() {
        return (
            <div
                style={{
                    backgroundColor: this.props.type === 'info' ? '#f79e13' : this.props.type === 'warning' ? '#ff5959' : '#37ff5f'
                }}
                className={`Notification ${
                    this.state.closing ? 'bounceOut' : 'bounceIn'
                }`}
            >
                <span className="closeNotif" onClick={this.handleClose}>
                    ×
                </span>
                <h2>{this.props.title}</h2>
                <p>{this.props.message}</p>
            </div>
        );
    }
}
