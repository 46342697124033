import { Component } from 'react';
import { Col, Panel, Row } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';

import debounce from '../../Services/debounce';
import IconTooltip from '../Ui/IconTooltip';
import { notif } from '../Ui/Notification';


class PrePharmacy extends Component {
    constructor() {
        super();

        this.state = {
            data: [],
            totalPage: 0,
            sort: true,
            input: '',
            page: 1,
            question4: ''

        };

        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
        this.getData = this.getData.bind(this);

        this.debounceSearch = debounce(this.getData, 500);
    }

    getData(page = 1) {
        fetchApi(`/prepharmacy/all?page=${page}${this.state.input.trim() ? `&search=${this.state.input}` : ''}`)
            .then((res) => {
                this.setState({ data: res.data, totalPage: res.countPages });
            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getData();
    }

    updatePage(page) {
        this.getData(page);
    }

    sortInt(sortKey, sortSecond) {
        const data = this.state.data;

        if (this.state.sort) {
            data.sort((a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]);
        } else {
            data.sort((a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]);
        }

        this.setState({ data, sort: !this.state.sort });
    }

    onSort(sortKey) {
        const data = this.state.data;
        if (this.state.sort) {
            data.sort((a, b) => {
                return a[sortKey].localeCompare(b[sortKey]);                
            });
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => {
                return b[sortKey].localeCompare(a[sortKey]);
            });
            this.setState({ sort: true });
        }
        this.setState({ data });
    }


    render() {
        const page = this.state.page;
        return (
            <div className="prepharmacy">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Pre-inscription pharmacie</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>
                                    <p>
                        Liste des pre-inscriptions des pharmacies (Page <strong>{page}</strong> /{' '}
                                        {this.state.totalPage})
                                    </p>
                                    {page > 1 && (
                                        <IconTooltip
                                            icon="fa fa-chevron-left"
                                            text="Page précédente"
                                            onClick={() => this.updatePage(page - 1)}
                                        />
                                    )}
                                    {page < this.state.totalPage && (
                                        <IconTooltip
                                            icon="fa fa-chevron-right"
                                            text="Page suivante"
                                            onClick={() => this.updatePage(page + 1)}
                                        />
                                    )}
                                    <div>
                                        <input
                                            type="text"
                                            className={'input-search'}
                                            placeholder="Taper le nom ou l'email d'une pharmacie"
                                            value={this.state.input}
                                            onChange={(e) =>
                                                this.setState({ input: e.target.value }, () => 
                                                    this.debounceSearch()  
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div style={{ overflowX: 'auto' }}>
                                    <table className="table table-hover mb-0 prepharmacy-table">
                                        <thead>
                                            <tr>
                                                <th
                                                    style={{ width: 200}}
                                                    className="sortable"
                                                    onClick={() => this.onSort('name')}
                                                >
                              Nom
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                               
                                                <th
                                                    style={{ width: 200}}
                                                    className="sortable"
                                                    onClick={() => this.onSort('resp_name')}
                                                >
                              Nom et prenom du reponsable
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>

                                                <th
                                                    style={{ width: 150}}
                                                    className="sortable"
                                                    onClick={() => this.onSort('address')}
                                                >
                              Addresse
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    style={{ width: 150}}
                                                    className="sortable"
                                                    onClick={() => this.onSort('phone')}
                                                >
                              Phone
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>

                                                <th
                                                    style={{ width: 150}}
                                                    className="sortable"
                                                    onClick={() => this.onSort('email')}
                                                >
                              Email
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    style={{ width: 100}}
                                                    className="sortable"
                                                >
                              Cabine de teleconsultation
                                                </th>
                                                <th
                                                    style={{ width: 100}}
                                                    className="sortable"
                                                >
                              Outil de teleconsultation en moins de 10mn
                                                </th>
                                                <th
                                                    style={{ width: 450}}
                                                    className="sortable"
                                                >
                              Utilise un outil de téléconsultation dans l'officine
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.data.map((d) => {

                                                return (
                                                    (
                                                        <tr key={d._id}>
                                                            <td>
                                                                {d.name}
                                                            </td>
                                                            <td>{d.resp_name}</td>
                                                            <td>{d.address}</td>
                                                            <td>{d.phone}</td>
                                                            <td>{d.email}</td>
                                                            <td>{d.question1 ? 'Oui' : 'Non'}</td>
                                                            <td>{d.question2 ? 'Oui' : 'Non'}</td>
                                                            <td>
                                                                <div>
                                                                    <p>{d.question3 ? 'Oui' : 'Non'}</p>
                                                                    <hr />
                                                                    <p>{d.question3 ? ' Intéressé par un outil sans abonnement et sans perte d\'espace' : 'Découvrir le consultation par QrCode'} : {d.question4 ? 'Oui' : 'Non'}</p>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
};

export default PrePharmacy;