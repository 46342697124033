import React from 'react';
import { Row, Col, Panel, Modal, Button } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import isoDate from '../../Services/toIsoDate.js';

import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';
import debounce from '../../Services/debounce.js';
import { exportAsExcel } from '../../Services/exportAsExcel';

import UserCounter from '../Ui/UserCounter';

export default class Users extends React.Component {
    constructor() {
        super();

        this.state = {
            users: [],
            totalPage: 0,
            input: '',
            page: 1,
            month: null,
            type: { value: 'all', label: 'Tous' },
            numberR:  { value: 'all', label: 'Tous' },
            countUsers: 0,
            loading: false,
            isExporting: false,
            selectedState: [],
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            showFilterModal: false
        };


        this.handleRemove = this.handleRemove.bind(this);
        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.filterModal = this.filterModal.bind(this);

        this.debounceGetUser = debounce(this.getUsers, 250);
    }

    getUsers() {
        this.setState({ loading: true });
        let startDate, endDate;

        if (this.state.month) {
            startDate = isoDate(`${this.state.month.split('-')[1]}/01/${this.state.month.split('-')[0]}`, 0, 0);
            endDate = Number(this.state.month.split('-')[1]) < 12 ? isoDate(`${Number(this.state.month.split('-')[1]) + 1}/01/${this.state.month.split('-')[0]}`, 0, 0) : isoDate(`01/01/${Number(this.state.month.split('-')[0]) +1 }`, 0, 0);
        }

        const searchInput = this.state.input.length === 0 ? '' : this.state.input[0] === '0' || this.state.input[0] === '+' ? this.state.input.substring(1, this.state.input.length) : this.state.input;
   
        fetchApi(`/users?page=${this.state.page}${this.state.input.trim() ? `&search=${searchInput}` : ''}&start=${this.state.month ? startDate : ''}&end=${this.state.month? endDate : ''}${this.state.numberR.value ? `&numberR=${this.state.numberR.value}` : ''}`)
            .then((res) =>
                    this.setState({ users: res.users, totalPage: res.countPages, loading: false, countUsers: res.countUsers })
            )
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees Utilisateur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getUsers();
    }

    updatePage(page) {
        let startDate, endDate;
        if (this.state.month) {
            startDate = isoDate(`${this.state.month.split('-')[1]}/01/${this.state.month.split('-')[0]}`, 0, 0);
            endDate = Number(this.state.month.split('-')[1]) < 12 ? isoDate(`${Number(this.state.month.split('-')[1]) + 1}/01/${this.state.month.split('-')[0]}`, 0, 0) : isoDate(`01/01/${Number(this.state.month.split('-')[0]) +1 }`, 0, 0);
        }
        this.setState({ users: [], page, loading: true }, () => {
            fetchApi(`/users?page=${this.state.page}${this.state.input.trim() ? `&search=${this.state.input}` : ''}&start=${this.state.month ? startDate : ''}&end=${this.state.month? endDate : ''}${this.state.numberR.value ? `&numberR=${this.state.numberR.value}` : ''}`)
                .then((res) => {
                    this.setState({ users: res.users, totalPage: res.countPages, loading: false, countUsers: res.countUsers });
                })
                .catch((err) =>
                    notif.addNotification(
                        'Erreur',
                        'Error while getting the users: ' + err,
                        'warning'
                    )
                );
        });
    }

    sortInt(sortKey, sortSecond) {
        const users = this.state.users;

        if (this.state.sort) {
            users.sort((a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]);
        } else {
            users.sort((a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]);
        }

        this.setState({ users: users, sort: !this.state.sort });
    }

    onSort(sortKey, sortSecond) {
        if (sortSecond) {
            const users = this.state.users;

            if (this.state.sort) {
                users.sort((a, b) =>
                    a[sortKey][sortSecond].localeCompare(b[sortKey][sortSecond])
                );
            } else {
                users.sort((a, b) =>
                    b[sortKey][sortSecond].localeCompare(a[sortKey][sortSecond])
                );
            }
        } else {
            const data = this.state.users;
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
            this.setState({ users: data });
        }
    }

    handleRemove(id) {
        const user = this.state.users.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Cette action est reversible',
            text: ``,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi('/users', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: user._id
                    })
                }).then(() => {
                    this.getUsers()
                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Utilisateur supprime',
                        'success'
                    );
                });
            }
        });
    }

    exportToExcel() {
        this.setState({ isExporting: true }, () => {
            fetchApi(`/requests/users/getMail`, {
            method: 'PUT',
            body: JSON.stringify({
                start: isoDate(this.state.startDate, 0, 1),
                end: isoDate(this.state.endDate, 23, 59),
                type: this.state.selectedState
            })
        })
            .then((res) => {
                const toExport = res.data.map((v) => ({
                    'Nom et prenom': `${v.FName} ${v.LName}`,
                    'Email': v.email,
                    'Téléphone': v.phone, 
                    "Date d'inscription": new Date(v.createdAt).toLocaleDateString(),
                    "Récurrence" : v.request && v.request.length >  1 ? v.request.length - 1 : "Non",
                    "Type de demande" : v.request?.map(el => el.type).toString() || ''
                }));

                const cls = [
                    { wch: 30 },
                    { wch: 30 },
                ]

                this.setState({ isExporting: false, showFilterModal: false, selectedState: [] }, () => {
                    notif.addNotification(
                        'Succes !',
                        '',
                        'success'
                    )
                    exportAsExcel(toExport, `emails_${new Date().toISOString()}`, cls);
                } );
            })
            .catch((err) => {
                this.setState({ isExporting: false, showFilterModal: false }, () => {
                    notif.addNotification(
                        'Erreur',
                        'Error while getting the users: ' + err,
                        'warning'
                    );
                });
            });
        });
    }


    filterModal() {

        return (
            <Modal show={this.state.showFilterModal}>
                <Modal.Header>
                    <Modal.Title>Filtre</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        De : <input 
                                type="date" 
                                value={this.state.startDate} 
                                onChange={(e) => this.setState({ startDate: e.target.value })} 
                                max={this.state.endDate || new Date().toISOString().split('T')[0]}
                            />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                       au : <input 
                                type="date" 
                                value={this.state.endDate} 
                                onChange={(e) => this.setState({ endDate: e.target.value })} 
                                min={this.state.startDate}
                            />
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showFilterModal: false, selectedState: [] })}>Fermer</Button>
                    <Button disabled={this.state.isExporting} onClick={() => this.exportToExcel()} bsStyle="primary">{this.state.isExporting ? 'Chargement ...' : 'Valider'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        if (!this.state.users) {
            return <div>Récupération des données en cours...</div>;
        }
        const page = this.state.page;

        return (
            <div className="pT-25">
                {this.filterModal()}
                <Row>
                    <Col md={12}>
                        <UserCounter
                            isPartner={this.props.role === 4} 
                            utm_source={this.props.utm_source}
                            month={this.state.month}
                            setMonth={(val) => this.setState({ month: val, page: 1 }, () => {
                                this.getUsers();
                            })}
                            typeOption={this.state.type}
                            setTypeOption={(val) => this.setState({ type: val, page: 1 }, () => {
                                this.getUsers();
                            })}
                            numberRequest={this.state.numberR}
                            setNumberRequest={(val) => this.setState({ numberR: val, page: 1 }, () => {
                                this.getUsers();
                            })}
                            loading={this.state.loading}
                            count={this.state.countUsers}
                        />

                        <Panel>
                            <Panel.Heading>
                                <h6>Utilisateurs</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>
                                    <div style={{ margin: '10px 0' }}>
                                        <button 
                                            className="button-export" 
                                            onClick={() => this.setState({ showFilterModal: true })}
                                            disabled={this.state.showFilterModal}
                                        >
                                            Exporter les emails en excel
                                        </button>
                                    </div>
                                    
                                    <div>
                                        <input
                                            type="text"
                                            className={'input-search'}
                                            placeholder="Taper le nom, le prénom, le mail ou le numéro téléphone d'un client"
                                            value={this.state.input}
                                            onChange={(e) => {
                                                this.setState({ input: e.target.value, page: 1 }, () => { 
                                                    this.debounceGetUser();
                                                });
                                            }}
                                        />
                                    </div>

                                    <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>

                                        <p>
                                        Liste des utilisateurs (Page <strong>{page}</strong> /{' '}
                                            {this.state.totalPage})
                                        </p>

                                        <div>
                                            {page > 1 && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-left"
                                                    text="Page précédente"
                                                    onClick={() => this.updatePage(page - 1)}
                                                />
                                            )}
                                            {page < this.state.totalPage && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-right"
                                                    text="Page suivante"
                                                    onClick={() => this.updatePage(page + 1)}
                                                />
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('FName')}
                                                >
                          Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('createdAt')}
                                                >
                          Date d'inscription
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('lastLoginDate')}
                                                >
                          Derniere connexion
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.sortInt('request', 'length')}
                                                >
                          Requetes
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((d) => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.FName} {d.LName}
                                                    </td>
                                                    <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                    <td>
                                                        {dateFormat(d.lastLoginDate, 'dd/mm/yy HH:MM')}
                                                    </td>
                                                    <td>{d.request.length}</td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-user"
                                                            text="Voir le profil"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/users/detail/' + d._id
                                                                )
                                                            }
                                                        />
                                                        {!d.deleted && (
                                                            <IconTooltip
                                                                icon="fa fa-close danger"
                                                                text="Supprimer"
                                                                onClick={() => this.handleRemove(d._id)}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
