import React from 'react';
import QRCode from 'react-qr-code';
import Swal from 'sweetalert2';

import '../../Assets/Pages/pharmacy.css';

import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';

class Pharmacy extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: true,
            pharmacy: [],
            showCode: {
                state: false,
                item: {}
            }
        };

        this.swal =  Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        this.refQr = React.createRef();
        this.handleAdd = this.handleAdd.bind(this);
        this.getAll = this.getAll.bind(this);
    }

    getAll() {
        fetchApi('/pharmacy/all')
            .then((res) => {
                const sorted = res.pharmacies.sort((a, b) => {
                    const a1 = a.name.split(' ')[1];
                    const b1 = b.name.split(' ')[1];
                    return a1 && b1 ? a1.localeCompare(b1) : -1;
                });
                this.setState({ loading: false, pharmacy: sorted });
            })
            .catch((err) => {
                this.setState({ loading: false });
                console.log(err);
            });
    }

    componentDidMount() {
        this.getAll();
    }

    handleAdd() {
        this.swal({
            title: 'Ajouter un pharmacy',
            confirmButtonText: 'Ajouter',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            reverseButtons: true,
            html: `<input type="text" id="name" class="swal2-input" placeholder="Nom ...">
            <input type="text" id="address" class="swal2-input" placeholder="Addresse ...">`,
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#name').value;
                const address = Swal.getPopup().querySelector('#address').value;
                if (!name || !address) {
                    Swal.showValidationMessage(`Veuillez remplir tout les champs !`);
                }
                return { name, address };
            }
        }).then((res) => {
            fetchApi('/pharmacy/add', {
                method: 'POST',
                body: JSON.stringify({
                    address: res.value.address,
                    name: res.value.name
                })
            }).then(() => {
                this.getAll();
                notif.addNotification('Success !', 'Pharmacy ajoute !');
            })
                .catch(() => notif.addNotification('Erreur !', 'Pharmacy non ajoute !'));
        });
    }

    handleEdit(v) {
        this.swal({
            title: `Modifier ${v.name}`,
            confirmButtonText: 'Valider',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            reverseButtons: true,
            html: `<input value="${v.name}" type="text" id="name" class="swal2-input" placeholder="Nom ...">
            <input value="${v.address}" type="text" id="address" class="swal2-input" placeholder="Addresse ...">`,
            preConfirm: () => {
                let name = Swal.getPopup().querySelector('#name').value;
                let address = Swal.getPopup().querySelector('#address').value;

                if (!name || !address) {
                    Swal.showValidationMessage('Veuillez remplir tout les champs !');
                } else if (v.name === name && v.address === address) {
                    Swal.showValidationMessage('Aucun changement !');
                }

                return { name, address, id: v._id };
            }
        }).then((res) => {
            fetchApi(`/pharmacy/${res.value.id}`, {
                method: 'PUT',
                body: JSON.stringify({
                    address: res.value.address,
                    name: res.value.name,
                    _id: res.value.id
                })
            }).then(() => {
                this.getAll();
                notif.addNotification('Success !', 'Pharmacy modifie !');
            })
                .catch(() => notif.addNotification('Erreur !', 'Pharmacy non modifie !'));
        });;
    }

    handleDelete(v) {
        this.swal({
            title: `Supprimer ${v.name}`,
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            reverseButtons: true
        }).then((res) => {
            if (res.value) {
                fetchApi(`/pharmacy/${v._id}`, {
                    method: 'DELETE',
                    body: JSON.stringify({
                        _id: v._id
                    })
                }).then(() => {
                    const s = this.state.pharmacy.filter((k) => k._id !== v._id);
                    this.setState({ pharmacy: s }, () => {
                        notif.addNotification('Success !', 'Pharmacy supprime !');
                    });
                })
                    .catch(() => notif.addNotification('Erreur !', 'Pharmacy non supprime !'));
            }
        });;
    }

    render() {
        return this.state.loading ? (
            <p>Loading ...</p>
        ) : (
            <div className="pharmacy">
                <button onClick={this.handleAdd} className="add-btn" type="button">Ajouter</button>
                {this.state.showCode.state && (
                    <div onClick={() => this.setState({ showCode: { state: false, item: {} } })} className="qrCode">
                        <div ref={this.refQr}>
                            <QRCode title={this.state.showCode.item.name} value={`https://feeli.io/?utm_source=pharmacy_${this.state.showCode.item._id}`} />
                            <p className="qr-warn">{`Scanner: ${this.state.showCode.item.name}`}</p>
                        </div>
                    </div>
                )}
                {this.state.pharmacy.map((v) => ( 
                    <div 
                        key={v._id} 
                        className="pharmacy-item"
                    >
                        <span className="zmdi zmdi-hospital"></span>
                        <p>{v.name}</p>
                        <p 
                            onClick={() =>  {
                                this.setState({ showCode: {
                                    state: true,
                                    item: v
                                } }, () => {
                                    if (this.refQr && this.refQr.current) {
                                        this.refQr.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    }
                                });
    
                            }} 
                            className="show-text"
                        >
                            Afficher le QRCode
                        </p>
                        <div className="icon-block">
                            <span onClick={() => this.handleEdit(v)} style={{ fontSize: 17, cursor: 'pointer' }} className="zmdi zmdi-edit"></span>
                            &nbsp;
                            &nbsp;
                            <span onClick={() => this.handleDelete(v)} style={{ fontSize: 17, cursor: 'pointer' }} className="zmdi zmdi-delete"></span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
};

export default Pharmacy;
