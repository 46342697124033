import React from 'react';
import { endpoint } from '../../Constants';
import AuthService from '../../Services/AuthService';

import '../../Assets/Pages/login.css';
import { notif } from '../Ui/Notification';

export default class Login extends React.Component {
    constructor() {
        super();

        this.state = {
            login: '',
            password: '',
            isPartner: false,
            isLocked: false,
            lockTime: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        if (!this.state.isLocked) {
            fetch(`${endpoint}/login${this.state.isPartner ? '/partner' : ''}`, {
                method: 'POST',
                body: JSON.stringify({
                    username: this.state.login,
                    password: this.state.password
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (!res.success) {
                        if (res.time) {
                            this.setState({ isLocked: true, lockTime: res.time })
                        }
    
                        return notif.addNotification('Erreur', res.message, 'warning');
                    }
    
                    new AuthService().setToken(res.token);
                    this.props.history.push('/');
                })
                .catch(err => {
                    notif.addNotification('Erreur', err, 'warning')
                });
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.state.isLocked !== prevState.isLocked) && this.state.isLocked) {
            let time = this.state.lockTime;
            const timer = setInterval(() => {
                time--;
                this.setState({ lockTime: time });
                if (time === 0) {
                    clearInterval(timer);
                    this.setState({ isLocked: false })
                }
            }, 1000);
        }
    }


    render() {
        const mn = Math.floor(this.state.lockTime / 60) + ":" + (this.state.lockTime % 60).toString().padStart(2, '0');


        return (
            <div className="background-container">
                <img
                    src="/assets/images/logo-feeli-white.png"
                    className="logo-feeli"
                    alt="Feeli"
                />
                <div className="login-page">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="login">Username</label>
                            <input
                                type="text"
                                name="login"
                                value={this.state.value}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                                type="password"
                                name="password"
                                value={this.state.password}
                                onChange={this.handleChange}
                            />
                        </div>
                        {this.state.isLocked && (
                            <p style={{ textAlign: 'center', paddingTop: 5, paddingBottom: 10 }}>Réessayez dans {mn}</p>
                        )}
                        <input disabled={this.state.isLocked} type="submit" value="Connexion" />
                        <div style={{ marginTop: 10 }}>
                            <input onChange={() => this.setState({ isPartner: !this.state.isPartner })} style={{ width: '10%', marginRight: 10, verticalAlign: 'center' }} type="checkbox" name="partner" id="" />
                            <span>Vous etes un partenaire ?</span>
                        </div>
                    </form>

                </div>
            </div>
        );
    }
}
