import React, { useEffect, useState } from 'react';
import { Panel } from 'react-bootstrap';

import '../../Assets/Ui/RequestCounter.css';

import { fetchApi } from '../../Network/NetworkService';
import isoDate from '../../Services/toIsoDate.js';

const NewslettersCounter = () => {

    const [filterValue, setFilterValue] = useState({
        start: '',
        end: '',
    });
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);

    const { start, end } = filterValue;

    const getCount = () => {
        setLoading(true);
        fetchApi(`/newsletters/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...( start && { start: isoDate(start, 0, 0) }),
                ...( end && { end: isoDate(end, 23, 59) }),
            })
        })
            .then((res) => {
                setCount(res.count);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setCount('Error !');
            });
    };

    const handleDateChange = (e) => {
        setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        getCount();
    }, [start, end]);

    return (
        <Panel>
            <Panel.Heading>
                <h6>Compteur</h6>
            </Panel.Heading>
            <Panel.Body>
                <div className="counter">
                    <div className="filter-block">
                           <>
                            <div className="date-block">
                              <p>Debut</p>
                              <div className="inputDate">
                                <input  
                                    max={filterValue.end || new Date().toISOString().split('T')[0]}
                                    name="start" 
                                    onChange={handleDateChange} 
                                    type="date"
                                    value={filterValue.start}
                                />
                              </div>
                            </div>

                           <div className="date-block">
                            <p>Fin</p>
                            <div className="inputDate">
                                <input
                                    min={filterValue.start}
                                    name="end"   
                                    onChange={handleDateChange} 
                                    type="date" 
                                    value={filterValue.end}
                                />
                              </div>
                            </div>
                           </>
                    </div>

                    <div className="nb-block">
                        <Panel>
                            <Panel.Heading>
                                <h3>Total</h3>
                                {loading ? (
                                    <span className="loader" />
                                ) : (
                                    <h2>{count}</h2>
                                )}
                            </Panel.Heading>
                        </Panel>
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};

export default NewslettersCounter;
