import React, { useEffect, useState } from 'react';

import '../../Assets/Pages/activeDoctor.css';

import { socket } from '../../Constants';
import { fetchApi } from '../../Network/NetworkService';

const DoctorActive = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = (ids) => {
        fetchApi('/doctors/active', {
            method: 'POST',
            body: JSON.stringify({ ids })
        }).then((res) => {
            setLoading(false);
            setData(res.doctors);
            setIsExporting(false);
        }).catch((err) => {
            setLoading(false);
            console.log(err);
        });
    };

    useEffect(() => {
        socket.emit('client-admin');
        socket.on('activeDoctors', (ids) => getData(ids));

        return () => socket.off('activeDoctors');
    }, []);

    const handleRefresh = () => {
        socket.emit('client-admin');
    };

    return (
        <div className="doctor-active">
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <p>Les docteurs disponibles</p>
                <button onClick={handleRefresh} type="button" className="zmdi zmdi-refresh-alt"></button>
            </div>

            <div className="table-responsive">
                <table className="table table-hover mb-0">
                    <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Disponible</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? 'Loading ...' : data.map((v) => (
                            <tr key={v._id}>
                                <td>
                                    <span onClick={() => props.history.push(`/doctors/detail/${v._id}`)}>
                                        {`${v.FName} ${v.LName}`}
                                    </span>
                                </td>
                                <td>
                                    <i className="zmdi zmdi-check-circle"></i>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DoctorActive;
