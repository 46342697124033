import React, { useState, useEffect } from 'react';
import { Row, Col, Panel } from 'react-bootstrap';

import { fetchApi } from '../../Network/NetworkService';
import '../../Assets/Ui/WeeklyCounter.css';
import ReactEcharts from 'echarts-for-react';

const getDate = (input, h = 0, m = 0, end) => {
  let n;
  if (input) {
    if (end) {
      n = new Date(Number(input.split('-')[0]), Number(input.split('-')[1]), 0);
    } else {
      n = new Date(`${input.split('-')[0]}/${input.split('-')[1]}/01`);
    }
  } else {
    n = new Date();
  }

  n.setHours(h, m, 0);
  return n.toISOString();
};

const getXAxisData = () => {
    let data = [];
    for (let i = 1; i < 31; i++) {
        data.push(i)
    }
    return data;
}

const options = {
    title: {
        text: ''
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: ['', '']
    },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#878787'
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#878787',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: 12
                }
            },
            splitLine: {
                show: false
            },
            data: []
        }
    ],
    yAxis: [
        {
            type: 'value',
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#878787'
                }
            },
            axisLabel: {
                textStyle: {
                    color: '#878787',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: 12
                }
            },
            splitLine: {
                show: false
            }
        }
    ],
    series: [
        {
            name: '',
            type: 'line',
            data: [],
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#09B1D0'
                }
            }
        },
        {
            name: '',
            type: 'line',
            data: [],
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#dedede'
                }
            }
        }
    ]
};

const WeeklyCounter = () => {
    const [month, setMonth] = useState(`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`);
    const [lastMonth, setLastMonth] = useState(`${new Date().getMonth() === 0 ? `${new Date().getFullYear() - 1}-12` : `${new Date().getFullYear()}-0${new Date().getMonth()}`}`);
    const [usersOfMonth, setUsersOfMonth] = useState([]);
    const [usersOfLastMonth, setUsersOfLastMonth] = useState([]);
    const [requestsOfMonth, setRequestsOfMonth] = useState([]);
    const [requestsOfLastMonth, setRequestsOfLastMonth] = useState([]);

    useEffect(() => {
        getCount();
    }, [month, lastMonth]);

    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    const handleLastMonthChange = (e) => {
        setLastMonth(e.target.value);
    };

    const getCount = () => {
        fetchApi(`/users/stats`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { startDate: getDate(month, 0, 0) } ),
                ...( { endDate: getDate(month, 0, 0, true) } ),
            })
        })
            .then((res) => {
                setUsersOfMonth(res.stats)
            })
            .catch((err) => {
               console.log(err)
            });

        fetchApi(`/users/stats`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { startDate: getDate(lastMonth, 0, 0) } ),
                ...( { endDate: getDate(lastMonth, 0, 0, true) } ),
            })
        })
            .then((res) => {
                setUsersOfLastMonth(res.stats)
            })
            .catch((err) => {
                console.log(err)
            });

        fetchApi(`/requests/stats`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { startDate: getDate(month, 0, 0) } ),
                ...( { endDate: getDate(month, 23, 59, true)} ),
                ...( { state: 'approved,reviewed,terminated,notOnline,waiting,toCall,refunded,canceled' } ),
            })
        })
            .then((res) => {
                setRequestsOfMonth(res.stats)
            })
            .catch((err) => {
                console.log(err)
            });

        fetchApi(`/requests/stats`, {
            method: 'POST',
            body: JSON.stringify({
                ...( { startDate: getDate(lastMonth, 0, 0) } ),
                ...( { endDate: getDate(lastMonth, 23, 59, true) } ),
                ...( { state: 'approved,reviewed,terminated,notOnline,waiting,toCall,refunded,canceled' } ),
            })
        })
            .then((res) => {
                setRequestsOfLastMonth(res.stats)
            })
            .catch((err) => {
                console.log(err)
            });
    }

    const getUsersOptions = () => {
        if (!usersOfMonth || !usersOfLastMonth) {
            return JSON.parse(JSON.stringify(options));
        }

        const usesOptions = options;
        const xAxisData = getXAxisData();

        const xAxisLegend1 = [
            ...new Set([
                ...xAxisData.map(
                    el =>el.toString() + '/' + month.split('-')[1].toString() + '/' + month.split('-')[0].toString() 
                )
            ])
        ];
        const xAxisLegend2 = [
            ...new Set([
                ...xAxisData.map(
                    el =>el.toString() + '/' + lastMonth.split('-')[1].toString() + '/' + lastMonth.split('-')[0].toString() 
                )
            ])
        ];

        const xAxisDataUsers1 = [];
        const xAxisDataUsers2 = [];

        for (let i = 0; i < xAxisLegend1.length; i++) {
            const year = parseInt(xAxisLegend1[i].split('/')[2]);
            const month = parseInt(xAxisLegend1[i].split('/')[1]);
            const day = parseInt(xAxisLegend1[i].split('/')[0]);

            const users1Registered = usersOfMonth.find(
                ({ _id }) => _id.year === year && _id.month === month && _id.day === day
            );

            if (!users1Registered) {
                xAxisDataUsers1.push(0);
            } else {
                xAxisDataUsers1.push(users1Registered.count);
            }
        }

        for (let i = 0; i < xAxisLegend2.length; i++) {
            const year = parseInt(xAxisLegend2[i].split('/')[2]);
            const month = parseInt(xAxisLegend2[i].split('/')[1]);
            const day = parseInt(xAxisLegend2[i].split('/')[0]);

            const users2Registered = usersOfLastMonth.find(
                ({ _id }) => _id.year === year && _id.month === month && _id.day === day
            );

            if (!users2Registered) {
                xAxisDataUsers2.push(0);
            } else {
                xAxisDataUsers2.push(users2Registered.count);
            }
        }
       
        usesOptions.xAxis[0].data = xAxisLegend1;
        usesOptions.series[0].data = xAxisDataUsers1;
        usesOptions.series[1].data = xAxisDataUsers2;
        usesOptions.title.text = "Utilisateurs";
        usesOptions.legend.data = [month, lastMonth]; 
        usesOptions.series[0].name = month;
        usesOptions.series[1].name = lastMonth;
        usesOptions.series[0].itemStyle.normal.color = '#8051A2'

        return JSON.parse(JSON.stringify(usesOptions));
    };

    const getRequestsOptions = () => {
        if (!requestsOfMonth || !requestsOfLastMonth) {
            return JSON.parse(JSON.stringify(options));
        }

        const requestsOptions = options;
        const xAxisData = getXAxisData();

        const xAxisLegend1 = [
            ...new Set([
                ...xAxisData.map(
                    el =>el.toString() + '/' + month.split('-')[1].toString() + '/' + month.split('-')[0].toString() 
                )
            ])
        ];
        const xAxisLegend2 = [
            ...new Set([
                ...xAxisData.map(
                    el =>el.toString() + '/' + lastMonth.split('-')[1].toString() + '/' + lastMonth.split('-')[0].toString() 
                )
            ])
        ];

        const xAxisDataRequest1 = [];
        const xAxisDataRequest2 = [];

        for (let i = 0; i < xAxisLegend1.length; i++) {
            const year = parseInt(xAxisLegend1[i].split('/')[2]);
            const month = parseInt(xAxisLegend1[i].split('/')[1]);
            const day = parseInt(xAxisLegend1[i].split('/')[0]);

            const users1Registered = requestsOfMonth.find(
                ({ _id }) => _id.year === year && _id.month === month && _id.day === day
            );

            if (!users1Registered) {
                xAxisDataRequest1.push(0);
            } else {
                xAxisDataRequest1.push(users1Registered.count);
            }
        }

        for (let i = 0; i < xAxisLegend2.length; i++) {
            const year = parseInt(xAxisLegend2[i].split('/')[2]);
            const month = parseInt(xAxisLegend2[i].split('/')[1]);
            const day = parseInt(xAxisLegend2[i].split('/')[0]);

            const users2Registered = requestsOfLastMonth.find(
                ({ _id }) => _id.year === year && _id.month === month && _id.day === day
            );

            if (!users2Registered) {
                xAxisDataRequest2.push(0);
            } else {
                xAxisDataRequest2.push(users2Registered.count);
            }
        }
       
        requestsOptions.xAxis[0].data = xAxisLegend1;
        requestsOptions.series[0].data = xAxisDataRequest1;
        requestsOptions.series[1].data = xAxisDataRequest2;
        requestsOptions.title.text = "Requêtes";
        requestsOptions.legend.data = [month, lastMonth]; 
        requestsOptions.series[0].name = month;
        requestsOptions.series[1].name = lastMonth;
        requestsOptions.series[0].itemStyle.normal.color = '#02ce26'

        return JSON.parse(JSON.stringify(requestsOptions));
    }

    return (
        <div className='weekly-counter'>
            <Panel>
                <Panel.Heading>
                    <Panel.Title >Compteur entre &nbsp;
                            <input  
                                max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                min="2019-01"
                                name="month" 
                                onChange={handleMonthChange} 
                                type="month"
                                value={month}
                            />
                        <span>{' '} et {'   '}</span>
                            <input  
                                max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                min="2019-01"
                                name="lastMonth" 
                                onChange={handleLastMonthChange} 
                                type="month"
                                value={lastMonth}
                            />
                    </Panel.Title>
                </Panel.Heading>

                <Panel.Body>
                    <ReactEcharts
                        option={getUsersOptions()}
                        style={{ height: '250px', width: '100%' }}
                    />
                    <ReactEcharts
                        option={getRequestsOptions()}
                        style={{ height: '250px', width: '100%' }}
                    />
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default WeeklyCounter;
