import React from 'react';

import '../../Assets/Layout/Footer.css';

export default class Footer extends React.Component {
    render() {
        return (
            <div className="Footer">
                <p>{new Date().getFullYear()} © Feeli.</p>
            </div>
        );
    }
}
