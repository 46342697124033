import React from 'react';
import { Row, Col, Panel, Modal, Button } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import isoDate from '../../Services/toIsoDate.js';

import IconTooltip from '../Ui/IconTooltip';
import debounce from '../../Services/debounce.js';

import FeedbackCounter from '../Ui/FeedbackCounter';
import { feedbackQuestion } from '../../Constants.jsx';
import ReactEcharts from 'echarts-for-react';

export default class Feedback extends React.Component {
    constructor() {
        super();

        this.state = {
            feedback: [],
            totalPage: 0,
            repData: [],
            typeList: [],
            input: '',
            page: 1,
            month: null,
            type: null,
            countFeedback: 0,
            loading: false,
            doctorList: [],
            filteredRep: [],
            doctorSearch: '',
            doctorValue: null,
            isExporting: false,
            selectedState: [],
            startDate: new Date().toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            showFilterModal: false
        };


        this.getRepData = this.getRepData.bind(this);
        this.getFeedback = this.getFeedback.bind(this);
        this.filterModal = this.filterModal.bind(this);

        this.debounceGetFeedback = debounce(this.getFeedback, 250);
    }

    getFeedback() {
        this.setState({ loading: true });
        let startDate, endDate;

        if (this.state.month) {
            startDate = isoDate(`${this.state.month.split('-')[1]}/01/${this.state.month.split('-')[0]}`, 0, 0);
            endDate = Number(this.state.month.split('-')[1]) < 12 ? isoDate(`${Number(this.state.month.split('-')[1]) + 1}/01/${this.state.month.split('-')[0]}`, 0, 0) : isoDate(`01/01/${Number(this.state.month.split('-')[0]) +1 }`, 23, 59);
        }

        const searchInput = this.state.input.length === 0 ? '' : this.state.input[0] === '0' || this.state.input[0] === '+' ? this.state.input.substring(1, this.state.input.length) : this.state.input;
   
        fetchApi(`/feedback?page=${this.state.page}${this.state.input.trim() ? `&search=${searchInput}` : ''}&start=${this.state.month ? startDate : ''}&end=${this.state.month? endDate : ''}${(this.state.type) ? `&type=${this.state.type.value}` : ''}${(this.state.doctorValue) ? `&doctor=${this.state.doctorValue.value}` : ''}`)
            .then((res) =>
                    this.setState({ feedback: res.feedback, totalPage: res.countPages, loading: false, countFeedback: res.countFeedback })
            )
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees : ' + err,
                    'warning'
                )
            );
    }

    getRepData() {
        let startDate, endDate;

        if (this.state.month) {
            startDate = isoDate(`${this.state.month.split('-')[1]}/01/${this.state.month.split('-')[0]}`, 0, 0);
            endDate = Number(this.state.month.split('-')[1]) < 12 ? isoDate(`${Number(this.state.month.split('-')[1]) + 1}/01/${this.state.month.split('-')[0]}`, 0, 0) : isoDate(`01/01/${Number(this.state.month.split('-')[0]) +1 }`, 23, 59);
        }

        fetchApi(`/feedback/rep?start=${this.state.month ? startDate : ''}&end=${this.state.month? endDate : ''}`)
            .then((res) => {
                const doctorSet = new Set();
                const typeSet = new Set()

                const data = res.repData.map(item => {
                    if (item.requestId.doctorId) {
                        if (![...doctorSet].some(doc => doc._id === item.requestId.doctorId._id)) {
                            doctorSet.add(item.requestId.doctorId);
                        }
                    }

                    if (![...typeSet].some(type => type === item.type)) {
                        typeSet.add(item.type);
                    }

                    return item
                });                

                const doctorList = Array.from(doctorSet);

                this.setState({ repData: data, doctorList, typeList: Array.from(typeSet) });


            })
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees : ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getFeedback();
        this.getRepData()
    }

    updatePage(page) {
        this.setState({ users: [], page }, () => {
            this.getFeedback()
        });
    }

    handleSelectDoctor = (doctor, a) => {
        if (a.action === 'clear') {
            this.clearDocSearch()
            return
        };
        
        const arr = this.state.repData.filter(item => {
            return item.requestId.doctorId && item.requestId.doctorId._id === doctor.value
        });

        this.setState({ filteredRep: arr, 
            doctorValue: doctor, type: null}, () => {
                this.getFeedback()
            })



    };

    handleSelectType = (type, e) => {
        if (e.action === 'clear') {
            this.clearDocSearch()
            return;
        }

        const arr = this.state.repData.filter(item => {
            return item.type === type.value
        });

        this.setState({ filteredRep: arr })
    };


    filterModal() {

        return (
            <Modal show={this.state.showFilterModal}>
                <Modal.Header>
                    <Modal.Title>Filtre</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        De : <input 
                                type="date" 
                                value={this.state.startDate} 
                                onChange={(e) => this.setState({ startDate: e.target.value })} 
                                max={this.state.endDate || new Date().toISOString().split('T')[0]}
                            />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                       au : <input 
                                type="date" 
                                value={this.state.endDate} 
                                onChange={(e) => this.setState({ endDate: e.target.value })} 
                                min={this.state.startDate}
                            />
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showFilterModal: false, selectedState: [] })}>Fermer</Button>
                    <Button disabled={this.state.isExporting} onClick={() => this.exportToExcel()} bsStyle="primary">{this.state.isExporting ? 'Chargement ...' : 'Valider'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    getChartData(arr, key) {
        const counts = {};

        let total = 0        

        arr.forEach(item => {
            const values = Array.isArray(item.data[key]) ? item.data[key] : [item.data[key]];

            values.forEach(value => {
                if (counts[value]) {
                    counts[value]++;
                } else {
                    counts[value] = 1;
                }

                total++
            });
        });

        const result = Object.keys(counts).map(value => {
            const percentage = ((counts[value] / total) * 100).toFixed(2)
            return {
                name: `${value} \n${percentage}%`,
                value: counts[value]
            }
        });

        return result;
    }

    getRepOptions(question) {
        const options = {
            title: {
              text: '',
              left: 'left'
            },
            tooltip: {
              trigger: 'item'
            },
            series: [
              {
                name: '',
                type: 'pie',
                radius: '50%',
                data: [],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          

        options.series[0].data = this.getChartData((this.state.filteredRep.length ? this.state.filteredRep : this.state.repData), question);
        return JSON.parse(JSON.stringify(options));
    }

    clearDocSearch = () => {
        this.setState({ filteredRep: [], doctorValue: null, type: null }, () => {
            this.getFeedback()
        })
    }

    render() {
        if (!this.state.feedback) {
            return <div>Récupération des données en cours...</div>;
        }
        const page = this.state.page;
        

        return (
            <div className="pT-25">
                {this.filterModal()}
                <Row>
                    <Col md={12}>
                        <FeedbackCounter
                            month={this.state.month}
                            setMonth={(val) => this.setState({ month: val, page: 1 }, () => {
                                this.getFeedback();
                                this.getRepData()
                            })}
                            type={this.state.type}
                            setType={(val, e) => this.setState({ type: val, page: 1, doctorValue: null }, () => {
                                this.getFeedback();
                                this.handleSelectType(val, e)
                            })}
                            loading={this.state.loading}
                            doctorValue={this.state.doctorValue}
                            count={this.state.countFeedback}
                           
                            typeList={this.state.typeList.map((v) => ({ value: v, label: v }))}
                            clearDocSearch={this.clearDocSearch}
                            doctorList={this.state.doctorList.map((v) => ({ value: v._id, label: `${v.FName} ${v.LName}` }))}
                            handleSelectDoctor={this.handleSelectDoctor}
                        />

                        <Panel>
                            <Panel.Heading>
                                <h6>Partage d'experience</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>
                                    
                                    
                                    <div>
                                        <input
                                            type="text"
                                            className={'input-search'}
                                            placeholder="Taper le nom, le prénom, le mail"
                                            value={this.state.input}
                                            onChange={(e) => {
                                                this.setState({ input: e.target.value, page: 1 }, () => { 
                                                    this.debounceGetFeedback();
                                                });
                                            }}
                                        />
                                    </div>

                                    <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>

                                        <p>
                                        Liste des partages (Page <strong>{page}</strong> /{' '}
                                            {this.state.totalPage})
                                        </p>

                                        <div>
                                            {page > 1 && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-left"
                                                    text="Page précédente"
                                                    onClick={() => this.updatePage(page - 1)}
                                                />
                                            )}
                                            {page < this.state.totalPage && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-right"
                                                    text="Page suivante"
                                                    onClick={() => this.updatePage(page + 1)}
                                                />
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    
                                                >
                          Utilisateur
                                                    
                                                </th>
                                                <th
                                                    
                                                >
                          Email
                                                   
                                                </th>
                                                <th>Docteur</th>
                                                <th>Type</th>

                                                <th
                                                    
                                                >
                          Date de partage
                                                   
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(this.state.feedback).map((d) => {
                                                const req = d.requestId;

                                                return (
                                                    <tr key={d._id}>
                                                        <td>
                                                            {d.userId.FName} {d.userId.LName}
                                                        </td>
                                                        <td>{d.userId.email}</td>
                                                        <td>{req.doctorId.FName} {req.doctorId.LName}</td>
                                                        <td>{d.type}</td>
                                                        <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                        <td>
                                                            <IconTooltip
                                                                icon="fas fa-file-medical"
                                                                text="Voir les details"
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        '/feedback/detail/' + d._id
                                                                    )
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <h2>Repartition</h2>
                                
                                <div>
                                        <Row>
                                            <Col lg={5} md={5} sm={12} xs={12}>
                                                <p>{feedbackQuestion['q1']}</p>
                                                <ReactEcharts
                                                    option={this.getRepOptions('q1')}
                                                    style={{ height: '350px', width: '100%' }}
                                                />
                                            </Col>

                                            <Col lg={7} md={7} sm={12} xs={12}>
                                            <p>{feedbackQuestion['q2']}</p>
                                                <ReactEcharts
                                                    option={this.getRepOptions('q2')}
                                                    style={{ height: '350px', width: '100%' }}
                                                />              
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col lg={5} md={5} sm={12} xs={12}>
                                            <p>{feedbackQuestion['q3']}</p>
                                                <ReactEcharts
                                                    option={this.getRepOptions('q3')}
                                                    style={{ height: '350px', width: '100%' }}
                                                />
                                            </Col>

                                            <Col lg={7} md={7} sm={12} xs={12}>
                                            <p>{feedbackQuestion['q4']}</p>
                                                <ReactEcharts
                                                    option={this.getRepOptions('q4')}
                                                    style={{ height: '350px', width: '100%' }}
                                                />              
                                            </Col>
                                        </Row>
                                    </div>

                                    <div>
                                        <Row>
                                            <Col lg={5} md={5} sm={12} xs={12}>
                                            <p>{feedbackQuestion['q5']}</p>
                                                <ReactEcharts
                                                    option={this.getRepOptions('q5')}
                                                    style={{ height: '350px', width: '100%' }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
