import React, { useState } from 'react';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { Grid, Row, Col, Panel, Tabs, Tab } from 'react-bootstrap';
import ProfilePanel from '../Ui/ProfilePanel';
import Swal from 'sweetalert2';
import dateFormat from 'dateformat';
import RequestsTable from '../Ui/RequestsTable';
import IconTooltip from '../Ui/IconTooltip';

const DoctorData = props => {

    const [edit, setEdit] = useState({ rpps: false, password: false, amNumber: false, email: false, address: false, phone: false });

    const handleEdit = (name) => {
        setEdit({ ...edit, [name]: !edit[name] });
    }

    return (
        <div className="DoctorData">
        <table className="table">
            <tbody>
                <tr>
                    <td
                        colSpan="2"
                        style={{
                            textAlign: 'center',
                            textDecoration: 'underline'
                        }}
                    >
                        <strong>Informations Générale</strong>
                    </td>
                </tr>
                <tr>
                    <td>Prenom</td>
                    <td>{props.FName}</td>
                </tr>
                <tr>
                    <td>Nom</td>
                    <td>{props.LName}</td>
                </tr>
                <tr>
                    <td>Genre</td>
                    <td>
                        {props.gender ? (
                            props.gender
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Date de naissance</td>
                    <td>
                        {props.birth_date ? (
                            props.birth_date
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{edit.email ? <input style={{ width: '60%' }} type="text" name="email" onChange={(e) => props.handleInputChange(e)} value={props.emailVal} /> : props.email } {edit.email && <i onClick={() => { props.handleValidEdit(); setEdit({ ...edit, email: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('email')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.email ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td>Numéro de téléphone</td>
                    <td>{edit.phone ? <input style={{ width: '60%' }} type="text" name="phone" onChange={(e) => props.handleInputChange(e)} value={props.phoneVal} /> : props.phone } {edit.phone && <i onClick={() => { props.handleValidEdit(); setEdit({ ...edit, phone: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('phone')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.phone ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td>Adresse Postale</td>
                    <td>{edit.address ? <input style={{ width: '60%' }} type="text" name="address" onChange={(e) => props.handleInputChange(e)} value={props.addressVal} /> : props.address } {edit.address && <i onClick={() => { props.handleValidEdit(); setEdit({ ...edit, address: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('address')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.address ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td
                        colSpan="2"
                        style={{
                            textAlign: 'center',
                            textDecoration: 'underline'
                        }}
                    >
                        <strong>Informations Docteur</strong>
                    </td>
                </tr>
                <tr>
                    <td>Spécialité Principale</td>
                    <td>
                        {props.favoriteSpecialty ? (
                            props.favoriteSpecialty
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Spécialités</td>
                    <td>
                        <ul>
                            {props.specialties.map((element, index) => {
                                return <li key={index}>{element}</li>;
                            })}
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td>Numéro RPPS</td>
                    <td>{edit.rpps ? <input style={{ width: '60%' }} type="text" name="rpps" onChange={(e) => props.handleInputChange(e)} value={props.rppsVal} /> : props.rpps } {edit.rpps && <i onClick={() => { props.handleValidEdit(); setEdit({ ...edit, rpps: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('rpps')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.rpps ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td>Numéro AM</td>
                    <td>{edit.amNumber ? <input style={{ width: '60%' }} type="text" name="amNumber" onChange={(e) => props.handleInputChange(e)} value={props.amVal} /> : props.amNumber } {edit.amNumber && <i onClick={() => { props.handleValidEdit(); setEdit({ ...edit, amNumber: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('amNumber')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.amNumber ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td>Derniere connexion</td>
                    <td>{dateFormat(props.lastLoginDate, 'dd/mm/yy HH:MM')}</td>
                </tr>
                <tr>
                    <td>Date de creation du compte</td>
                    <td>{dateFormat(props.createdAt, 'dd/mm/yy HH:MM')}</td>
                </tr>
                <tr>
                    <td>Dernier prise en charge d'un demande</td>
                    <td>{props.request.length > 0 ? dateFormat(new Date(Math.max(...props.request.map(e => new Date(e.createdAt)))), 'dd/mm/yy HH:MM') : 'Non défini'}</td>
                </tr>
                <tr>
                    <td>
                        <button onClick={() => setEdit({ password: true })} style={{ border: 'none' }}>
                            Modifier le mot de passe
                        </button>
                    </td>
                    <td>{edit.password && <input style={{ width: '60%' }} type="text" name="password" onChange={(e) => props.handleInputChange(e)} value={props.passVal} /> } {edit.password && <i onClick={() => { props.handleUpdatePassword(); setEdit({ ...edit, password: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('password')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.password ? 'fa-close' : ''}`}></i> </td>
                </tr>
            </tbody>
        </table>
    </div>
    )
}

const doctorActive = (
    <div className="doctorActive">
        <i className="fa fa-check" />
    </div>
);

const DoctorWaiting = props => (
    <button className={`DoctorWaiting ${props.status ? 'deactive' : ''}`} onClick={props.handleClick}>
        {props.status ? 'Desactiver' : 'Activer'}
    </button>
);

export default class DoctorProfile extends React.Component {
    constructor() {
        super();

        this.state = {
            doctor: null,
            file: [],
            loading: false,
            requests: [],
            page: 1,
            totalPages: 1,
            countRequests: {},
            reqPerSpec: {},
            rpps: '',
            password: '',
            amNumber: '',
            email: '',
            address: '',
            phone: ''
        };

        this.handleActivate = this.handleActivate.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSendFile = this.handleSendFile.bind(this);
        this.getDoctor = this.getDoctor.bind(this);
        this.setDoctorSign = this.setDoctorSign.bind(this);
        this.getRequests = this.getRequests.bind(this);
        this.handleValidEdit = this.handleValidEdit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    }

    getReqPerSpec() {
        fetchApi(`/doctors/request/perSpeciality/${this.state.doctor._id}`)
        .then((res) => this.setState({ reqPerSpec: res.requests }))
        .catch((err) => notif.addNotification(
            'Erreur',
            'Erreur de recuperation des requetes ' + err,
            'warning'
        ))
    }

    getCountRequest() {
        fetchApi(`/doctors/count-request/${this.state.doctor._id}`)
        .then((res) => this.setState({ countRequests: res.nbRequests }))
        .catch((err) => notif.addNotification(
            'Erreur',
            'Erreur de recuperation des requetes ' + err,
            'warning'
        ))
    }

    getRequests() {
        fetchApi(`/doctors/request/${this.state.doctor._id}?page=${this.state.page}`)
        .then((resp) => { this.setState({ requests: resp.requests, totalPages: resp.countPages }) })
        .catch((err) => notif.addNotification(
            'Erreur',
            'Erreur de recuperation des requetes ' + err,
            'warning'
        ))
    }

    getDoctor() {
        fetchApi(`/doctors/${this.props.match.params.id}`)
            .then(res => {
                this.setState({ doctor: res.doctor, rpps: res.doctor.rpps, amNumber: res.doctor.amNumber, email: res.doctor.email, address: res.doctor.address, phone: res.doctor.phone }, () => {
                    this.getRequests();
                    this.getCountRequest();
                    this.getReqPerSpec();
                });
            })
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    handleValidEdit() {
        fetchApi(`/doctors/${this.props.match.params.id}`, {
            body: JSON.stringify({ rpps: this.state.rpps, amNumber: this.state.amNumber, email: this.state.email, address: this.state.address, phone: this.state.phone }),
            method: 'PUT'
        })
            .then(() => {
                const item = this.state.doctor;
                item.rpps = this.state.rpps;
                item.amNumber = this.state.amNumber;
                item.email = this.state.email;
                item.address = this.state.address;
                item.phone = this.state.phone;
                this.setState({ doctor: item });
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            });
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value});
    }

    handleUpdatePassword() {
        fetchApi(`/doctors/${this.props.match.params.id}/password`, {
            body: JSON.stringify({ password: this.state.password }),
            method: 'PUT'
        })
            .then(() => {
                notif.addNotification(
                    'Succes',
                    'Mot de passe changé',
                    'success'
                )
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            });
    }


    setDoctorSign(sign) {
        fetchApi(`/doctors/${this.props.match.params.id}`, {
            method: 'PUT',
            body: JSON.stringify({ signature: sign })
        })
            .then(() => this.getDoctor())
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            );
    }

    updatePage(page) {
        this.setState({ page }, () => this.getRequests())
    } 

    componentDidMount() {
        this.getDoctor();
    }

    handleActivate() {
        if (!this.state.doctor) {
            return;
        }

        const doctor = this.state.doctor;

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment ${doctor.activation ? 'desactiver' : 'activer'} ${doctor.FName} ${
                doctor.LName
            }?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi('/doctors/activate', {
                    method: 'PUT',
                    body: JSON.stringify({
                        doctorId: doctor._id,
                        status: !doctor.activation
                    })
                }).then(() => {
                    doctor.activation = !doctor.activation;

                    this.setState({
                        doctors: doctor
                    });

                    swalWithBootstrapButtons(
                        `${doctor.activation ? 'Active' : 'Desactive'}!`,
                        `Docteur ${doctor.activation ? 'active' : 'desactive'}`,
                        'success'
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Operation annulee',
                    'error'
                );
            }
        });
    }

    handleFileChange(e) {
        this.setState({ file: e.target.files });
    }

    handleSendFile() {
        let file = this.state.file[0];

        const sendSign = this.setDoctorSign;

        this.setState({ loading: true });

        let reader = new FileReader();
    
        reader.readAsDataURL(file);

        reader.onloadend = function() {
            const img = document.createElement('img');
            img.setAttribute("src", reader.result);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = 100;
                canvas.height = 100;

                ctx.drawImage(img, 0, 0, 100, 100);

                const dataURI = canvas.toDataURL('image/png');

                sendSign(dataURI);
            }
        }

        this.setState({ loading: false });
    }

    renderNbPerPatho() {
        return (
            <>
                <thead>
                    <tr>
                        {Object.keys(this.state.reqPerSpec).map((v) => <th key={v} style={{ textAlign: 'center' }} colSpan={2}>{v}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Object.keys(this.state.reqPerSpec).map((v) => [
                            <td style={{ textAlign: 'center' }} key={`d-${v}`}>{this.state.reqPerSpec[v]} demandes</td>,
                            <td style={{ textAlign: 'center' }} key={`p-${v}`}>{(this.state.reqPerSpec[v] / this.state.countRequests.all * 100).toFixed(2)} %</td>,
                        ])}
                    </tr>
                </tbody>
            </>
        );
    }

    render() {
        if (!this.state.doctor) {
            return <div>Loading...</div>;
        }

        return (
            <div className="pT-25">
                <Row>
                    <Col lg={3} xs={12}>
                        <button
                            onClick={() => this.props.history.goBack()}
                            className="backBtn"
                        >
                            <i className="icon-arrow-left" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <ProfilePanel {...this.state.doctor}>
                            <Grid fluid={true}>
                                <Row>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {this.state.countRequests.all}
                                        </span>
                                        <span className="subtitle">
                                            consultations
                                        </span>
                                    </Col>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {
                                                this.state.countRequests.approved
                                            }
                                        </span>
                                        <span className="subtitle">
                                            approuvés
                                        </span>
                                    </Col>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {
                                             this.state.countRequests.reviewed 
                                            }
                                        </span>
                                        <span className="subtitle">
                                            soignés
                                        </span>
                                    </Col>
                                </Row> <Row>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {this.state.countRequests.cancelByDoctor}
                                        </span>
                                        <span className="subtitle">
                                            annulés
                                        </span>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="textCounter">
                                            {
                                                this.state.countRequests.notOnline
                                            }
                                        </span>
                                        <span className="subtitle">
                                            indisponible à distance
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                            <DoctorWaiting
                                                status={this.state.doctor.activation}
                                                handleClick={
                                                    this.handleActivate
                                                }
                                            />
                                    </Col>
                                </Row>
                            </Grid>
                        </ProfilePanel>
                    </Col>
                    <Col lg={9} xs={12}>
                        <Panel>
                            <Panel.Body style={{ paddingTop: 0 }}>
                                <Tabs
                                    defaultActiveKey={1}
                                    className="custom"
                                    id="tabs"
                                >
                                    <Tab eventKey={1} title="Profil">
                                        <Row>
                                            <Col md={12}>
                                                <DoctorData
                                                    {...this.state.doctor}
                                                    request={this.state.requests}
                                                    rppsVal={this.state.rpps}
                                                    amVal={this.state.amNumber}
                                                    emailVal={this.state.email}
                                                    addressVal={this.state.address}
                                                    passVal={this.state.password}
                                                    phoneVal={this.state.phone}
                                                    handleInputChange={this.handleInputChange}
                                                    handleValidEdit={this.handleValidEdit}
                                                    handleUpdatePassword={this.handleUpdatePassword}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={2} title="Requetes">
                                        <Row>
                                            <Col md={12}>
                                                <div className="table-responsive">
                                                    <table className="table table-hover mb-0 border-table">
                                                        {this.renderNbPerPatho()}
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div style={{ marginLeft: '4%' }}>
                                                <p>Page {this.state.page} / {this.state.totalPages}</p>
                                                {this.state.page > 1 && (
                                                    <IconTooltip
                                                        icon="fa fa-chevron-left"
                                                        text="Page précédente"
                                                        onClick={() => this.updatePage(this.state.page - 1)}
                                                    />
                                                )}
                                                {this.state.page < this.state.totalPages && (
                                                    <IconTooltip
                                                        icon="fa fa-chevron-right"
                                                        text="Page suivante"
                                                        onClick={() => this.updatePage(this.state.page + 1)}
                                                    />
                                                )}
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <RequestsTable
                                                    history={this.props.history}
                                                    requests={this.state.requests}
                                                    user={false}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={3} title="Signature">
                                        <Row>
                                            <Col md={12}>
                                                <div>
                                                    {this.state.doctor.signature ? (
                                                        <div>
                                                            <img width="100px" height="100px" src={this.state.doctor.signature} alt="dr-sign" />
                                                        </div>
                                                    ) : <p>Non défini</p>}
                                                    <div style={{ marginTop: 20 }}>
                                                        <p>Changer :</p>
                                                        <input 
                                                            type="file"
                                                            accept="image/*" 
                                                            onChange={this.handleFileChange}
                                                        />
                                                        <button 
                                                            disabled={this.state.file.length < 1 || this.state.loading}
                                                            style={{ marginTop: 10, ...(this.state.file.length > 0 && { color: 'black' }) }}
                                                            onClick={this.handleSendFile}
                                                        >
                                                            Envoyer
                                                        </button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
