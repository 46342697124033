import React, { useEffect } from 'react';
import { useState } from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { ButtonToolbar, MenuItem, Modal, Panel, SplitButton } from 'react-bootstrap';


import '../../Assets/Pages/conversionTunnel.css';

import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { CardCount } from '../Ui/Card';

const inputOptions = {
    requestState: [
        { value: null, label: 'Type' },
        { value: 'dermatologie', label: 'Dermatologie' },
        { value: 'generaliste', label: 'Generaliste' },
    ],
    sourceState: [
        { value: null, label: 'No source' },
        { value: 'google', label: 'Google' },
        { value: 'bing', label: 'Bing' },
        { value: 'feeli-blog', label: 'Blog Feeli' },
        { value: 'skycall', label: 'Skycall (tous)' },
        { value: 'gescall', label: 'Gescall' },
        { value: 'skycall-voice', label: 'Skycall Voice' },
        { value: 'pharmacy', label: 'Pharmacie/Camping' },
    ],
    device: [
        { value: null, label: 'Device category' },
        { value: 'desktop', label: 'Desktop' },
        { value: 'tablet', label: 'Tablet' },
        { value: 'mobile', label: 'Mobile' },
    ],
};

const questText = {
    generaliste: {
        1: "Quel est le motif de votre consultation aujourd'hui ?",
        2: "Quels sont les symptômes actuellement ?",
        3: "Depuis quand avez-vous ces symptômes ?",
        4: "Comment cela a-t-il commencé ?",
        5: "Avez-vous de la fièvre ?",
        6: "Un test covid ou grippal a-t-il été réalisé ?",
        7: "Avez-vous déjà consulté ou pris un traitement pour ce même problème ?",
        8: "Avez-vous des allergies connues ou des intolérances aux médicaments ?",
        9: "Prenez-vous un traitement quotidien ou de longue durée ?",
        10: "Avez-vous des antécédents médicaux ou chirurgicaux notables ?"
    },
    dermatologie: {
        1: "Est-ce que la consultation concerne un enfant de moins de 16 ans ?",
        2: "Quel est votre principal symptôme cutané ?",
        3: "Depuis quand avez-vous ce symptôme (heures, jours, mois) ?",
        4: "Quelle est la localisation de toutes vos lésions cutanées ?",
        5: "Avez-vous déjà consulté un spécialiste ou un généraliste pour ce symptôme ?",
        6: "Avez-vous des antécédents pathologiques à signaler (dermatologiques, médico-chirurgicaux, médico-chirurgicaux, allergiques) ?",
        7: "Prenez-vous des traitements au long cours pour d’autres pathologies chroniques ?",
        8: "Prenez-vous une contraception? Si oui, laquelle? Depuis quand ?",
        9: "Avez-vous un médecin traitant ?"
    }
}


const ConversionTunnel = () => {
    const oneMonthBefore = new Date();

    oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

    const [withTypeData, setWithTypeData] =  useState(null);
    const [pageView, setPageView] = useState(0);
    const [dateValue, setDateValue] = useState([
        oneMonthBefore.toISOString().split('T')[0],
        new Date().toISOString().split('T')[0],
    ])
    const [selectValue, setSelectValue] = useState({
        device: inputOptions.device[0],
        requestState: inputOptions.requestState[0],
        sourceState: inputOptions.sourceState[0]
    })
    const [loading, setLoading] = useState(false);

    const getHomePageCount = () => {
        fetchApi(`/analyticData/getHomePageView`, {
            method: 'POST',
            body: JSON.stringify({
                dateValue,
                selectValue
            })
        })
        .then((res) => {
            setPageView(res.data.pageview);
            setLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Une erreur est survenue !',
                'warning'
            )
            setLoading(false);
        });
    }

    const getPerTypeCount = () => {
        fetchApi(`/analyticData/getPerTypeCount`, {
            method: 'POST',
            body: JSON.stringify({
                dateValue,
                selectValue
            })
        })
        .then((res) => {
            setWithTypeData(res.data);
            setLoading(false);
        })
        .catch(() => {
            notif.addNotification(
                'Erreur',
                'Une erreur est survenue !',
                'warning'
            )
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        getHomePageCount();
        if (selectValue.requestState.value) {
            getPerTypeCount()
        }
    }, [selectValue, dateValue])


    const handleSelect = (e, name) => {
        setSelectValue({ ...selectValue, [name]: e });
    }

    const renderSplitButton = () => {
        return Object.keys(inputOptions).map((v) => (
            <ButtonToolbar key={v} width='200px'>
                <SplitButton
                    id="option-list"
                    rootCloseEvent="mousedown"
                    title={selectValue[v].label}
                >   
                    {
                        inputOptions[v].map((k) => (
                            <MenuItem
                                eventKey={k}
                                active={k.value === selectValue[v].value}
                                onSelect={k.value === selectValue[v].value ? undefined : (e) => handleSelect(e, v)}
                                key={k.label}
                            >
                                {k.label}
                            </MenuItem>
                        ))
                    }
                </SplitButton>
            </ButtonToolbar>
        ))
    }

    const renderQuestText = (name, path) => {
            const q = name.split(" ");
            if (path.includes('dermatologie')) {
                return (
                    <p style={{ margin: 0 }}>{questText.dermatologie[q[q.length - 1]]}</p>
                )
            }
            if (path.includes('generaliste')) {
                return  (
                     <p  style={{ margin: 0 }}>{questText.generaliste[q[q.length - 1]]}</p>
                )
            }
    }

    const renderQuestImg = (name, path) => {
        if (name.includes('Etape 2')) {
            return (
                <div style={{ width: 430 }}>
                    <a href={`https://admin.feeli.io/assets/images/e2.png`} target='_blank' rel="noreferrer">
                        <img height={100} src="/assets/images/e2.png" alt="step2img" />
                    </a>
                </div>
            )
        }

        if (name.includes('Etape 3')) {
            let src;

            if (path.includes('dermatologie')) {
                src = "/assets/images/d-e3.png";
            } 
            else if (path.includes('generaliste')) {
                src = "/assets/images/g-e3.png";
            }

            return (
                <div style={{ width: 430 }}>
                    <a href={`https://admin.feeli.io${src}`} target='_blank' rel="noreferrer">
                        <img height={100}  src={src} alt="step3img" />
                    </a>
                </div>
            )
        }
        return null;
    }
    

    const renderCardData = () => {
        const path = withTypeData && withTypeData.index.path;
        return withTypeData && (
            <div className='card-block'>
                <CardCount
                    number={withTypeData.index.count}
                    subtitle={`Page ${path}`}
                    icon="fa fa-eye"
                    background={'rgb(33, 134, 51)'}
                />


                {withTypeData.quest.map((v) => (
                    <div key={v.name}>
                        <div className='type-block'>
                            <div style={{ textAlign: 'center' }}>
                                <i style={{ fontSize: '40px', color: '#00ffeb', marginBottom: 10 }} className="zmdi zmdi-long-arrow-down"></i>
                                <CardCount
                                    number={v.value}
                                    subtitle={v.name}
                                    icon="fa fa-eye"
                                    background={v.name == 'Etape 1 - Question 11' ? '#00cdac' : 'rgb(33, 134, 51)'}
                                />
                            </div>

                            {(v.name.includes("Etape 1")) && (
                                <div style={{ textAlign: 'center', padding: 10, border: '1px solid red', fontSize: 20, width: 430 }}>
                                    {renderQuestText(v.name, path)}
                                </div>
                            )}
                            {renderQuestImg(v.name, path)}
                            <div>

                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <Panel>
            <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modal-spinner"
            >
                <span className="loader" />
                {loading && <p>Récupération des données en cours...</p>}
            </Modal>
            <Panel.Heading>
                <h6>Tunnel de conversion</h6>
            </Panel.Heading>
            <Panel.Body>
                <div className='conversion-tunnel'>
                    <div className='block-1'>
                        <div>
                         <DateRangePicker 
                            locale="fr-FR" 
                            onChange={value => {
                                setDateValue(value)
                            }} 
                            value={dateValue} 
                            maxDate={new Date()}
                            clearIcon={null}
                        />
                        </div>
                        <div className='select-block'>
                            {renderSplitButton()}
                        </div>

                    </div>

                    <div className='block-2'>
                        <CardCount
                            number={pageView}
                            subtitle="Page d'acceuil"
                            icon="fa fa-eye"
                            background={'#00cdac'}
                            width="300px"
                        />
                    </div>
                </div>
                {renderCardData()}
            </Panel.Body>
        </Panel>
    )
}

export default ConversionTunnel;