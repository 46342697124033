import React, { useState } from 'react';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { Grid, Row, Col, Panel, Tabs, Tab } from 'react-bootstrap';
import ProfilePanel from '../Ui/ProfilePanel';
import dateFormat from 'dateformat';
import RequestsTable from '../Ui/RequestsTable';

const UserData = props => {
    const [edit, setEdit] = useState({ fname: false, lname: false, email: false, password: false });

    const handleEdit = (name) => {
        setEdit({ ...edit, [name]: !edit[name] });
    }

    return (
        <div className="UserData table-responsive">
        <table className="table">
            <tbody>
                <tr>
                    <td>Prenom</td>
                    <td>{edit.fname ? <input style={{ width: '60%' }} type="text" name="fname" onChange={(e) => props.handleNameChange(e)} value={props.fnameVal} /> : props.FName } {edit.fname && <i onClick={() => { props.handleValidName(); setEdit({ ...edit, fname: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('fname')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.fname ? 'fa-close' : 'fa-pen'}`}></i> </td>
                </tr>
                <tr>
                    <td>Nom</td>
                    <td>{edit.lname ? <input style={{ width: '60%' }} type="text" name="lname" onChange={(e) => props.handleNameChange(e)} value={props.lnameVal} /> : props.LName }  {edit.lname && <i onClick={() => { props.handleValidName(); setEdit({ ...edit, lname: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('lname')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.lname ? 'fa-close' : 'fa-pen'}`}></i></td>
                </tr>
                <tr>
                    <td>Genre</td>
                    <td>
                        {props.gender ? (
                            props.gender
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Date de naissance</td>
                    <td>
                        {props.birth_date ? (
                            new Date(props.birth_date).getDate() +
                            '/' +
                            (new Date(props.birth_date).getMonth() + 1) +
                            '/' +
                            new Date(props.birth_date).getFullYear()
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>{edit.email ? <input style={{ width: '60%' }} type="text" name="email" onChange={(e) => props.handleNameChange(e)} value={props.emailVal} /> : props.email }  {edit.email && <i onClick={() => { props.handleValidName(); setEdit({ ...edit, email: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('email')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.email ? 'fa-close' : 'fa-pen'}`}></i></td>
                </tr>
                <tr>
                    <td>Téléphone</td>
                    <td>{props.phone}</td>
                </tr>
                <tr>
                    <td>Carte Vitale</td>
                    <td>
                        {props.vitalCard ? (
                            props.vitalCard
                        ) : (
                            <strong>Non défini</strong>
                        )}
                    </td>
                </tr>
                <tr>
                    <td>Derniere connexion</td>
                    <td>{dateFormat(props.lastLoginDate, 'dd/mm/yy HH:MM')}</td>
                </tr>
                <tr>
                    <td>Date de creation du compte</td>
                    <td>{dateFormat(props.createdAt, 'dd/mm/yy HH:MM')}</td>
                </tr>
                <tr>
                    <td>
                        <button onClick={() => setEdit({ password: true })} style={{ border: 'none' }}>
                            Modifier le mot de passe
                        </button>
                    </td>
                    <td>{edit.password && <input style={{ width: '60%' }} type="text" name="password" onChange={(e) => props.handleNameChange(e)} value={props.passVal} /> } {edit.password && <i onClick={() => { props.handleUpdatePassword(); setEdit({ ...edit, password: false }) }} style={{ position: 'absolute', right: '13%', cursor: 'pointer', color: 'green' }} className={`zmdi zmdi-check-circle`}></i>} <i onClick={() => handleEdit('password')} style={{ position: 'absolute', right: '10%', cursor: 'pointer' }} className={`fa ${edit.password ? 'fa-close' : ''}`}></i> </td>
                </tr>
            </tbody>
        </table>
    </div>
    )
};

export default class UserProfile extends React.Component {
    constructor() {
        super();

        this.state = {
            user: null,
            fname: '',
            lname: '',
            email: '',
            password: ''
        };

        this.handleLockButton = this.handleLockButton.bind(this);
        this.handleValidName = this.handleValidName.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleUpdatePassword = this.handleUpdatePassword.bind(this);
    }

    componentDidMount() {
        fetchApi(`/users/${this.props.match.params.id}`)
            .then(res => this.setState({ user: res.user, fname: res.user.FName, lname: res.user.LName, email: res.user.email }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    handleLockButton(lock) {
        fetchApi(`/users/lock/${this.props.match.params.id}`, {
            body: JSON.stringify({ locked: lock }),
            method: 'PUT'
        })
            .then(() => {
                const item = this.state.user;
                item.locked = lock;
                this.setState({ user: item });
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            });
    }

    handleNameChange(e) {
        this.setState({ [e.target.name]: e.target.value});
    }

    handleUpdatePassword() {
        fetchApi(`/users/password/${this.props.match.params.id}`, {
            body: JSON.stringify({ password: this.state.password }),
            method: 'PUT'
        })
            .then(() => {
                notif.addNotification(
                    'Succes',
                    'Mot de passe changé',
                    'success'
                )
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            });
    }

    renderNbPerPatho() {
        const reduced = this.state.user.request.reduce((a, b) => (a[b.type] = a[b.type] + 1 || 1, a), {});

        return (
            <>
                <thead>
                    <tr>
                        {Object.keys(reduced).map((v) => <th style={{ textAlign: 'center' }}>{v}</th>)}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {Object.keys(reduced).map((v) => <td style={{ textAlign: 'center' }}>{reduced[v]} demandes</td>)}
                    </tr>
                </tbody>
            </>
        );
    }

    handleValidName() {
        fetchApi(`/users/name/${this.props.match.params.id}`, {
            body: JSON.stringify({ fname: this.state.fname, lname: this.state.lname, email: this.state.email }),
            method: 'PUT'
        })
            .then(() => {
                const item = this.state.user;
                item.FName = this.state.fname;
                item.LName = this.state.lname;
                item.email = this.state.email
                this.setState({ user: item });
            })
            .catch(err => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de modification: ' + err,
                    'warning'
                )
            });
    }

    render() {
        if (!this.state.user) {
            return <div>Loading...</div>;
        }

        return (
            <div className="pT-25">
                <Row>
                    <Col lg={3} xs={12}>
                        <button
                            onClick={() => {
                                if (this.props.history.location.state?.page) {
                                    this.props.history.push({
                                        pathname: this.props.history.location.state.page,
                                        state: this.props.history.location.state
                                    })
                                } else {
                                    this.props.history.goBack()
                                }   
                            }}
                            className="backBtn"
                        >
                            <i className="icon-arrow-left" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <ProfilePanel {...this.state.user}>
                            <Grid fluid={true}>
                                <Row>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {this.state.user.request.length}
                                        </span>
                                        <span className="subtitle">
                                            requetes
                                        </span>
                                    </Col>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {
                                                this.state.user.request.filter(
                                                    ({ state }) =>
                                                        state === 'approved'
                                                ).length
                                            }
                                        </span>
                                        <span className="subtitle">
                                            attente
                                        </span>
                                    </Col>
                                    <Col xs={4}>
                                        <span className="textCounter">
                                            {
                                                this.state.user.request.filter(
                                                    ({ state }) =>
                                                        state === 'reviewed'
                                                ).length
                                            }
                                        </span>
                                        <span className="subtitle">
                                            terminées
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <hr />
                                    <Col xs={12}>
                                        {this.state.user.deleted ? (
                                            <button
                                                className='user-lock lock'
                                                style={{ cursor: 'unset' }}
                                            >
                                                Utilisateur supprimé
                                            </button>
                                        ) : (
                                            <button
                                                className={
                                                    this.state.user.locked
                                                        ? 'user-lock unlock'
                                                        : 'user-lock lock'
                                                }
                                                onClick={() => {
                                                    this.state.user.locked
                                                        ? this.handleLockButton(
                                                            false
                                                        )
                                                        : this.handleLockButton(
                                                            true
                                                        );
                                                }}
                                            >
                                                {this.state.user.locked
                                                    ? "Déverouiller l'utilisateur"
                                                    : "Verrouiller l'utilisateur"}
                                            </button>
                                        )}
                                    </Col>
                                </Row>
                            </Grid>
                        </ProfilePanel>
                    </Col>
                    <Col lg={9} xs={12}>
                        <Panel>
                            <Panel.Body style={{ paddingTop: 0 }}>
                                <Tabs
                                    defaultActiveKey={1}
                                    className="custom"
                                    id="tabs"
                                >
                                    <Tab eventKey={1} title="Profil">
                                        <Row>
                                            <Col md={12}>
                                                <UserData
                                                    {...this.state.user}
                                                    handleNameChange={this.handleNameChange}
                                                    fnameVal={this.state.fname}
                                                    lnameVal={this.state.lname}
                                                    emailVal={this.state.email}
                                                    passVal={this.state.password}
                                                    handleValidName={this.handleValidName}
                                                    handleUpdatePassword={this.handleUpdatePassword}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey={2} title="Requetes">
                                        <Row>
                                            <Col md={12}>
                                                <div className="table-responsive">
                                                    <table className="table table-hover mb-0">
                                                        {this.renderNbPerPatho()}
                                                    </table>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <RequestsTable
                                                    history={this.props.history}
                                                    requests={
                                                        this.state.user.request
                                                    }
                                                    user={true}
                                                />
                                            </Col>
                                        </Row>
                                    </Tab>
                                </Tabs>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
