import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';

export default class Testers extends React.Component {
    constructor() {
        super();

        this.state = {
            testers: [],
            sort: true
        };

        this.handleRemove = this.handleRemove.bind(this);
        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
    }

    componentDidMount() {
        fetchApi('/testers')
            .then(res => this.setState({ testers: res.testers }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    handleRemove(id) {
        const tester = this.state.testers.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment dire adieu a ${tester.FName} ${
                tester.LName
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi('/testers', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: tester._id
                    })
                }).then(() => {
                    this.setState({
                        testers: this.state.testers.filter(
                            ({ _id }) => _id !== id
                        )
                    });

                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Testeur supprime',
                        'success'
                    );
                });
            } else if (
                // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Testeur en securite :)',
                    'error'
                );
            }
        });
    }

    sortInt(sortKey, sortSecond) {
        const testers = this.state.testers;

        if (this.state.sort) {
            testers.sort(
                (a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]
            );
        } else {
            testers.sort(
                (a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]
            );
        }

        this.setState({ testers: testers, sort: !this.state.sort });
    }

    onSort(sortKey) {
        const data = this.state.testers;
        if (this.state.sort) {
            data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
            this.setState({ sort: true });
        }
        this.setState({ testers: data });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Testeurs</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>Liste des 200 derniers testeurs</p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('FName')
                                                    }
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('createdAt')
                                                    }
                                                >
                                                    Date d'inscription
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'lastLoginDate'
                                                        )
                                                    }
                                                >
                                                    Derniere connexion
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Active</th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.sortInt(
                                                            'test',
                                                            'length'
                                                        )
                                                    }
                                                >
                                                    Nb Tests
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.testers.map(d => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.FName} {d.LName}
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            d.createdAt,
                                                            'dd/mm/yy HH:MM'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            d.lastLoginDate,
                                                            'dd/mm/yy HH:MM'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {d.activation ? (
                                                            <i className="fa fa-check success" />
                                                        ) : (
                                                            <i className="fa fa-close danger" />
                                                        )}
                                                    </td>
                                                    <td>
                                                        -
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-user"
                                                            text="Voir le profil"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/testers/' +
                                                                        d._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() =>
                                                                this.handleRemove(
                                                                    d._id
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export class WaitingTesters extends React.Component {
    constructor() {
        super();

        this.state = {
            testersWaiting: [],
            sort: true
        };

        this.onSort = this.onSort.bind(this);
    }

    componentDidMount() {
        fetchApi('/testers?filter=waitingActivation')
            .then(res => this.setState({ testersWaiting: res.testers }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    onSort(event, sortKey) {
        const data = this.state.testersWaiting;
        if (this.state.sort) {
            data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
            this.setState({ sort: true });
        }
        this.setState({ testers: data });
    }

    handleRemove(id) {
        const doctor = this.state.testers.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment dire adieu a ${doctor.FName} ${
                doctor.LName
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi('/testers', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: doctor._id
                    })
                }).then(() => {
                    this.setState({
                        testers: this.state.testers.filter(
                            ({ _id }) => _id !== id
                        )
                    });

                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Testeur supprime',
                        'success'
                    );
                });
            } else if (
                // Read more about handling dismissals
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Testeur en securite :)',
                    'error'
                );
            }
        });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Testeurs non actives</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>
                                    Liste des testeurs en attente d'approbation
                                    par un administrateur.
                                </p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={e =>
                                                        this.onSort(e, 'FName')
                                                    }
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={e =>
                                                        this.onSort(
                                                            e,
                                                            'createdAt'
                                                        )
                                                    }
                                                >
                                                    Date d'inscription
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.testersWaiting.map(
                                                d => (
                                                    <tr key={d._id}>
                                                        <td>
                                                            {d.FName} {d.LName}
                                                        </td>
                                                        <td>
                                                            {dateFormat(
                                                                d.createdAt,
                                                                'dd/mm/yy HH:MM'
                                                            )}
                                                        </td>
                                                        <td>
                                                            <IconTooltip
                                                                icon="fa fa-user"
                                                                text="Voir le profil"
                                                                onClick={() =>
                                                                    this.props.history.push(
                                                                        '/testers/' +
                                                                            d._id
                                                                    )
                                                                }
                                                            />
                                                            <IconTooltip
                                                                icon="fa fa-close danger"
                                                                text="Supprimer"
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
