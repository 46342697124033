import React from 'react';
import { Row, Col, Panel, Modal, Button } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';
import '../../Assets/Ui/Requests.css';
import RequestCounter from '../Ui/RequestCounter';
import AuthService from '../../Services/AuthService';
import { exportAsExcel } from '../../Services/exportAsExcel';
import isoDate from '../../Services/toIsoDate.js';
import Select from 'react-select';
import debounce from '../../Services/debounce.js';
import { rqState } from '../../Constants.jsx';

const rqStateObj = {
    incomplete: 'Incomplete',
    approved: 'Approuvé',
    reviewed: 'Examiné',
    waiting: 'En Attente',
    terminated: 'Terminé',
    notOnline: 'Non à distance',
    toCall: 'A appeler',
    refunded: 'Rembousé',
    canceled: 'Annulé'
};

export default class Requests extends React.Component {
    constructor() {
        super();

        this.state = {
            requests: null,
            totalPage: 0,
            input: '',
            isLoading: true,
            page: 1,
            isExporting: false,
            type: [],
            source: {},
            startDate: '',
            endDate: '',
            selectedState: [],
            showFilterModal: false,
            pathology: [],
            showFilterModalGclid: false,
            conversionName: '', 
            priority: null,
            doctorList: [],
            loadingDoctorList: false,
            nbLimit: 15,
            selectedDoctor: null,
            openFilterDoctor: false
        };

        
        this.Auth = new AuthService();
        this.handleRemove = this.handleRemove.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.exportConversion = this.exportConversion.bind(this);
        this.getRequests =  this.getRequests.bind(this);
        this.filterModal = this.filterModal.bind(this);

        this.debounceSearch = debounce(this.getRequests, 500);
    }

    getRequests() {
        const sourceQuery = this.props.role === 4 ? `&utm_source=${this.state.source && this.state.source.value ? this.state.source.value : this.props.utm_source}` : this.state.source.value ? `&utm_source=${this.state.source.value}` : '';
        const start = this.props.role === 5 ? `&start=${isoDate("2021/01/01", 0, 0)}` : '';
        const end = this.props.role === 5 ? `&end=${isoDate("2023/10/31", 23, 59)}` : '';
        fetchApi(`/requests?page=${this.state.page}&limit=${this.state.nbLimit}${this.state.input ? `&input=${this.state.input}` : ''}${sourceQuery}${this.state.type.length > 0 ? `&state=${this.state.type.map((v) => v.value).toString()}` : ''}${this.state.pathology.length > 0 ? `&pathology=${this.state.pathology.map((v) => v.value).toString()}` : ''}${start}${end}`)
            .then((res) =>
                this.setState({
                    totalPage: res.countPages,
                    isLoading: false,
                    requests: res.requests.filter(({ userId }) => !!userId).map((r) => ({
                        ...r,
                        doctorId: r.doctorId || { FName: '', LName: '' }
                    }))
                })
            )
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getRequests();
    }

    updatePage(page) {
        this.setState({ page, isLoading: true }, () => {
            this.getRequests();
        });
    }

    handleRemove(id) {
        const request = this.state.requests.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment supprimer cette request ${
                request.type
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi(`/requests/${request._id}`, {
                    method: 'DELETE'
                }).then(() => {
                    this.setState({
                        requests: this.state.requests.filter(({ _id }) => _id !== id)
                    });

                    swalWithBootstrapButtons('Supprime!', 'Requete supprime', 'success');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons('Annule', 'Operation annulee', 'error');
            }
        });
    }

    onSort(sortKey, optionKey) {
        const data = this.state.requests;
        if (optionKey) {
            if (this.state.sort) {
                data.sort((a, b) =>
                    a[sortKey][optionKey].localeCompare(b[sortKey][optionKey])
                );
                this.setState({ sort: false });
            } else {
                data.sort((a, b) =>
                    b[sortKey][optionKey].localeCompare(a[sortKey][optionKey])
                );
                this.setState({ sort: true });
            }
        } else {
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
        }

        this.setState({ requests: data });
    }

    exportToExcel() {

        const calculateTime = (attachmentsArr, prescriptionArr, acceptedTime) => {
            let fistPrescription = attachmentsArr && attachmentsArr.find(el => el.name.includes("ordonnance") || el.name.includes("presc")) || prescriptionArr && prescriptionArr[0] ;
            let sendPrescriptionTime = null;
            if (fistPrescription && acceptedTime) {
                sendPrescriptionTime =
                    new Date(fistPrescription.createdAt) - new Date(acceptedTime);
                if ( sendPrescriptionTime >= 0 ) {
                    let seconds = Math.floor(sendPrescriptionTime / 1000);
                    let minutes = Math.floor(seconds / 60);
                    let hours = Math.floor(minutes / 60);
                    let days = Math.floor(hours / 24);
        
                    sendPrescriptionTime = `${days} jours, ${hours % 24} heures, ${minutes %
                        60} minutes et ${seconds % 60} secondes`;
                } else {
                    sendPrescriptionTime = "erreur"
                }
            }
            return sendPrescriptionTime;
        }

        this.setState({ isExporting: true }, () => {

            const api = this.props.role === 5 ? `/requests/getRequest/dataExcel/fiscale` : `/requests/${this.props.role === 4 ? `partnerRequests/${this.props.utm_source}` : 'getRequest/dataExcel'}`

            fetchApi(api, {
                method: 'POST',
                body: JSON.stringify({
                    start: isoDate(this.state.startDate, 0, 1),
                    end: isoDate(this.state.endDate, 23, 59),
                    type: this.state.selectedState,                  
                })
            })
                .then((res) => {
                    const validState = ['reviewed', 'terminated', 'approved'];

                    let data = res.data;

                    if (this.state.selectedDoctor && this.state.selectedDoctor.value !== "all") {
                        data = res.data.filter(el => el.doctorId && `${el.doctorId.FName} ${el.doctorId.LName}` === this.state.selectedDoctor.value)
                    }

                    const toExport = this.props.role === 5 ? data.reverse().map((v) => ({
                        'ID': v._id,
                        'Date de création': dateFormat(v.createdAt, 'dd/mm/yy HH:MM'),
                        Type: v.type,
                        Statut: v.state,
                        Docteur: v && v.doctorId ? `${v.doctorId.FName} ${v.doctorId.LName}` : '',
                        "Nombre d'échange": v.messenger?.length,
                        Ordonnance: (v.attachments && v.attachments.length > 0 && v.attachments.filter(el => (el !== null && el.name?.includes('ordonnance')) || (el !== null && el.name?.includes('presc'))).length > 0) || v.prescription && v.prescription.length > 0 ? 'OUI' : 'NON',
                        "Source d'acquisition": v.utm_source || '',
                    }))
                    :
                     data.reverse().map((v) => ({
                        Utilisateur: v.userId ? `${v.userId.FName} ${v.userId.LName}` : '',
                        Email: v.userId ? v.userId.email : '',
                        'Date de creation': dateFormat(v.createdAt, 'dd/mm/yy HH:MM'),
                        Type: v.type,
                        Status: v.state,
                        Docteur: v.doctorId ? `${v.doctorId.FName} ${v.doctorId.LName}` : '',
                        "Nombre d'échange": v.messenger.length,
                        Ordonnance: (v.attachments && v.attachments.length > 0 && v.attachments.filter(el => (el !== null && el.name.includes('ordonnance')) || (el !== null && el.name.includes('presc'))).length > 0) || v.prescription && v.prescription.length > 0 ? 'OUI' : 'NON',
                        utm_content: v.utm_content,
                        utm_source: v.utm_source,
                        Priorité: v.priority ? 'OUI' : 'NON',
                        ...(this.props.role !== 4 && {
                            Récurrence: validState.indexOf(v.state) !== -1 && v.userId && v.userId.request.filter(el => el && validState.indexOf(el.state) > -1).findIndex(el => el._id === v._id) > 0 ? `Oui (${v.userId.request.filter(el => el && validState.indexOf(el.state) > -1).findIndex(el => el._id === v._id) + 1})` : 'Non',
                            "Temps d'envoie ordonnance": calculateTime(v.attachments, v.prescription, v.acceptedAt)
                         }),
                    }));

                    const cls = [
                        { wch: 20 },
                        { wch: 25 },
                        { wch: 15 },
                        { wch: 20 },
                        { wch: 15 },
                        { wch: 20 },
                        { wch: 20 },
                        { wch: 15 },
                        { wch: 15 },
                        { wch: 15 },
                        { wch: 20 },
                        { wch: 15 },
                        { wch: 30 }
                    ];

                    this.setState({ isExporting: false, showFilterModal: false, selectedState: [], selectedDoctor: null, doctorList: [] }, () => {
                        notif.addNotification(
                            'Succes !',
                            '',
                            'success'
                        );
                        exportAsExcel(toExport, `requests_${new Date().toISOString()}`, cls);
                    });

                })
                .catch((err) => {
                    this.setState({ isExporting: false }, () => {
                        notif.addNotification(
                            'Erreur',
                            'Error while getting the users: ' + err,
                            'warning'
                        );
                    });
                });
        });

    }

    exportConversion() {
        if (!this.state.conversionName) {
            return notif.addNotification(
                'Erreur',
                'Nom de conversion vide !',
                'warning'
            );
        }


        this.setState({ isExporting: true }, () => {
            fetchApi(`/requests/getRequest/dataConversion`, {
                method: 'POST',
                body: JSON.stringify({
                    start: isoDate(this.state.startDate, 0, 1),
                    end: isoDate(this.state.endDate, 23, 59),
                    ...(this.props.role === 4 && { utm_source: this.props.utm_source })
                })
            })
                .then((res) => {

                    const toExport = res.data.map((v) => ({
                        'Google Click ID': v.gclid,
                        'Conversion Name': this.state.conversionName,
                        'Conversion Time': v.createdAt.split('Z')[0],
                        'Conversion Value': (v.type === 'dermatologie' || v.type === 'acne' ) ? '45' : '30',
                        'Conversion Currency': 'EUR'
                    }));

                    const cls = [
                        { wch: 95 },
                        { wch: 35 },
                        { wch: 24 },
                        { wch: 20 },
                        { wch: 20 }
                    ];

                    this.setState({ isExporting: false, showFilterModal: false, showFilterModalGclid: false }, () => {
                        notif.addNotification(
                            'Succes !',
                            '',
                            'success'
                        );
                        exportAsExcel(toExport, `conversion_${new Date().toISOString()}`, cls);
                    });

                })
                .catch((err) => {
                    this.setState({ isExporting: false }, () => {
                        notif.addNotification(
                            'Erreur',
                            'Error while getting the users: ' + err,
                            'warning'
                        );
                    });
                });
        });
    }

    filterModal() {
        const getDoctorList = () => {
            if (this.state.openFilterDoctor) {
                this.setState({ openFilterDoctor : false})
            } else {
                fetchApi(`/requests/getDoctorList`, {
                    method: 'POST',
                    body: JSON.stringify({
                        start: isoDate(this.state.startDate, 0, 1),
                        end: isoDate(this.state.endDate, 23, 59),
                        state: this.state.selectedState
                    })
                })
                .then((res) => {
                    let doctorArr = [{value: "all", label: "Tous les docteurs"}];
                    res.data.filter(el => el.doctorId).map(el => {
                        if (doctorArr.findIndex(doctor => doctor.value === `${el.doctorId.FName} ${el.doctorId.LName}`) === -1) {
                            doctorArr.push({value: `${el.doctorId.FName} ${el.doctorId.LName}`, label: `${el.doctorId.FName} ${el.doctorId.LName}`})
                        }
                    })
                    this.setState({
                        doctorList : doctorArr,
                        openFilterDoctor: true
                    })
                }
                   
                )
                .catch((err) =>
                    notif.addNotification(
                        'Erreur',
                        'Erreur de recuperation des donnees request: ' + err,
                        'warning'
                    )
                );
            }
            

           
        }

        return (
            <Modal show={this.state.showFilterModal}>
                <Modal.Header>
                    <Modal.Title>Filtre</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        De : <input
                            type="date"
                            value={this.state.startDate}
                            onChange={(e) => this.setState({ startDate: e.target.value, selectedDoctor: null, doctorList: [], openFilterDoctor: false })}
                            max={this.props.role === 5 ? this.state.endDate || '2023-10-31' : this.state.endDate || new Date().toISOString().split('T')[0]}
                            min={this.props.role === 5 ? '2021-01-01' : ''}
                        />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                       au : <input
                            type="date"
                            value={this.state.endDate}
                            onChange={(e) => this.setState({ endDate: e.target.value, selectedDoctor: null, doctorList: [], openFilterDoctor: false })}
                            min={this.props.role === 5 ? this.state.startDate||'2021-01-01' : this.state.startDate}
                            max={this.props.role === 5 ? '2023-10-31' : ''}
                        />
                    </div>
                    <div className="select-input">
                        {this.state.showFilterModalGclid ? (
                            <input
                                className='input-search'
                                type="text"
                                placeholder="Nom du conversion"
                                onChange={(e) => this.setState({ conversionName: e.target.value })}
                            />
                        ) : (
                            <Select
                                value={this.state.selectedState}
                                onChange={(v) => this.setState({ selectedState: v })}
                                options={rqState}
                                isMulti
                                styles={{ option: (s) => ({
                                    ...s,
                                    color: 'black'
                                })}}
                                role="checkbox"
                                placeholder="Filtre par status ..."
                            />
                        )}
                    </div>

                    {
                        this.state.openFilterDoctor && 
                        <div style={{marginTop: "10px"}}>
                        <Select
                            value={this.state.selectedDoctor}
                            onChange={(v) => this.setState({ selectedDoctor: v })}
                            options={this.state.doctorList}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black',
                            })}}
                            role="checkbox"
                            placeholder="Selectionner a docteur ..."
                        />
                        </div>
                    }

                    {
                        !this.state.showFilterModalGclid && this.props.role !== 5 &&
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="openFilterDoctor" name="openFilterDoctor" onClick={getDoctorList} checked={this.state.openFilterDoctor} />
                            <label for="openFilterDoctor">Filtre par médecin</label>
                        </div>
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showFilterModalGclid: false, showFilterModal: false, selectedState: [], selectedDoctor: null, doctorList: [] })}>Fermer</Button>
                    <Button disabled={this.state.isExporting} onClick={this.state.showFilterModalGclid ? () => this.exportConversion() : () => this.exportToExcel()} bsStyle="primary">{this.state.isExporting ? 'Chargement ...' : 'Valider'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        const page = this.state.page;
        const requests = this.state.requests;


        return (
            <div className="pT-25">
                {this.filterModal()}
                <Row>

                    <Col md={12}>
                        <RequestCounter
                            isPartner={this.props.role === 4}
                            isAdminFiscale={this.props.role === 5}
                            utm_source={this.props.utm_source}
                            setValues={(val) => this.setState({ type: val.type ? val.type : this.state.type, source: val.source ? val.source : this.state.source, pathology: val.pathology ? val.pathology : this.state.pathology, page: 1   }, () => {
                                this.getRequests();
                            })}
                        />

                        <Panel>
                            <Panel.Heading>
                                <h6>Requetes</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>

                                    <button
                                        className="button-export"
                                        onClick={() => this.setState({ showFilterModal: true })}
                                        disabled={this.state.isExporting}
                                    >
                                            Exporter en excel
                                    </button>

                                    <br />

                                    {this.props.role !== 4 && this.props.role !== 5 && (
                                        <button
                                            className="button-export"
                                            onClick={() => this.setState({ showFilterModal: true, showFilterModalGclid: true })}
                                            disabled={this.state.isExporting}
                                        >
                                            Exporter les conversions
                                        </button>
                                    )}

                                    {this.props.role !== 5 && <div>
                                        <input
                                            type="text"
                                            className={'input-search'}
                                            placeholder="Nom ou email d'un client"
                                            value={this.state.input}
                                            onChange={(e) =>
                                                this.setState({ input: e.target.value }, () => {
                                                    this.debounceSearch();
                                                })
                                            }
                                        />
                                    </div>}


                                    <div style={{ display: 'flex', gap: 15, marginTop: 10 }}>

                                        <p>
                                        Liste des demandes (Page <strong>{page}</strong> /{' '}
                                            {this.state.totalPage})
                                        </p>

                                        <div>
                                            {page > 1 && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-left"
                                                    text="Page précédente"
                                                    onClick={() => this.updatePage(page - 1)}
                                                />
                                            )}
                                            {page < this.state.totalPage && (
                                                <IconTooltip
                                                    icon="fa fa-chevron-right"
                                                    text="Page suivante"
                                                    onClick={() => this.updatePage(page + 1)}
                                                />
                                            )}
                                        </div>

                                    </div>

                                    <div>
                                        Limite : <input 
                                            max={100} 
                                            style={{ width: '50px' }}
                                            type="number" 
                                            value={this.state.nbLimit} 
                                            onChange={(e) => {
                                                if (e.target.valueAsNumber <= 100) {
                                                    this.setState({ isLoading: true, nbLimit: e.target.value }, () => {
                                                        this.getRequests();
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                {this.props.role !== 5 && <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('userId', 'FName')}
                                                >
                          Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>}
                                                {this.props.role !== 5 &&<th
                                                    className="sortable"
                                                    onClick={() => this.onSort('userId', 'email')}
                                                >
                          Email
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>}
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('createdAt')}
                                                >
                          Date de creation
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('type')}
                                                >
                          Type
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('state')}
                                                >
                          Etat
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('doctorId', 'FName')}
                                                >
                          Docteur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                {this.props.role !== 5 && <th>Action</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.isLoading ? 'Récupération des données en cours...' :requests.map((r) => (
                                                <tr key={r._id}>
                                                    {this.props.role === 4  ?
                                                        <td>
                                                            {r.userId._id} {((r.utm_source === "skycall" && r.utm_content && r.utm_content.includes('voice')) || r.skycallVoice) && <img src="/assets/images/skycall-voice.png" className="logo-skycall-voice" alt="skycall-voice"/>} {r.priority && <span className='priority-badge'>Urgent</span>}
                                                        </td> :
                                                        this.props.role !== 5 &&
                                                        <td>
                                                            {r.userId.FName} {r.userId.LName} {((r.utm_source === "skycall" && r.utm_content && r.utm_content.includes('voice')) || r.skycallVoice) && <img src="/assets/images/skycall-voice.png" className="logo-skycall-voice" alt="skycall-voice"/>}
                                                             {r.priority && <span className='priority-badge'>Urgent</span>}
                                                        </td>
                                                    }
                                                   { this.props.role !== 5 && <td>{r.userId.email}</td>}
                                                    <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                    <td>{r.type}</td>
                                                    <td>{r.notOnlineReason ? 'Indisponible à distance' : r.state === "canceled" && r.canceledByDoctor === true ? "Annulé par médecin" : rqStateObj[r.state]}</td>
                                                    <td>
                                                        {r.doctorId
                                                            ? r.doctorId.FName + ' ' + r.doctorId.LName
                                                            : ''}
                                                    </td>
                                                    {
                                                        this.props.role !== 5 && 
                                                        <td>
                                                        <IconTooltip
                                                            icon="fas fa-file-medical"
                                                            text="Details"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/requests/detail/' + r._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() => this.handleRemove(r._id)}
                                                        />
                                                    </td>
                                                    }
                                                    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
