import React, { useState, useEffect } from 'react';
import { fetchApi } from '../../Network/NetworkService';

import '../../Assets/Ui/CohorteClients.css';
import { Modal } from 'react-bootstrap';
import Chart from '../Ui/Chart';
import { exportAsExcel } from '../../Services/exportAsExcel';

const typeStates = [
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie,dermatologue-en-ligne', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste,généraliste', label: 'generaliste' },
    { value: 'gynecologie,gynecologue', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'kit-voyage-et-vaccination', label: 'kit-voyage-et-vaccination' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'renouvellement-ordonnance', label: 'renouvellement-ordonnance' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

const fromDateToQuarter = (date) => {
    return `${new Date(date).getFullYear()}-${Math.floor(new Date(date).getMonth() / 3 + 1)}`
}

const getMonths = () => {
    let startMonth = 1;
    let startYear = 2020;
    let endMonth = new Date().getMonth() === 0 ? 11 : new Date().getMonth();
    let endYear = new Date().getMonth() === 0 ? new Date().getFullYear() -1 : new Date().getFullYear();

    let months = [];
  
    for(let year = startYear; year <= endYear; year++) {
        let month = year === startYear ? startMonth : 1;
        const monthLimit = year === endYear ? endMonth : 12;
        for(; month <= monthLimit; month++) {
            const input = month < 10 ? `${year}-0${month}` : `${year}-${month}`;
          months.push(input)
        }
      }
      return months;
}

const getQuarter = (isWithSource) => {
    let startQuarter = isWithSource ? 3 : 1;
    let startYear = isWithSource ? 2022 : 2020;
    let endQuarter = Math.floor(new Date().getMonth() / 3);
    let endYear = new Date().getFullYear();
    let result = [];
    for (let year = startYear; year <= endYear; year++) {
        let quarter = year === startYear ? startQuarter : 1;
        const quarterLimit = year === endYear ? endQuarter : 4;
        for(; quarter <= quarterLimit; quarter++) {
            result.push(`${year}-${quarter}`)
        }
    }
    return result;
}
const getDate = (input, h, m, end) => {
    let n;  
    if (input) {
        if (end) {
            n = new Date(Number(input.split('-')[0]), Number(input.split('-')[1]), 0);
        } else {
            n = new Date(`${input.split('-')[0]}/${input.split('-')[1]}/01`);
        }
    } else {
        n = new Date();
    }

    n.setHours(h, m, 0);
    return n.toISOString();
};
const getDateFromQuarter = (input, h, m, end) => {
    let n;
    if (end) {
        n = Number(input.split('-')[1]) === 4 ?  new Date(`${Number(input.split('-')[0]) + 1}/01/01`) : new Date(`${Number(input.split('-')[0])}/${Number(input.split('-')[1]) * 3 + 1}/01`);
    } else {
        n = new Date(`${input.split('-')[0]}/${input.split('-')[1] * 3 - 2}/01`);
    }
    n.setHours(h, m, 0);
    return n.toISOString();
}

const formatDateToMonth = (date) => {
    if (new Date(date).getMonth() < 9) {
        return `${new Date(date).getFullYear()}-0${new Date(date).getMonth() + 1}`
    } else {
        return `${new Date(date).getFullYear()}-${new Date(date).getMonth() + 1}`
    }
};


const CohorteClients = (props) => {
    const [loading, setLoading] = useState(false);
    const [calculating, setCaculating] = useState(false);
    const [data, setData] = useState({});
    const [input, setInput] = useState('number');
    const [name, setName] = useState('trimester');
    const [dataType, setDataType] = useState(""); 
    const [source, setSource] = useState('')

    const month = name ==='month' ? getMonths() : getQuarter(source);

    const getDatas = () => {
        setLoading(true);
        const month = getMonths();
        const state = [{value: "terminated"}, {value: "reviewed"}, {value: "approved"}];
        const apis = month.map(el => fetchApi(`/requests/analytics/cohort?start=${getDate(el, 0, 0, false)}&end=${getDate(el, 23, 59, true)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}`))
        Promise.all(apis)
            .then(responses => {
                setLoading(false);
                setCaculating(true);
                let datas = {};
                responses.map((element, index) => {
                    let result = [];
                    let fromNewUser = 0;
                    let turnover = [];
                    for (let i=0; i < month.length; i++) {
                        if (getDate(month[index], 0, 0, false) < getDate(month[i], 0, 0, false)) {
                            result.push(null);
                            turnover.push(null);
                        } else {
                            let calculateTurnover = 0;

                            let countArr = element.requests.filter(r => r.userId && r.createdAt && formatDateToMonth(r.userId?.createdAt) === month[i] && formatDateToMonth(r.createdAt) === month[index]); 

                            if (month[i] === "2020-1") {
                                countArr = element.requests.filter(r => r.userId && r.createdAt && (new Date (r.userId?.createdAt) < new Date('2020-01-01') || formatDateToMonth(r.userId?.createdAt) === month[i]) && formatDateToMonth(r.createdAt) === month[index]);
                            }
              
                            let uniqueArr = [];
                            if (dataType === "demande") {
                                uniqueArr = [...countArr]
                            } else {  
                                countArr.forEach(item => {
                                    if (uniqueArr.findIndex(el => el.userId._id === item.userId._id) === -1) uniqueArr.push(item)
                                })
                            }
                
                            const count = uniqueArr.length;
                            countArr.forEach(el => el.type === "dermatologie" ? calculateTurnover += 45 : calculateTurnover +=30)

                            result.push(count);
                            turnover.push(calculateTurnover)
                            if (getDate(month[index], 0, 0, false) === getDate(month[i], 0, 0, false)) {
                                fromNewUser = count;
                            }
                        }
                    };
                    datas[month[index]] = {result, total: fromNewUser, turnover};
                });
                setCaculating(false);
                setData(datas);
                setName('month');
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            })
    };

    const getDatasTrimester = () => {
        setLoading(true);
        const month = getQuarter(source);
        const state = [{value: "terminated"}, {value: "reviewed"}, {value: "approved"}];
        const type = [];
        const apis = month.map(el => fetchApi(`/requests/analytics/cohort?start=${getDateFromQuarter(el, 0, 0, false)}&end=${getDateFromQuarter(el, 0, 0, true)}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}${dataType ? `&type=${dataType}` : ''}${source ? `&utm_source=${source}`: ''}`))
        Promise.all(apis)
            .then(responses => {
                setLoading(false);
                setCaculating(true);
                let datas = {};
                responses.map((element, index) => {
                    let userResult = [];
                    let demandeResult = [];
                    let fromNewUser = 0;
                    let turnover = [];
                    for (let i=0; i < month.length; i++) {
                        if (getDateFromQuarter(month[index], 0, 0, false) < getDateFromQuarter(month[i], 0, 0, false)) {
                            userResult.push(null);
                            demandeResult.push(null);
                            turnover.push(null);
                        } else {
                            let calculateTurnover = 0;

                            let countArr = element.requests.filter(r => r.userId && r.createdAt && fromDateToQuarter(r.userId?.createdAt) === month[i] && fromDateToQuarter(r.createdAt) === month[index]);

                            if (month[i] === "2020-1") {
                                countArr = element.requests.filter(r => r.userId && r.createdAt && (new Date (r.userId?.createdAt) < new Date('2020-01-01') || fromDateToQuarter(r.userId?.createdAt) === month[i]) && fromDateToQuarter(r.createdAt) === month[index]);
                            }

                            let uniqueArr = [];                           
                            countArr.forEach(item => {
                                if (uniqueArr.findIndex(el => el.userId._id === item.userId._id) === -1) uniqueArr.push(item)
                            })
                            
                            const count = uniqueArr.length;
                            countArr.forEach(el => el.type === "dermatologie" ? calculateTurnover += 45 : calculateTurnover +=30)

                            userResult.push(uniqueArr.length);
                            demandeResult.push(countArr.length)
                            turnover.push(calculateTurnover)
                            if (getDate(month[index], 0, 0, false) === getDate(month[i], 0, 0, false)) {
                                fromNewUser = count;
                            }
                        }
                    };
                    datas[month[index]] = {userResult, demandeResult, total: fromNewUser, turnover};
                });
                setCaculating(false);
                setData(datas);
                setName('trimester')
            })
            .catch(err => {
                setLoading(false);
                console.error(err);
            })
    };

    useEffect(()=> {
        if (name === "month") {
            getDatas();
        } else {
            getDatasTrimester();  
        }
    
    }, [dataType, source]);

    const exportToExcel = () => {

            let toExport = month?.map( (m,i) => {
                let obj = {"Nombre de consultation": m};
                data[m] && data[m].demandeResult.forEach((el, i) => obj[month[i]] = el);
                obj['Consultation mois N'] = data[m].demandeResult.reduce((acc, val) => acc + val, 0);

                obj['']= ''


                obj['Nombre de nouveau client']= m;

                data[m] && data[m].userResult.forEach((el, i) => obj[`${month[i]} `] = el);
                obj['Client mois N'] = data[m].userResult.reduce((acc, val) => acc + val, 0);

                return obj;
            });
    
            let total = {'Nombre de consultation': 'Total'};
            Object.keys(data).length === month.length && month.map((m, i) => total[month[i]] = Object.keys(data).map(d => data[d].demandeResult[i]).reduce( (acc, val) => acc+val, 0) );
            total['Consultation mois N'] = Object.keys(data).length === month.length && month.map((m, i) => Object.keys(data).map(d => data[d].demandeResult[i]).reduce( (acc, val) => acc+val, 0)).reduce((acc, val) => acc+val, 0);

           
            total["Nombre de nouveau client"] = 'Total';
            Object.keys(data).length === month.length && month.map((m, i) => total[`${month[i]} `] = Object.keys(data).map(d => data[d].userResult[i]).reduce( (acc, val) => acc+val, 0) );
            total['Client mois N'] = Object.keys(data).length === month.length && month.map((m, i) => Object.keys(data).map(d => data[d].userResult[i]).reduce( (acc, val) => acc+val, 0)).reduce((acc, val) => acc+val, 0);
        
            toExport.push(total);
    
            let totalPerMonth = {'Nombre de consultation': 'Part des clients inscrits en mois N'};
            Object.keys(data).length === month.length && month.map((m, i) => totalPerMonth[month[i]] = `${((Object.keys(data).map(d => data[d].demandeResult[i]).reduce( (acc, val) => acc+val, 0) - data[m].total) / data[m].total *100).toFixed(2)} %`);
            totalPerMonth['Consultation mois N'] = Object.keys(data).length === month.length && month.map(el => data[el].total).reduce( (acc, val) => acc + val, 0);

            totalPerMonth["Nombre de nouveau client"] = "Part des clients inscrits en mois N";
            Object.keys(data).length === month.length && month.map((m, i) => totalPerMonth[`${month[i]} `] = `${((Object.keys(data).map(d => data[d].userResult[i]).reduce( (acc, val) => acc+val, 0) - data[m].total) / data[m].total *100).toFixed(2)} %`);
            totalPerMonth['Client mois N'] = Object.keys(data).length === month.length && month.map(el => data[el].total).reduce( (acc, val) => acc + val, 0);

            toExport.push(totalPerMonth);

            toExport.push({'': ''})
            toExport.push({'': ''})
            toExport.push({'': ''})


            let titre = {"Nombre de consultation": "", "Consultation mois N" : "Récurrentes"};
            month.map(m => titre[m] = m);
            toExport.push(titre)

            let cohortTable = month?.map( (m,index) => {
                let obj= {"Nombre de consultation": m}
                let sum = 0
                data[m] && data[m].demandeResult.forEach((el, i) => {
                    if (el) {
                        obj[month[i]] = `${obj[month[i]] = (Math.ceil((el/data[m].userResult[index])*100))}%`;
                        sum += Math.ceil((el/data[m].userResult[index])*100)
                    } else if ( el === 0) {
                        obj[month[i]] = "0%"
                    } else {
                        obj[month[i]] = null
                    }
                    
                });
                obj['Consultation mois N'] = `${sum - 100}%`;

                return obj;
            });
            
        
            toExport = [...toExport,...cohortTable]
         
            toExport.push({'': ''})
            toExport.push({'': ''})
            toExport.push({'': ''})

            let title = {"Nombre de consultation": "Récurente"};
            month.map(m => title[m] = m);

            title["Nombre de nouveau client"] = "Récurente Cumulée";
            month.map(m => title[`${m} `] = m);
            toExport.push(title);

            let pourcentCohortTable = month.map( (m,index) => {
                let cohortObj= {"Nombre de consultation": m}

                const keys = [...month]
                const values = cohortTable.map(el => el[m]).filter(el => el)
                keys.map((key,i) => cohortObj[key] = values[i])

                // cohortObj['Consultation mois N'] = '';

                // cohortObj['']= ''

                let cohortCumulateObj = {'Nombre de nouveau client' : m}

                let sum = 0;
                
                let periods = Object.keys(cohortObj).filter(key => key && key !== "Nombre de consultation" && key !== "Consultation mois N");
                
                periods.forEach(period => {
                    if (cohortObj[period]) {
                        sum += parseInt(cohortObj[period], 10); 
                        cohortCumulateObj[`${period} `] = `${sum} %`; 
                    }
                  
                });

                return {...cohortObj, ...cohortCumulateObj};
            });

            toExport = [...toExport,...pourcentCohortTable]

    
            const cls = month.map(el => ({ wch: 10 })); 
         
            exportAsExcel(toExport, `cohorte-client`, cls);  
         
    }


    return (
        <div className='cohorte'>
            <Modal
                show={loading || calculating}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modal-spinner"
            >
                <span className="loader" />
                {loading && <p>Récupération des données en cours...</p>}
                {calculating && <p>Calcul des données en cours...</p>}
            </Modal>

            <select name='name' value={name} onChange={e=>{
                if (e.target.value === 'month') {
                    getDatas();
                };
                if (e.target.value === 'trimester') {
                    getDatasTrimester();
                };
            }}>
                {/* <option value='month'>Par mois</option> */}
                <option value='trimester'>Par trimestre</option>
            </select>

            <select name='dataType' value={dataType} onChange={(e)=>{
                setDataType(e.target.value)
            }}>
                <option value=''>Tous les pathologies</option>
                {
                    typeStates.map(type => <option value={type.value} key={type.value}>{type.label}</option>)
                }
            </select>
            <select name='source' value={source} onChange={e=>{
                setSource(e.target.value);
            }}>
                <option value=''>Tous les sources</option>
                <option value='google'>Google</option>
                <option value='skycall'>Skycall</option>
                <option value='null'>Naturel</option>
            </select>
            <select name='input' value={input} onChange={e=>{
                setInput(e.target.value);
            }}>
                <option value='number'>Nombre</option>
                <option value='percent'>Pourcentage</option>
                <option value='turnover'>Chiffre d'affaire</option>
                <option value='chart'>Graphique</option>
            </select>

            {input !== "chart" && <button onClick={() => exportToExcel()} className ='button'>Export en excel</button>}

            {/* {
                input === "chart" ?
                <Chart 
                    months={month}
                    data={data}
                    dataType={dataType}
                />
                :
                <div className='table-container'>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                {
                                    month.map(el => 
                                    <th key={`h-${el}`}>
                                        {el}
                                    </th>)
                                }
                                { input === "number" && <th>{dataType === 'demande' ? 'Consultation mois N' : 'Client mois N'}</th> }
                                { input === "percent" && <th>CAHT Mensuel</th> }
                                { input === "turnover" && <th>CA</th> }
                                { input === "turnover" && <th>CA Récurents</th> }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                    month.map(el =>             
                                    <tr key={`v-${el}`}>
                                        <td className='first-column'><b>{el}</b></td> 
                                        {data[el] && input === "number" && data[el].result.map((item, i) => <td key={`item-${i}`}>{item}</td>)}
                                        {data[el] && input === "percent" && data[el].result.map((item, i) => item !== null ? <td key={`item-${i}`}>{(item/data[month[i]].total*100).toFixed(2)} %</td> : <td key={`k-${i}`}></td>)}
                                        {data[el] && input === "turnover" && data[el].turnover.map((item, i) => item !== null ? <td key={`item-${i}`}>{item} euros</td> : <td key={`k-${i}`}></td>)}          
                                        {!data[el] && month.map(el=><td key={`e-${el}`}></td>)}

                                        {data[el] && input === "number" && <td><b>{data[el].result.reduce((acc, val) => acc + val, 0)}</b></td>}
                                        {data[el] && input === "percent" && <td><b>{(data[el].result.map((item, i) => (item/data[month[i]].total*100).toFixed(2) ).reduce((acc, val) => acc + Number(val), 0)).toFixed(2)} % </b></td>}
                                        {data[el] && input === "turnover" && <td><b>{data[el].turnover.reduce((acc, val) => acc + val, 0)}</b> euros</td>}    
                                        {data[el] && input === "turnover" && <td><b>{data[el].turnover.reduce((acc, val) => acc + val, 0) - Math.max(...data[el].turnover.filter(element=>element !== null))}</b> <br/> euros</td>} 
                                        {!data[el] && <td></td>}               
                                    </tr>
                                )
                            }
                            {
                                input === "number" && 
                                <tr>
                                    <td>Total</td>
                                    {Object.keys(data).length === month.length && month.map((m, i) => <td>{Object.keys(data).map(d => data[d].result[i]).reduce( (acc, val) => acc+val, 0)}</td> )}
                                    {Object.keys(data).length === month.length && <td><b>{month.map((m, i) => Object.keys(data).map(d => data[d].result[i]).reduce( (acc, val) => acc+val, 0) ).reduce((acc, val) => acc+val, 0)}</b></td>}
                                </tr>
                            }

                            {
                                input === "percent" && 
                                <tr>
                                <td>Nombre de Clients inscrits en mois N</td>
                                {Object.keys(data).length === month.length && month.map((m, i) => <td>{((Object.keys(data).map(d => data[d].result[i]).reduce( (acc, val) => acc+val, 0) - data[m].total )/ data[m].total *100).toFixed(2)  } %</td> )}
                                {Object.keys(data).length === month.length && <td><b>{month.map((m, i) => ((Object.keys(data).map(d => data[d].result[i]).reduce( (acc, val) => acc+val, 0) - data[m].total )/ data[m].total *100)).reduce((acc, val) => acc+val, 0).toFixed(2)} %</b></td>}
                            </tr>
                            }
                    
                            {
                                input === "number" && 
                                <tr>
                                <td>Part des clients inscrits en mois N</td>
                                {
                                    Object.keys(data).length === month.length && month.map(el => data[el] && <td>{(data[el].total / data[el].result.reduce((acc, val) => acc + val, 0)*100).toFixed(2)} %</td>)
                                }
                                <td><b>{ Object.keys(data).length === month.length && month.map(el => data[el].total).reduce( (acc, val) => acc + val, 0)}</b></td>
                            </tr>
                            }

                            {
                                input === "turnover" && 
                                <tr>
                                    <td>Total</td>
                                    {Object.keys(data).length === month.length && month.map((m, i) => <td>{Object.keys(data).map(d => data[d].turnover[i]).reduce( (acc, val) => acc+val, 0)} euros</td> )}
                                    {Object.keys(data).length === month.length && <td><b>{month.map((m, i) => Object.keys(data).map(d => data[d].turnover[i]).reduce( (acc, val) => acc+val, 0) ).reduce((acc, val) => acc+val, 0)}</b> <br/>euros</td>}
                                    {Object.keys(data).length === month.length && <td><b>{month.map((m, i) => data[m].turnover.reduce((acc, val) => acc + val, 0) - Math.max(...data[m].turnover.filter(element=>element !== null))).reduce((acc, val) => acc + val, 0)}</b> <br/> euros</td>} 
                                </tr>
                            }
                        
                        </tbody>
                    </table>
                </div>
            } */}

           
            
        </div>
    );
};

export default CohorteClients;
