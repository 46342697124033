import React from 'react';
import { Row, Col, Panel } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';

export default class Tests extends React.Component {
    constructor() {
        super();

        this.state = {
            tests: []
        };

        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        fetchApi('/tests')
            .then(res =>
                this.setState({
                    tests: res.tests
                        .filter(({ userId }) => !!userId)
                        .map(r => ({
                            ...r,
                            testerId: r.testerId
                                ? r.testerId
                                : { FName: '', LName: '' }
                        }))
                })
            )
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
    }

    handleRemove(id) {
        const request = this.state.tests.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment supprimer cette request ${
                request.type
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi(`/tests/${request._id}`, {
                    method: 'DELETE'
                }).then(() => {
                    this.setState({
                        tests: this.state.tests.filter(
                            ({ _id }) => _id !== id
                        )
                    });

                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Requete supprime',
                        'success'
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Operation annulee',
                    'error'
                );
            }
        });
    }

    onSort(sortKey, optionKey) {
        const data = this.state.tests;
        if (optionKey) {
            if (this.state.sort) {
                data.sort((a, b) =>
                    a[sortKey][optionKey].localeCompare(b[sortKey][optionKey])
                );
                this.setState({ sort: false });
            } else {
                data.sort((a, b) =>
                    b[sortKey][optionKey].localeCompare(a[sortKey][optionKey])
                );
                this.setState({ sort: true });
            }
        } else {
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
        }

        this.setState({ tests: data });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Tests</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>Liste des 300 derniers Tests</p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'userId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('createdAt')
                                                    }
                                                >
                                                    Date de creation
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('type')
                                                    }
                                                >
                                                    Type
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('state')
                                                    }
                                                >
                                                    Etat
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'testerId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Docteur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tests.map(r => (
                                                <tr key={r._id}>
                                                    <td>
                                                        {r.userId.FName}{' '}
                                                        {r.userId.LName}
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            r.createdAt,
                                                            'dd/mm/yy HH:MM'
                                                        )}
                                                    </td>
                                                    <td>{r.type}</td>
                                                    <td>{r.state}</td>
                                                    <td>
                                                        {r.testerId
                                                            ? r.testerId.FName +
                                                              ' ' +
                                                              r.testerId.LName
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fas fa-file-medical"
                                                            text="Details"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/tests/' +
                                                                        r._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() =>
                                                                this.handleRemove(
                                                                    r._id
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export class TestsWaiting extends React.Component {
    constructor() {
        super();

        this.state = {
            tests: []
        };

        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        fetchApi('/tests?filter=waitingActivation')
            .then(res =>
                this.setState({
                    tests: res.tests
                        .filter(({ userId }) => !!userId)
                        .map(r => ({
                            ...r,
                            testerId: r.testerId
                                ? r.testerId
                                : { FName: '', LName: '' }
                        }))
                })
            )
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees test: ' + err,
                    'warning'
                )
            );
    }

    handleRemove(id) {
        const request = this.state.tests.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment supprimer cette request ${
                request.type
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi(`/tests/${request._id}`, {
                    method: 'DELETE'
                }).then(() => {
                    this.setState({
                        tests: this.state.tests.filter(
                            ({ _id }) => _id !== id
                        )
                    });

                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Requete supprime',
                        'success'
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Operation annulee',
                    'error'
                );
            }
        });
    }

    onSort(sortKey, optionKey) {
        const data = this.state.tests;
        if (optionKey) {
            if (this.state.sort) {
                data.sort((a, b) =>
                    a[sortKey][optionKey].localeCompare(b[sortKey][optionKey])
                );
                this.setState({ sort: false });
            } else {
                data.sort((a, b) =>
                    b[sortKey][optionKey].localeCompare(a[sortKey][optionKey])
                );
                this.setState({ sort: true });
            }
        } else {
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
        }

        this.setState({ tests: data });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Tests</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>Liste des 300 derniers Tests</p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'userId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('createdAt')
                                                    }
                                                >
                                                    Date de creation
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('type')
                                                    }
                                                >
                                                    Type
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('state')
                                                    }
                                                >
                                                    Etat
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'testerId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Docteur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tests.map(r => (
                                                <tr key={r._id}>
                                                    <td>
                                                        {r.userId.FName}{' '}
                                                        {r.userId.LName}
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            r.createdAt,
                                                            'dd/mm/yy HH:MM'
                                                        )}
                                                    </td>
                                                    <td>{r.type}</td>
                                                    <td>{r.state}</td>
                                                    <td>
                                                        {r.testerId
                                                            ? r.testerId.FName +
                                                              ' ' +
                                                              r.testerId.LName
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fas fa-file-medical"
                                                            text="Details"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/tests/' +
                                                                        r._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() =>
                                                                this.handleRemove(
                                                                    r._id
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}

export class TestsApproving extends React.Component {
    constructor() {
        super();

        this.state = {
            tests: []
        };

        this.handleRemove = this.handleRemove.bind(this);
    }

    componentDidMount() {
        fetchApi('/tests?filter=waitingApproval')
            .then(res =>
                this.setState({
                    tests: res.tests
                        .filter(({ userId }) => !!userId)
                        .map(r => ({
                            ...r,
                            testerId: r.testerId
                                ? r.testerId
                                : { FName: '', LName: '' }
                        }))
                })
            )
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            );
    }

    handleRemove(id) {
        const request = this.state.tests.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment supprimer cette request ${
                request.type
            }? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then(result => {
            if (result.value) {
                fetchApi(`/tests/${request._id}`, {
                    method: 'DELETE'
                }).then(() => {
                    this.setState({
                        tests: this.state.tests.filter(
                            ({ _id }) => _id !== id
                        )
                    });

                    swalWithBootstrapButtons(
                        'Supprime!',
                        'Requete supprime',
                        'success'
                    );
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Operation annulee',
                    'error'
                );
            }
        });
    }

    onSort(sortKey, optionKey) {
        const data = this.state.tests;
        if (optionKey) {
            if (this.state.sort) {
                data.sort((a, b) =>
                    a[sortKey][optionKey].localeCompare(b[sortKey][optionKey])
                );
                this.setState({ sort: false });
            } else {
                data.sort((a, b) =>
                    b[sortKey][optionKey].localeCompare(a[sortKey][optionKey])
                );
                this.setState({ sort: true });
            }
        } else {
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
        }

        this.setState({ tests: data });
    }

    render() {
        return (
            <div className="pT-25">
                <Row>
                    <Col md={12}>
                        <Panel>
                            <Panel.Heading>
                                <h6>Tests</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <p>Liste des 300 derniers Tests</p>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'userId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('createdAt')
                                                    }
                                                >
                                                    Date de creation
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('type')
                                                    }
                                                >
                                                    Type
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort('state')
                                                    }
                                                >
                                                    Etat
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() =>
                                                        this.onSort(
                                                            'testerId',
                                                            'FName'
                                                        )
                                                    }
                                                >
                                                    Testeur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tests.map(r => (
                                                <tr key={r._id}>
                                                    <td>
                                                        {r.userId.FName}{' '}
                                                        {r.userId.LName}
                                                    </td>
                                                    <td>
                                                        {dateFormat(
                                                            r.createdAt,
                                                            'dd/mm/yy HH:MM'
                                                        )}
                                                    </td>
                                                    <td>{r.type}</td>
                                                    <td>{r.state}</td>
                                                    <td>
                                                        {r.testerId
                                                            ? r.testerId.FName +
                                                              ' ' +
                                                              r.testerId.LName
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fas fa-file-medical"
                                                            text="Details"
                                                            onClick={() =>
                                                                this.props.history.push(
                                                                    '/tests/' +
                                                                        r._id
                                                                )
                                                            }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() =>
                                                                this.handleRemove(
                                                                    r._id
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}