import React, { useEffect, useState } from 'react';
import {  Modal, Panel, Button } from 'react-bootstrap';

import '../../Assets/Ui/RequestCounter.css';
import '../../Assets/Ui/RequestAnalytics.css';
import '../../Assets/Ui/Requests.css';

import IconTooltip from '../Ui/IconTooltip';
import { fetchApi } from '../../Network/NetworkService';
import isoDate from '../../Services/toIsoDate.js';
import { notif } from './Notification';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { exportAsExcel } from '../../Services/exportAsExcel.js';
import dateFormat from 'dateformat';
import { rqState } from '../../Constants.jsx';

const CustomerValue = (props) => {
    const [total, setTotal] = useState(0);
    const [countOld, setCountOld] = useState([]);
    const [requestArr, setRequestArr] = useState([]);
    const [start, setStart] = useState(new Date().toISOString().substring(0, 10)); 
    const [end, setEnd] = useState(new Date().toISOString().substring(0, 10)); 
    const [isSorting, setIsSorting] = useState(false);
    const [pathology, setPathology] = useState({});
    const [location, setLocation] = useState(null);
    const [repeatSkycall, setRepeatSkycall] = useState([]);
    const [state, setState] = useState([]);
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [comparison, setComparison] = useState({value: 'last-month', label: 'Mois précédent'});
    const [isExporting, setIsExporting] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [startDateExporting, setStartDateExporting] = useState('');
    const [endDateExporting, setEndDateExporting] = useState('');

    const getCount = () => {
        setTotal(-1);
        setCountOld([]);
        setRequestArr([]);
        setRepeatSkycall([]);

        let stateInput = state;

        if ((props.history.location?.state && JSON.stringify(location) !== JSON.stringify(props.history.location?.state)) || (!location && props.history.location?.state)) {
            startInput = props.history.location?.state.start ; 
            endInput = props.history.location?.state.end ;
            stateInput = props.history.location?.state.state ;
            setLocation(props.history.location?.state);
            setStart(props.history.location.state.start);
            setEnd(props.history.location.state.end);
            setState(props.history.location.state.state);
        };

        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }

        const apis = [
            fetchApi(`/requests/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}${stateInput.length > 0 ? `&state=${stateInput.map((v) => v.value).toString()}` : ''}`),
            fetchApi(`/requests/analytics?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}${stateInput.length > 0 ? `&state=${stateInput.map((v) => v.value).toString()}` : ''}`)
        ]

        Promise.all(apis)
            .then((responses) => {
                let oldClients = [];
                let arr = [];
                let pathologyObj = {};
                let repeatSkycallArr = [];
                let pathologyCompareObj = {};

                responses[0].requests.map(el=>{
                    if (new Date (el.userId?.createdAt) < new Date(isoDate(startDate, 0, 0))) {
                        oldClients.push(el);
                        if (arr.findIndex(a => a._id === el.userId?._id) === -1)  {
                            arr.push(el.userId);
                        }
                        if (pathologyObj[el.type]) {
                            pathologyObj[el.type]++;
                        }
                        if (!pathologyObj[el.type]) {
                            pathologyObj[el.type] = 1;
                        }
                        if (el.utm_source?.includes("skycall")) {
                            repeatSkycallArr.push(el)
                        }
                    }
                    
                });

                Object.keys(pathologyObj).map(path => pathologyCompareObj[path] = responses[1].requests.filter(el=>el.type === path).length);

                setCountOld(oldClients);
                setRequestArr(arr);
                setTotal(responses[0].countRequests);
                setPathology(pathologyObj);
                setRepeatSkycall(repeatSkycallArr);

            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    const getValue = (arr) => {
        let total = 0;
        arr && arr.length > 0 && arr.map(el => {
            if (el.state === "approved" || el.state === "reviewed" || el.state === "terminated") {
                el.type === "dermatologie" ? total +=45 : total+=30
            }
        })
        return total;
    }

    const getValueCustomer = (arr, name) => {
        let data =[...arr];
        data = data.filter(el=>el.request.length > 1)
        let value = 0;
        if (name === "value") {
            value = data.reduce((acc, val) => acc + getValue(val.request), 0);
        } else if (name === "user") {
            value = data.length;
        } else if (name === "request") {
            value = data.reduce((acc, val) => acc + val.request.length, 0);
        }
        return value;    
    }

    const onSort = (sortKey, optionKey, length) => {
        const data = requestArr;
        if (length) {
            if (optionKey) {
                if (isSorting) {
                    data.sort((a, b) => 
                        a[sortKey][optionKey].length - b[sortKey][optionKey].length 
                    );
                    setIsSorting(false);
                } else {
                    data.sort((a, b) =>
                        b[sortKey][optionKey].length - a[sortKey][optionKey].length
                    );
                    setIsSorting(true );
                }
            } else {
                if (isSorting) {
                    data.sort((a, b) => a[sortKey].length - b[sortKey].length);
                    setIsSorting(false)
                } else {
                    data.sort((a, b) => b[sortKey].length - a[sortKey].length);
                    setIsSorting(true)
                }
            }
        } else {
            if (optionKey) {
                if (isSorting) {
                    data.sort((a, b) =>
                        a[sortKey][optionKey].localeCompare(b[sortKey][optionKey])
                    );
                    setIsSorting(false);
                } else {
                    data.sort((a, b) =>
                        b[sortKey][optionKey].localeCompare(a[sortKey][optionKey])
                    );
                    setIsSorting(true );
                }
            } else {
                if (isSorting) {
                    data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                    setIsSorting(false)
                } else {
                    data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                    setIsSorting(true)
                }
            }
        }

        setRequestArr(data)
    }

    const onSortValue = () => {
        const data = requestArr;
            if (isSorting) {
                data.sort((a, b) => getValue(a.request) - getValue(b.request));
                setIsSorting(false)
            } else {
                data.sort((a, b) => getValue(b.request) - getValue(a.request));
                setIsSorting(true)
            }
        setRequestArr(data)
    }

    useEffect(() => {
        getCount();
    }, []);

    const countSource = (arr, source) => { 
        let result;
        if (source === "pharmacy") {
            result = arr.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes(source)).length;
        } else {
            result = arr.filter(el => el.utm_source === source).length;
        }
   
        return result;
    };

    const countNumberOfRequest = (array) => {
        let numberOfRequest = {};
        for (let i=0; i< array.length; i++) {
            if (numberOfRequest[array[i].request.length]) {
                numberOfRequest[array[i].request.length].count++;
                numberOfRequest[array[i].request.length].detail.push(array[i].request)
            } else {
                numberOfRequest[array[i].request.length] = {count: 1, detail: [array[i].request]};
            }
        }
        return numberOfRequest;
    };

    const exportToExcel = () => {
        setIsExporting(true);
      
        fetchApi(`/requests/analytics?start=${isoDate(startDateExporting, 0, 0)}&end=${isoDate(endDateExporting, 23, 59)}`)
            .then(response => {
                const data = response.requests.length > 0 && response.requests.filter(el =>  new Date (el.userId?.createdAt) < new Date(isoDate(startDateExporting, 0, 0))).filter(el => el.utm_source && el.utm_source?.includes('skycall'));
      
                const toExport = data.length > 0 ? data.map((v,i) => ({
                    '#': i+1,
                    'Requête ID': v._id,
                    "Date de création": dateFormat(v.createdAt, 'dd/mm/yy HH:MM'),
                    Type: v.type,
                    Statut: v.state,
                    utm_source: v.utm_source,
                    utm_content: v.utm_content
                })) : [];
    
                const cls = [
                    { wch: 15 },
                    { wch: 25 },
                    { wch: 20 },
                    { wch: 20 },
                    { wch: 15 },
                    { wch: 20 },
                    { wch: 20 },
                
                ];
                
                exportAsExcel(toExport, `repeats_skycall${new Date().toISOString()}`, cls);
                setIsExporting(false);
                setModalIsOpen(false);
                notif.addNotification(
                    'Succes !',
                    '',
                    'success'
                );
            })
            .catch ((err) => {
                setIsExporting(false);
                setModalIsOpen(false);
                console.log(err)
             })
           
    }

    const exportModal = () => {
        return (
            <Modal show={modalIsOpen}>
                <Modal.Header>
                    <Modal.Title>Exporter en excel les repeats Skycall</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        De : <input
                            type="date"
                            value={startDateExporting}
                            onChange={(e) => setStartDateExporting(e.target.value)}
                            max={endDateExporting || new Date().toISOString().split('T')[0]}
                        />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                       au : <input
                            type="date"
                            value={endDateExporting}
                            onChange={(e) => setEndDateExporting(e.target.value)}
                            min={startDateExporting}
                        />
                    </div>
                    
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => {
                        setModalIsOpen(false)
                    }}>Fermer</Button>
                    <Button disabled={isExporting || !startDateExporting || !endDateExporting} onClick={(e) => {
                        e.preventDefault();
                        exportToExcel()
                        }} bsStyle="primary">{isExporting ? 'Chargement ...' : 'Valider'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    return (
        <div className='r-analytics pT-25'>
            {exportModal()}
        <Panel>
            <Panel.Heading className='heading'>
                <h2>Récurrents clients</h2>
                <div>
                    <h6>Compteur du {' '} </h6>
                    <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                    {
                        comparison.value === 'personalized' &&
                        <DateRangePicker onChange={value => setRangeCompare(value)} value={rangeCompare} maxDate={new Date()}/>
                    }  
                    
                    <Select
                        value={state}
                        onChange={(v) => setState(v)}
                        options={rqState}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par status ..." 
                        className='filter-status'
                    />
                    <button onClick={() => {
                        getCount();
                    }}>Chercher</button>
                </div>
            </Panel.Heading>
            <Panel.Body>
                <div>
                <button
                    className="button-export"
                    onClick={() => setModalIsOpen(true)}
                    disabled={isExporting}
                >
                        Exporter en excel les repeats skycall
                </button>

                </div>
                <div className='source-table'>
                    <h1>Analyse par pathologie des récurrents clients</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(pathology).map(p => <th key={p} colSpan={2}>{p}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(pathology).map((p, i) => [<th key={`1${i}${p}`}>Nombre</th>, <th key={`2${i}${p}`}>Taux</th>])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {Object.keys(pathology).map(p => [<td key={`1-value-${p}`}>{pathology[p]}</td>, <td key={`2-value-${p}`}>{(pathology[p] / countOld.length * 100).toFixed(2)} %</td>])}
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                {
                    requestArr.length > 0 &&
                    <div className='source-table'>
                        <h1>Customer Time Value</h1>
                        <table className="table table-hover mb-0 analytic-table"> 
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Nombre de requêtes</th>
                                    {Object.keys(countNumberOfRequest(requestArr)).map((el, i)=>
                                        <th key={`key-${i}`} colSpan={3}>{el}</th>)}
                                </tr>
                                <tr>
                                    {Object.keys(countNumberOfRequest(requestArr)).map((el)=> [
                                        <th key={`title-1-${el}`}>Nombre</th>,
                                        <th key={`title-2-${el}`}>Taux</th>,
                                        <th key={`title-3-${el}`}>Valeur</th>,
                                    ])}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Nombre d'utilisateurs</td>
                                    {Object.keys(countNumberOfRequest(requestArr)).map((el, i)=> [
                                        <td key={`number-${i}`}>{countNumberOfRequest(requestArr)[el].count}</td>,
                                        <td key={`percent-${i}`}>{(countNumberOfRequest(requestArr)[el].count /countOld.length *100).toFixed(2)} %</td>,
                                        <td key={`value-${i}`}>{countNumberOfRequest(requestArr)[el].detail.reduce((acc, val) => acc + getValue(val), 0)} euros</td>

                                    ])}
                                </tr>
                                
                            </tbody>
                        </table>
                        <p>Note: Calculer sur les clients récurrents</p>
                    </div> 
                }               

                {
                    requestArr.length > 0 &&
                    <div className='source-table'>
                        <h1>Valeur d'un ancien client</h1>
                        <table className="table table-hover mb-0"> 
                            <thead>
                                <tr>
                                    <th>Utilisateurs</th>
                                    <th>Requêtes</th>
                                    <th>Chiffre d'affaire</th>
                                    <th>Valeur moyen d'un client </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{getValueCustomer(requestArr, 'user')}</td>
                                    <td>{getValueCustomer(requestArr, 'request')}</td>
                                    <td>{getValueCustomer(requestArr, 'value').toLocaleString()} euros</td>
                                    <td>{(getValueCustomer(requestArr, 'value') / getValueCustomer(requestArr, 'user')).toFixed(2)} euros</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>Note: Nombre d'utilisateurs qui ont plus d'une requête</p>
                    </div>
                }

                {
                    requestArr.length > 0 &&
                    <div className='source-table'>
                        <h1>Liste d'anciens clients</h1>
                         <table className="table table-hover mb-0"> 
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Utilisateurs</th>
                                    <th  onClick={() => onSort('createdAt', '', false)}>
                                        Date d'inscription
                                        <i
                                            className={
                                                isSorting
                                                    ? 'zmdi zmdi-caret-down'
                                                    : 'zmdi zmdi-caret-up'
                                            }
                                        />
                                    </th>
                                    <th onClick={() => onSort('request', '', true)}>
                                        Requetes
                                        <i
                                            className={
                                                isSorting
                                                    ? 'zmdi zmdi-caret-down'
                                                    : 'zmdi zmdi-caret-up'
                                            }
                                        />
                                    </th>
                                    <th onClick={() => onSortValue()}>
                                        Valeur du Client (euros)
                                        <i className={
                                                isSorting
                                                    ? 'zmdi zmdi-caret-down'
                                                    : 'zmdi zmdi-caret-up'
                                            }
                                        />
                                    </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    requestArr.map((r, i) => <tr key={i}>
                                        <td>{i +1}</td>
                                        <td>
                                            {r.FName} {r.LName}
                                        </td>
                                        <td>{new Date(r.createdAt).toLocaleDateString()}</td>
                                        <td>{r.request.length}</td>
                                        <td>
                                            {getValue(r.request)}
                                        </td>
                                        <td>
                                            <IconTooltip
                                                icon="fa fa-user"
                                                text="Voir le profil"
                                                onClick={() =>
                                                    props.history.push({
                                                        pathname: '/users/detail/' + r._id,
                                                        state: {
                                                            start: start,
                                                            end: end,
                                                            state: state,
                                                            page: '/analytics/customer-value'
                                                        }
                                                    }     
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>               
                }

                {
                    repeatSkycall.length > 0 &&
                    <div className='source-table'>
                        <h1>Liste d'anciens clients Skycall</h1>
                         <table className="table table-hover mb-0"> 
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Request Id</th>
                                    <th>
                                        Date 
                                    </th>
                                    <th>
                                        Type 
                                    </th>
                                    <th>Statut</th>
                                    <th>utm_content</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    repeatSkycall.map((r, i) => <tr key={i}>
                                        <td>{i +1}</td>
                                        <td>
                                            {r._id}
                                        </td>
                                        <td>{new Date(r.createdAt).toLocaleDateString()}</td>
                                        <td>{r.type}</td>
                                        <td>
                                            {r.state}
                                        </td>
                                        <td>
                                            {r.utm_content}
                                        </td>
                                        <td>
                                            <IconTooltip
                                                icon="fa fa-user"
                                                text="Voir le profil"
                                                onClick={() =>
                                                    props.history.push({
                                                        pathname: '/users/detail/' + r._id,
                                                        state: {
                                                            start: start,
                                                            end: end,
                                                            state: state,
                                                            page: '/analytics/customer-value'
                                                        }
                                                    }     
                                                    )
                                                }
                                            />
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    </div>               
                }
            </Panel.Body>
        </Panel>
        </div>
    );
};

export default CustomerValue;

