import moment from "moment";

const isoDate = (date, h, m) => {
    const n = date ? moment(date).utc(true) : moment();
    
    n.set({ hour: h, minute: m })
    const isoString = n.toISOString();    
    
    return isoString;
};

export default isoDate;