import React, { useEffect, useState } from 'react';
import { ButtonToolbar, MenuItem, Panel, SplitButton } from 'react-bootstrap';
import Select from 'react-select';

import '../../Assets/Ui/RequestCounter.css';

import { fetchApi } from '../../Network/NetworkService';
import isoDate from '../../Services/toIsoDate.js';
import { rqState } from '../../Constants.jsx';


const drState = [
    { value: 'locked', label: 'Bloqué' },
    { value: 'inactive', label: 'Non activé' },
];

const clState = [{ value: 'locked', label: 'Bloqué' }];

const sourceState = [
    { value: 'google', label: 'Google' },
    { value: 'bing', label: 'Bing' },
    { value: 'crechmed', label: 'Crechmed' },
    { value: 'feeli-blog', label: 'Blog Feeli' },
    { value: 'skycall', label: 'Skycall (tous)' },
    { value: 'skycall-normal', label: 'Skycall (sauf skycall voice)' },
    { value: 'skycall-api', label: 'Skycall Api' },
    { value: 'skycall-voice', label: 'Skycall Voice' },
    { value: 'pharmacy', label: 'Pharmacie/Camping' },
    { value: 'null', label: 'No source' }
];

const pathologyState = [
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'kit-voyage-et-vaccination', label: 'kit-voyage-et-vaccination' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'renouvellement-ordonnance', label: 'renouvellement-ordonnance' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];
const createdTimeState = [
    { start: '', end: '', label: ''},
    { start: new Date(new Date().getTime() - 1000*60*60).toISOString(), end: '', label: '< 1 heure', name: 'less' },
    { start: new Date(new Date().getTime() - 1000*60*60*2).toISOString(), end: '', label: '< 2 heures', name: 'less' },
    { start: new Date(new Date().getTime() - 1000*60*60*3).toISOString(), end: '', label: '< 3 heures', name: 'less' },
    { start: '', end: new Date(new Date().getTime() - 1000*60*60*3).toISOString(), label: '> 3 heures', name: 'plus' },
];

const typeState = [
    { value: null, label: 'Tous' },
    { value: true, label: 'Urgent' },
    { value: false, label: 'Non urgent' },
];

const RequestCounter = ({ justToday, isPartner, utm_source, setValues, isAdminFiscale }) => {

    const options = isPartner ? [
        { value: 'requete', label: 'Demandes' },
    ] : [
        { value: 'requete', label: 'Demandes' },
        { value: 'doctor', label: 'Docteurs' },
        { value: 'client', label: 'Prospects' },
    ]

    const [filterValue, setFilterValue] = useState({
        start: isAdminFiscale ? '2020-01-01' : '',
        end: isAdminFiscale ? '2023-10-31' : '',
        type: [],
        source: '',
        pathology: [],
        createdTime: '',
        priority: null
    });
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [option, setOption] = useState({ value: 'requete', label: 'Demandes' });

    const { start, end, type, source, pathology, createdTime, priority } = filterValue;

    const getCount = () => {
        setLoading(true);
        fetchApi(`/requests/count`, {
            method: 'POST',
            body: JSON.stringify({
                ...(justToday ? { start: isoDate(null, 0, 0) } : createdTime.start ? {start : createdTime.start } : start && { start: isoDate(start, 0, 0) }),
                ...(justToday ? { end: isoDate(null, 23, 59) } : createdTime.end ? {end : createdTime.end } : end && { end: isoDate(end, 23, 59) }),
                type: type.map((v) => v.value),
                pathology,
                ...(isPartner ? { utm_source: filterValue.source && filterValue.source.value ?  filterValue.source.value : utm_source} : {utm_source: source.value ? source.value : ''}),
                ...(priority ? { priority: priority.value } : {}),
            })
        })
            .then((res) => {
                setCount(res.count);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setCount('Error !');
            });
    };

    const handleDateChange = (e) => {
        setFilterValue({ ...filterValue, [e.target.name]: e.target.value });
    };

    const handleSelect = (e) => {
        setOption(e);
    }

    useEffect(() => {
        getCount();
    }, [start, end, type, option, source, pathology, createdTime, priority]);

    return (
        <Panel>
            <Panel.Heading>
                <h6>Compteur {justToday && new Date().toISOString().split('T')[0]} et filtre</h6>
            </Panel.Heading>
            <Panel.Body>
                <div className="counter">
                    <div className="filter-block">
                        {/* <div className="select-block">
                        <ButtonToolbar>
                            <SplitButton
                                id="option-list"
                                rootCloseEvent="mousedown"
                                title={option.label}
                            >   
                                {
                                    options.map((v, i) => (
                                        <MenuItem
                                            eventKey={v}
                                            active={v.value === option.value}
                                            onSelect={v.value === option.value ? undefined : handleSelect}
                                            key={`item-${i}`}
                                    >
                                        {v.label}
                                    </MenuItem>
                                ))
                            }
                        </SplitButton>
                        </ButtonToolbar>
                        </div> */}
                       
                       { !justToday && (
                           <>
                            <div className="date-block">
                              <p>Debut</p>
                              <div className="inputDate">
                                <input  
                                    max={filterValue.end || new Date().toISOString().split('T')[0]}
                                    min={isAdminFiscale ? '2021-01-01' : ''}
                                    name="start" 
                                    onChange={handleDateChange} 
                                    type="date"
                                    value={filterValue.start}
                                />
                              </div>
                            </div>

                           <div className="date-block">
                            <p>Fin</p>
                            <div className="inputDate">
                                <input
                                    min={filterValue.start}
                                    max={isAdminFiscale ? '2023-10-31' : ''}
                                    name="end"   
                                    onChange={handleDateChange} 
                                    type="date" 
                                    value={filterValue.end}
                                />
                              </div>
                            </div>
                           </>
                       )}

                        <div className="select-group">
                            <div className="select-input">
                                <Select
                                    value={filterValue.type}
                                    onChange={(v) => {
                                        setFilterValue({ ...filterValue, type: v });
                                        if (option.value === 'requete') {
                                            setValues({ type: v });
                                        }
                                    }}
                                    options={option.value === 'doctor' ? drState : option.value === 'client' ? clState : rqState}
                                    isMulti
                                    styles={{ option: (s) => ({
                                        ...s,
                                        color: 'black'
                                    })}}
                                    role="checkbox"
                                    placeholder="Filtre par status ..." 
                                />
                            </div>

                            <div>
                                {
                                    !isPartner && !isAdminFiscale && 
                                    <div>
                                         &nbsp;
                                        <Select
                                            value={filterValue.createdTime}
                                            onChange={(v) => {
                                                setFilterValue({ ...filterValue, createdTime: v });
                                                if (option.value === 'requete') {
                                                    setValues({ createdTime: v});
                                                }
                                            }}
                                            options={createdTimeState}
                                            styles={{ option: (s) => ({
                                                ...s,
                                                color: 'black',
                                            })}}
                                            role="checkbox"
                                            placeholder="Filtre par temps ..." 
                                        />
                                        &nbsp;
                                        <Select
                                            value={filterValue.source}
                                            onChange={(v) => {
                                                setFilterValue({ ...filterValue, source: v });
                                                if (option.value === 'requete') {
                                                    setValues({ source: v});
                                                }
                                            }}
                                            options={sourceState}
                                            styles={{ option: (s) => ({
                                                ...s,
                                                color: 'black',
                                            })}}
                                            role="checkbox"
                                            placeholder="Filtre par source ..." 
                                        />
                                        &nbsp;
                                        <Select
                                            value={filterValue.pathology}
                                            onChange={(v) => {
                                                setFilterValue({ ...filterValue, pathology: v });
                                                if (option.value === 'requete') {
                                                    setValues({ pathology: v});
                                                }
                                            }}
                                            options={pathologyState}
                                            styles={{ option: (s) => ({
                                                ...s,
                                                color: 'black'
                                            })}}
                                            role="checkbox"
                                            placeholder="Filtre par pathologie ..." 
                                            isMulti
                                        />
                                         &nbsp;
                                    </div>
                                }
                                &nbsp;

                                <Select
                                    value={filterValue.priority}
                                    onChange={(v) => {
                                        setFilterValue({ ...filterValue, priority: v });
                                        if (option.value === 'requete') {
                                            setValues({ priority: v});
                                        }
                                    }}
                                    options={typeState}
                                    styles={{ option: (s) => ({
                                        ...s,
                                        color: 'black',
                                    })}}
                                    role="checkbox"
                                    placeholder="Filtre par priorité ..." 
                                />

                                &nbsp;

                                {
                                    isPartner && utm_source === "skycall" && 
                                    <Select
                                    value={filterValue.source}
                                    onChange={(v) => {
                                        setFilterValue({ ...filterValue, source: v });
                                        if (option.value === 'requete') {
                                            setValues({ source: v});
                                        }
                                    }}
                                    options={[
                                        { value: null, label: 'Tous' },
                                        { value: 'skycall', label: 'Skycall (tous)' },
                                        { value: 'skycall-normal', label: 'Skycall (sauf skycall voice)' },
                                        { value: 'skycall-voice', label: 'Skycall Voice' },
                                        { value: 'skycall-api', label: 'Skycall Api' },
                                    ]}
                                    styles={{ option: (s) => ({
                                        ...s,
                                        color: 'black',
                                    })}}
                                    role="checkbox"
                                    placeholder="Filtre par source ..." 
                                />
                                }
                            </div>
                        </div>
                    </div>

                    <div className="nb-block">
                        <Panel>
                            <Panel.Heading>
                                <h3>Total</h3>
                                {loading ? (
                                    <span className="loader" />
                                ) : (
                                    <h2>{count}</h2>
                                )}
                            </Panel.Heading>
                        </Panel>
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};

export default RequestCounter;
