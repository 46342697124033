import React from 'react';
import { Row, Col, Panel, Modal, Button } from 'react-bootstrap';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import dateFormat from 'dateformat';
import isoDate from '../../Services/toIsoDate';
import IconTooltip from '../Ui/IconTooltip';
import Swal from 'sweetalert2';
import debounce from '../../Services/debounce.js';
import NewslettersCounter from '../Ui/NewslettersCounter';
import { exportAsExcel } from '../../Services/exportAsExcel';
import moment from 'moment';


export default class Users extends React.Component {
    constructor() {
        super();

        this.state = {
            users: [],
            totalPage: 0,
            input: '',
            page: 1,
            countUsers: 0,
            loading: false,
            showExportModal: false,
            startDate: new Date(),
            endDate: new Date(),
            isExporting: false
        };

        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.handleSeeDetail = this.handleSeeDetail.bind(this);
        this.handleRemove = this.handleRemove.bind(this);

        this.debounceGetUser = debounce(this.getUsers, 250);
    }

    getUsers() {
        this.setState({ loading: true});
   
        fetchApi(`/newsletters/getAll?page=${this.state.page}`)
            .then((res) =>
                    this.setState({ users: res.users, totalPage: res.countPages, loading: false, countUsers: res.countUsers })
            )
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees Utilisateur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getUsers();
    }

    updatePage(page) {
        this.setState({ users: [], page, loading: true }, () => {
            fetchApi(`/newsletters/getAll?page=${this.state.page}`)
                .then((res) => {
                    this.setState({ users: res.users, totalPage: res.countPages, loading: false, countUsers: res.countUsers });
                })
                .catch((err) =>
                    notif.addNotification(
                        'Erreur',
                        'Error while getting the users: ' + err,
                        'warning'
                    )
                );
        });
    }

    sortInt(sortKey, sortSecond) {
        const users = this.state.users;

        if (this.state.sort) {
            users.sort((a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]);
        } else {
            users.sort((a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]);
        }

        this.setState({ users: users, sort: !this.state.sort });
    }

    onSort(sortKey, sortSecond) {
        if (sortSecond) {
            const users = this.state.users;

            if (this.state.sort) {
                users.sort((a, b) =>
                    a[sortKey][sortSecond].localeCompare(b[sortKey][sortSecond])
                );
            } else {
                users.sort((a, b) =>
                    b[sortKey][sortSecond].localeCompare(a[sortKey][sortSecond])
                );
            }
        } else {
            const data = this.state.users;
            if (this.state.sort) {
                data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
                this.setState({ sort: false });
            } else {
                data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
                this.setState({ sort: true });
            }
            this.setState({ users: data });
        }
    }

    handleSeeDetail (email) {
        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });
        fetchApi(`/newsletters/findAnUserByEmail`, {
            method: 'post',
            body: JSON.stringify({
                email
            } )
        })
            .then(res => {
                if (res.users) {
                    this.props.history.push(
                        '/users/detail/' + res.users._id
                    )
                } else {
                    swalWithBootstrapButtons(
                        'Pas de compte',
                        "Utilisateur non enregistré",
                        'error'
                    );
                }
                
            })
            .catch(err => notif.addNotification(
                'Erreur',
                'Error while getting the users: ' + err,
                'warning'
            ))
        
    }
    handleRemove(email) {
        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Êtes vous sûr?',
            text: `Se désabonner à la newsletter pour cet utilisateur?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, valider!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi('/newsletters/delete', {
                    method: 'DELETE',
                    body: JSON.stringify({
                        email
                    })
                }).then(() => {
                   this.getUsers();

                    swalWithBootstrapButtons(
                        'Se désabonner!',
                        'Utilisateur se désabonne à la newsletter',
                        'success'
                    );
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons(
                    'Annule',
                    'Utilisateur en securite :)',
                    'error'
                );
            }
        });
    }

    exportToExcel() {
        
        this.setState({ isExporting: true }, () => {
            fetchApi(`/newsletters/getExcelData`, {
            method: 'POST',
            body: JSON.stringify({
                start: isoDate(this.state.startDate, 0, 1),
                end: isoDate(this.state.endDate, 23, 59),
            })
        })
            .then((res) => {

                const toExport = res.users.map((v) => ({
                    'Prénom': v.FName,
                    'Nom': v.LName,
                    'Email': v.email,
                    "Date d'inscription": new Date(v.createdAt).toLocaleDateString(),
                    "Nombre de request": v.request ? v.request.length : 0
                }));

                const cls = [
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                ]

                this.setState({ isExporting: false, showExportModal: false }, () => {
                    notif.addNotification(
                        'Succes !',
                        '',
                        'success'
                    )
                    exportAsExcel(toExport, `newsletters_${new Date().toISOString()}`, cls);
                } );
                    
            })
            .catch((err) => {
                console.log(err)
                this.setState({ isExporting: false, showFilterModal: false }, () => {
                    notif.addNotification(
                        'Erreur',
                        'Error while getting the users: ' + err,
                        'warning'
                    );
                });
            });
        });
    }

    exportModal() {
        return (
            <Modal show={this.state.showExportModal}>
                <Modal.Header>
                    <Modal.Title>Exporter en excel les newsletters</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div>
                        De : <input 
                                type="date" 
                                value={this.state.startDate} 
                                onChange={(e) => this.setState({ startDate: e.target.value })} 
                                max={this.state.endDate || new Date().toISOString().split('T')[0]}
                            />
                    </div>
                    <div style={{ margin: '20px 0' }}>
                       au : <input 
                                type="date" 
                                value={this.state.endDate} 
                                onChange={(e) => this.setState({ endDate: e.target.value })} 
                                min={this.state.startDate}
                            />
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ showExportModal: false })}>Fermer</Button>
                    <Button disabled={this.state.isExporting} onClick={() => this.exportToExcel()} bsStyle="primary">{this.state.isExporting ? 'Chargement ...' : 'Valider'}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    render() {
        if (!this.state.users) {
            return <div>Récupération des données en cours...</div>;
        }
        const page = this.state.page;

        return (
            <div className="pT-25">
                {this.exportModal()}
                <Row>
                    <Col md={12}>
                        <NewslettersCounter />
                        <Panel>
                            <Panel.Heading>
                                <h6>Newsletters</h6>
                            </Panel.Heading>
                            <Panel.Body>
                            <div style={{ margin: '10px 0' }}>
                                        <button 
                                            className="button-export" 
                                            onClick={() => this.setState({ showExportModal: true })}
                                            disabled={this.state.showExportModal}
                                        >
                                            Exporter les emails en excel
                                        </button>
                                    </div>
                                <div>
                                    <p>
                    Liste des utilisateurs (Page <strong>{page}</strong> /{' '}
                                        {this.state.totalPage})
                                    </p>
                                    {page > 1 && (
                                        <IconTooltip
                                            icon="fa fa-chevron-left"
                                            text="Page précédente"
                                            onClick={() => this.updatePage(page - 1)}
                                        />
                                    )}
                                    {page < this.state.totalPage && (
                                        <IconTooltip
                                            icon="fa fa-chevron-right"
                                            text="Page suivante"
                                            onClick={() => this.updatePage(page + 1)}
                                        />
                                    )}
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0">
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('FName')}
                                                >
                                                    Utilisateur
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('createdAt')}
                                                >
                                                    Date d'inscription
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.users.map((d) => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.email}
                                                    </td>
                                                    <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-user"
                                                            text="Voir le profil"
                                                            onClick={() => this.handleSeeDetail(d.email) }
                                                        />
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Se désabonner"
                                                            onClick={() => this.handleRemove(d.email)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
