import React, {useState, useEffect} from 'react';
import {  Panel, Row, Col } from 'react-bootstrap';
import isoDate from '../../Services/toIsoDate.js';
import { notif } from './Notification';
import { fetchApi } from '../../Network/NetworkService.jsx';


import dateFormat from 'dateformat';
import IconTooltip from './IconTooltip';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ReactEcharts from 'echarts-for-react';

const sources = ['google', 'skycall', 'skycall-voice', 'skycall-api', 'crechmed', 'naturel', 'bing', 'medifil', 'gescall', , 'cortel', 'pharmacy'];

export const TrackingWaiting = props => {
    const [waitingRequests, setWaitingRequests] = useState([]);
    const [waitingRequestsCompare, setWaitingRequestsCompare] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [comparison, setComparison] = useState({value: 'last-month', label: 'Mois précédent'});
    const [pathology, setPathology] = useState({});
    const [motifs, setMotifs] = useState({});
    const [total, setTotal] = useState(0)

    const setOptions = (valueDatas, nameDatas) => {
        let option = {
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            series: [
                {
                  name: '',
                  type: 'pie',
                  radius: '50%',
                  data: [],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
        };
        if (!valueDatas) {
            return option;
        }
       const datas = valueDatas.map((el, i) => {
        return {value: el, name: nameDatas[i]}
    })
   
        option.series[0].data = datas
       return option;
    };

    const getLogsWaiting = () => {
        setWaitingRequests([]);
        setWaitingRequestsCompare([]);
        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }
       const apis = [fetchApi(`/requests/analytics/getLogs?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}&state=waiting${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}`),
                     fetchApi(`/requests/analytics/getLogs?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}&state=waiting${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}`),
                     fetchApi(`/requests/count`, {
                        method: 'POST',
                        body: JSON.stringify({
                            start: isoDate(startDate, 0, 0),
                            end: isoDate(endDate, 23, 59),
                        })
                    })];
        Promise.all(apis)
            .then(responses => {
                let pathologyObj = {};
                responses[0].requests.map(el => {
                    if (pathologyObj[el.type]) {
                        pathologyObj[el.type]++;
                    } else {
                        pathologyObj[el.type] = 1
                    };

                   
                });     
                
                let motifsArr = [];
                // responses[0].requests.map(el => {
                //     if (el.state !=="incomplete" && el.decisionTree && el.decisionTree.questionOne) {
                //         motifsArr.push(el.decisionTree.questionOne);
                //     }
                // });

                // let douleurs = motifsArr.filter(el => el.includes('douleurs') || el.includes('douleur') || el.includes('mal') || el.includes('maux') || el.includes('malade') || el.includes('maladie') || el.includes('vomissement') || el.includes('vomir') || el.includes('toux') || el.includes('grippe') || el.includes('grippal') || el.includes('grippaux') || el.includes('fièvre') || el.includes('rhume')  ).length
                // let arretDeTravail = motifsArr.filter(el => el.includes('arrêt de travail') || el.includes('arret de travail')).length;
               
                setWaitingRequests(responses[0].requests);
                setPathology(pathologyObj);    
                // setMotifs({douleurs, arretDeTravail});

                setTotal(responses[2].count);
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            })
    }

    const filterSource = (arr, source) => {
        const data = [...arr];
        if (source === 'total') {
            return data.length;
        } else if (source === "google" || source === "bing" || source === "gescall" || source === "allowin" || source === "medifil" || source === "cortel") {
            return data.filter(el=>el.utm_source === source).length
        } else if (source === "skycall") {
            return data.filter(el=> !el.createdBySkycall && el.utm_source === "skycall" && !el.utm_content?.includes("voice")).length;
        } else if (source === "skycall-voice") {
            return data.filter(el=>(el.utm_source === "skycall" && el.utm_content?.includes("voice")) || el.skycallVoice).length;
        } else if (source === "skycall-api") {
            return data.filter(el=> el.createdBySkycall === true && el.utm_source === "skycall-api").length;
        } else if (source === "naturel") {
            return data.filter(el=>el.utm_source === null && el.utm_pharmacy === null).length;
        } else if (source === "pharmacy") {
            return data.filter(el => el.utm_pharmacy && el.utm_pharmacy.includes('pharmacy')).length;
        }
    }

    useEffect(() => {
        getLogsWaiting();
    }, []);

    return (
        <div className='r-analytics'>
        <Panel>
            <Panel.Heading className='heading'>
                <h2>Tracking Waiting</h2>
                <div>
                    <h6>Compteur du {' '}</h6>
                        <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                         <button className='submit-button' onClick={() => {
                        getLogsWaiting();
                    }}>Chercher</button>
                </div>
               
                <div>          
                </div>
            </Panel.Heading>
            <Panel.Body>
                <div>
                    <h1>Total: {waitingRequests.length}<span>{'  '}({(waitingRequests.length / total * 100).toFixed(2)} %)</span></h1>
                </div>
                <div>
                    <h1>Par pathologie</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <tbody>
                                    {Object.keys(pathology).map(p => (
                                        <tr key={p}>
                                            <td>{p}</td>
                                            <td>{waitingRequests.length > 0 && (pathology[p] / waitingRequests.length *100).toFixed(2)} %</td>
                                        </tr>
                                    )
                                    )}       
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions(Object.keys(pathology).map(el => pathology[el] / waitingRequests.length), Object.keys(pathology))}
                                style={{ height: '350px', width: '100%' }}
                            />  
                        </Col>
                    </Row>
                </div>

                <div>
                    <h1>Par source d'acquisition</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <tbody>
                                    {
                                        sources.map(source =>  <tr>
                                            <td>{source}</td>
                                            <td>{((filterSource(waitingRequests, source) / waitingRequests.length) * 100).toFixed(2)} %</td>
                                        </tr>)
                                    }
                                       
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions(
                                    sources.map(source => filterSource(waitingRequests, source) / waitingRequests.length),
                                   [...sources]
                                )}
                                style={{ height: '350px', width: '100%' }}
                            />  
                        </Col>
                    </Row>
                </div>

                {/* <div>
                    <h1>Par Symtome</h1>
                    <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <tbody>
                                    <tr>
                                        <td>Douleurs/fièvre/vomissement/toux/grippe</td>
                                        <td>{(motifs.douleurs/waitingRequests.length *100).toFixed(2)} %</td>
                                    </tr>
                                    <tr>
                                        <td>Arrêt de travail</td>
                                        <td>{(motifs.arretDeTravail/waitingRequests.length *100).toFixed(2)} %</td>
                                    </tr>
                                    <tr>
                                        <td>Autres</td>
                                        <td>{((1 - motifs.douleurs/waitingRequests.length - motifs.arretDeTravail/waitingRequests.length)*100).toFixed(2)} %</td>
                                    </tr>
                                       
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions(
                                    [motifs.douleurs/waitingRequests.length, motifs.arretDeTravail/waitingRequests.length, 1 - motifs.douleurs/waitingRequests.length - motifs.arretDeTravail/waitingRequests.length],
                                    ['Douleurs/fièvre/vomissement/toux/grippe', 'Arrêt de travail', 'Autres']
                                )}
                                style={{ height: '350px', width: '100%' }}
                            />  
                        </Col>
                    </Row>
                    
                </div> */}

                <div>
                    <h1>Détail</h1>
                    <table className="table table-hover mb-0"> 
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Email</th>
                                <th>Date de creation</th>
                                <th>Type</th>
                                <th>Source</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {waitingRequests.map((r) => (
                                <tr key={r._id}>
                                    <td>
                                        {r.userId?.FName} {r.userId?.LName}
                                    </td>
                                    <td>{r.userId?.email}</td>
                                    <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                                    <td>{r.type}</td>
                                    <td>{r.utm_source}</td>
                                    <td>
                                        <IconTooltip
                                            icon="fas fa-file-medical"
                                            text="Details"
                                            onClick={() =>
                                                props.history.push(
                                                    '/requests/detail/' + r._id
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Panel.Body>
        </Panel>
        </div>
    );
};
  
