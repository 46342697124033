import { endpoint } from '../Constants';
import AuthService from '../Services/AuthService';

const Auth = new AuthService();

export const fetchApi = (url, options) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': Auth.getToken()
    };

    return fetch(`${endpoint}${url}`, { ...options, headers })
        .then(res => res.json())
        .then(res => {
            if (res.success === false) {
                return Promise.reject(res.message);
            }

            return res;
        })
        .catch(err => {
            console.error('Fetch api:', err.message);
            return Promise.reject(err);
        });
};
