import React, {useState, useEffect} from 'react';
import {  Panel, Row, Col } from 'react-bootstrap';
import { notif } from './Notification';
import { fetchApi } from '../../Network/NetworkService.jsx';
import ReactEcharts from 'echarts-for-react';
import Select from 'react-select';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.js';
import { comparisonArr, priorityStates, typeStates } from '../../Constants.jsx';
import isoDate from '../../Services/toIsoDate.js';
import { LoadingModal } from '../Common/LoadingModal.jsx';

export const rqState = [
    { value: 'approved', label: 'Approuvé' },
    { value: 'reviewed', label: 'Examiné' },
    { value: 'notOnline', label: 'Impossible à distance' },
    { value: 'terminated', label: 'Terminé' },
    { value: 'refunded', label: 'Remboursé' },
    { value: 'toCall', label: 'A appeler' },
    { value: 'canceled', label: 'Annulé' },
    { value: 'canceledByDoctor', label: 'Annulé par le docteur'  }
]


export const CohortDoctor = props => {
    const [datas, setDatas] = useState(null)
    const [doctor, setDoctor] = useState(null);
    const [doctorStats, setDoctorStats] = useState([]);
    const [comparison, setComparison] = useState( {value: 'yesterday', label: "Jour précédent"} );
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [priorityState, setPriorityState] = useState(null);
    const [displayedDatas, setDisplayedDatas] = useState([]);
    const [displayedDatasCompare, setDisplayedDatasCompare] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [typeState, setTypeState] = useState([]);
    const [totalRequestByDoctor, setTotalRequestByDoctor] = useState(0);
    const [state, setState] = useState([]);

    const getDoctors = () => {
        setTotal(-1);
        setDisplayedDatas([]);
        setLoading(true);

        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;

        if (comparison && comparison.value === 'yesterday') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 1));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -1));
        };
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }
       const apis = [fetchApi(`/doctors/analytics/all?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}`),
                     fetchApi(`/doctors/analytics/all?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}`)];
        Promise.all(apis)
            .then(responses => {
                //setTotal( responses[0].countRequests);
                setDisplayedDatas(responses[0].requests);
                setDisplayedDatasCompare(responses[1].requests);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    };

    const setOptions = (valueDatas, nameDatas) => {
        let option = {
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            series: [
                {
                  name: '',
                  type: 'pie',
                  radius: '50%',
                  data: [],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                }
              ]
        };
        if (!valueDatas) {
            return option;
        }
       const datas = valueDatas.map((el, i) => {
        return {value: el, name: nameDatas[i]}
    })
   
        option.series[0].data = datas
       return option;
    };

    const getDoctorStats = () => {
        fetchApi(`/doctors/analytics/stats?start=${isoDate(range[0], 0, 0)}&end=${isoDate(range[1], 24, 59)}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}`)
            .then(res => {
                setDatas(res.stats);
                setTotal(res.totalRequest);
            })
            .catch((err) => {
                console.log(err)
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            });
    }

    useEffect(() => {
       getDoctorStats();
       getDoctors()
    }, []);

    const getADoctor = (id) => {
        fetchApi(`/doctors/analytics/specificDoctorStats?doctorId=${id}&start=${isoDate(range[0], 0, 0)}&end=${isoDate(range[1], 24, 59)}${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}${priorityState ? `&priorityState=${priorityState.value}` : ''}${state.length > 0 ? `&state=${state.map((v) => v.value).toString()}` : ''}`)
            .then((res) => {
                setDoctorStats(res.stats);
                setTotalRequestByDoctor(res.totalRequest);
                const doctorElement= document.getElementById("doctor-detail");
                doctorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees request: ' + err,
                    'warning'
                )
            });
    }


    const handleGetADoctor = (doctor) => {
        setDoctor(doctor);
        getADoctor(doctor._id);
    }

    if (!datas) {
        return <p>Loading...</p>
    }
    return (
        <div className='doctor-analytics r-analytics'>
            {loading && <LoadingModal isShow={loading} />}
            <Panel className='by-month'>
                <Panel.Heading className='heading'>
                    <h1>Analyses des médecins</h1>
                    <div>
                    <h6>Compteur du {' '} </h6>
                    <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>

                    {/* <h6>{' '} Par rapport {' '}</h6>
                    <Select
                        value={comparison}
                        onChange={(v) => setComparison(v)}
                        options={comparisonArr}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        className='filter-status'
                    />
                    {
                        comparison.value === 'personalized' &&
                        <DateRangePicker onChange={value => setRangeCompare(value)} value={rangeCompare} maxDate={new Date()}/>
                    }    */}
                </div>
                    <div>
                    <Select
                        value={state}
                        onChange={(v) => setState(v)}
                        options={rqState}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par statut ..." 
                        className='filter-status'
                    />
                    
                    <Select
                        value={typeState}
                        onChange={(v) => setTypeState(v)}
                        options={typeStates}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par pathologie ..." 
                        className='filter-status'
                    />
                    <Select
                        value={priorityState}
                        onChange={(v) => {
                           setPriorityState(v)
                        }}
                        options={priorityStates}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black',
                        })}}
                        role="checkbox"
                        placeholder="Filtre consultation urgente ..." 
                        className='filter-status'
                    />
                    <button className='submit-button' onClick={() => {
                       getDoctorStats();
                       getDoctors();
                    }}>Chercher</button>
                </div>
                </Panel.Heading>
                <Panel.Body>
                    <h2>Total consultations traitées: <strong>{total}</strong></h2>
                    <h2>Total médecins disponibles: <strong>{datas.length}</strong></h2>
                 
                    {
                    datas.length > 0 && 
                    <div className='source-table'>
                        <h1>Par médecin</h1>
                        <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <thead>
                                    <tr>
                                        <th>Docteur</th>
                                        <th>Nombre</th>
                                        <th>Taux</th>
                                        <th>Voir détail</th>
                                    </tr>
                                   
                                    
                                </thead>
                                <tbody>
                                    {
                                        datas.sort((a,b) => b.count - a.count).map(el => <tr key={el._id._id}>
                                            <td>{`${el?._id?.LName} ${el?._id?.FName}`}</td>
                                            <td>{el.count}</td>
                                            <td>{((el.count /total*100)).toFixed(2)} %</td>
                                            <td style={{cursor: 'pointer'}} onClick={(e)=> {
                                                e.preventDefault();
                                                handleGetADoctor(el._id);
                                                }}>Voir</td>
                                        </tr>)
                                    }
                                
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions([...datas.map(el => el.count/total)], [...datas.map(el => `${el?._id?.LName} ${el?._id?.FName}`)])}
                                style={{ height: '300px', width: '100%' }}
                            />  
                        </Col> 
                    </Row>
                        
                    </div>
                }

                {
                    doctor && 
                    <div className='source-table' id="doctor-detail">
                        <h1>Analyses par pathologie pour Dr. {doctor.LName} {doctor.FName}</h1>
                        <Row>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <table className="table table-hover mb-0 analytic-table">
                                <thead>
                                    <tr>
                                        <th>Pathologie</th>
                                        <th>Nombre</th>
                                        <th>Taux</th>
                                    </tr>
                                   
                                    
                                </thead>
                                <tbody>
                                    {
                                        doctorStats.map(el => <tr key={el._id._id}>
                                            <td>{el._id.type}</td>
                                            <td>{el.count}</td>
                                            <td>{((el.count /totalRequestByDoctor*100)).toFixed(2)} %</td>
                                        </tr>)
                                    }
                                
                                </tbody>
                            </table>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <ReactEcharts
                                option={setOptions([...doctorStats.map(el => el.count/totalRequestByDoctor)], [...doctorStats.map(el => el?._id?.type)])}
                                style={{ height: '300px', width: '100%' }}
                            />  
                        </Col> 
                    </Row>
                        
                    </div>
                }
                    

                </Panel.Body>
            </Panel>

           
        </div>
    );
};
  
