import React from 'react';

import '../../Assets/Ui/IconTooltip.css';

export default class IconTooltip extends React.Component {
    constructor() {
        super();

        this.state = {
            open: false,
            init: false
        };
    }

    render() {
        return (
            <div className="IconTooltip" onClick={this.props.onClick}>
                <div
                    className={`tooltip ${
                        this.state.init
                            ? this.state.open
                                ? ''
                                : 'fadeOut'
                            : 'hidden'
                    }`}
                >
                    {this.props.text}
                </div>
                <i
                    className={this.props.icon}
                    onMouseEnter={() =>
                        this.setState({ open: true, init: true })
                    }
                    onMouseLeave={() => this.setState({ open: false })}
                />
            </div>
        );
    }
}
