import React, {useState, useEffect} from 'react';
import {  Panel } from 'react-bootstrap';
import Select from 'react-select';
import isoDate from '../../Services/toIsoDate.js';
import { notif } from '../Ui/Notification';
import { fetchApi } from '../../Network/NetworkService.jsx';


import dateFormat from 'dateformat';
import IconTooltip from './IconTooltip';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';


const typeStates = [
    { value: 'acne', label: 'acne' },
    { value: 'asthme', label: 'asthme' },
    { value: 'cardiologie', label: 'cardiologie' },
    { value: 'conjonctivite', label: 'conjonctivite' },
    { value: 'contraception', label: 'contraception' },
    { value: 'covid19', label: 'covid19' },
    { value: 'cystite', label: 'cystite' },
    { value: 'dentiste', label: 'dentiste' },
    { value: 'dermatologie', label: 'dermatologie' },
    { value: 'douleurs-de-dos', label: 'douleurs-de-dos' },
    { value: 'dysfonctionnement-erectile', label: 'dysfonctionnement-erectile' },
    { value: 'generaliste', label: 'generaliste' },
    { value: 'gynecologie', label: 'gynecologie' },
    { value: 'hemorroides', label: 'hemorroides' },
    { value: 'herpes', label: 'herpes' },
    { value: 'infection urinaire', label: 'infection urinaire' },
    { value: 'kit-voyage-et-vaccination', label: 'kit-voyage-et-vaccination' },
    { value: 'maladie-sexuelle', label: 'maladie-sexuelle' },
    { value: 'ophtalmologie', label: 'ophtalmologie' },
    { value: 'pediatrie', label: 'pediatrie' },
    { value: 'psychologie', label: 'psychologie' },
    { value: 'renouvellement-ordonnance', label: 'renouvellement-ordonnance' },
    { value: 'test-de-grossesse', label: 'test-de-grossesse' },
];

const comparisonArr = [
    {value: 'last-month', label: "Mois précédent"},
    {value: 'last-year', label: "Année précédente"},
    {value: 'personalized', label: "Personnalisé"},
];

export const TrackingIncomplete = props => {
    const [incompleteRequests, setIncompleteRequests] = useState([]);
    const [incompleteRequestsCompare, setIncompleteRequestsCompare] = useState([]);
    const [typeState, setTypeState] = useState([]);
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [comparison, setComparison] = useState({value: 'last-month', label: 'Mois précédent'});
    const [pathology, setPathology] = useState({});
    const [step, setStep] = useState({});
    const [pathologyCompare, setPathologyCompare] = useState({});
    const [stepCompare, setStepCompare] = useState({});
    const [sources, setSources] = useState({});

    const getLogsIncomplete = () => {
        setIncompleteRequests([]);
        setIncompleteRequestsCompare([]);
        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }
       const apis = [fetchApi(`/requests/analytics/getLogs?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}&state=incomplete${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}`),
                     fetchApi(`/requests/analytics/getLogs?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}&state=incomplete${typeState.length > 0 ? `&typeState=${typeState.map((v) => v.value).toString()}` : ''}`)];
        Promise.all(apis)
            .then(responses => {
                let pathologyObj = {};
                let stepObj = {};
                let sourceObj = {};
                responses[0].requests.map(el => {
                    if (pathologyObj[el.type]) {
                        pathologyObj[el.type]++;
                    } else {
                        pathologyObj[el.type] = 1
                    };

                    if (stepObj[el.incompleteTracking]) {
                        stepObj[el.incompleteTracking]++;
                    } else {
                        stepObj[el.incompleteTracking] = 1;
                    };

                    if (sourceObj[el.utm_source] ) {
                        sourceObj[el.utm_source]++;
                    } else {
                        sourceObj[el.utm_source] = 1
                    } 
                });

                let pathologyCompareObj = {};
                let stepCompareObj = {};
                Object.keys(pathologyObj).map(path => pathologyCompareObj[path] = responses[1].requests.filter(el=>el.type === path).length);
                Object.keys(stepObj).map(s => stepCompareObj[s] = responses[1].requests.filter(el=>el.incompleteTracking == s).length);
               
                setIncompleteRequests(responses[0].requests);
                setPathology(pathologyObj);
                setStep(stepObj);
                setSources(sourceObj);

                setIncompleteRequestsCompare(responses[1].requests);
                setPathologyCompare(pathologyCompareObj);
                setStepCompare(stepCompareObj);
            })
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees: ' + err,
                    'warning'
                )
            })
    }

    useEffect(() => {
        getLogsIncomplete();
    }, []);

    return (
        <div className='r-analytics'>
        <Panel>
            <Panel.Heading className='heading'>
                <h2>Tracking Incomplète</h2>
                <div>
                    <h6>Compteur du {' '}</h6>
                    <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                    <h6>Par rapport {' '}   </h6>  
                    <Select
                            value={comparison}
                            onChange={(v) => setComparison(v)}
                            options={comparisonArr}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            className='filter-status'
                        />
                        {
                            comparison.value === 'personalized' &&
                            <DateRangePicker onChange={value => setRangeCompare(value)} value={rangeCompare} maxDate={new Date()}/>
                        }              
                </div>
                <div>
                    <Select
                        value={typeState}
                        onChange={(v) => setTypeState(v)}
                        options={typeStates}
                        isMulti
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Filtre par pathologie ..." 
                        className='filter-status'
                    />
                    <button className='submit-button' onClick={() => {
                        getLogsIncomplete();
                    }}>Chercher</button>
                </div>
            </Panel.Heading>
            <Panel.Body>
                <div>
                    <h1>Total: {incompleteRequests.length}<span>({incompleteRequestsCompare.length})</span></h1>
                </div>
                <div>
                    <h1>Par étape</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(step).map(p => <th colSpan={2} key={p}>Étape {Number(p) +1}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(step).map(p => [
                                    <th key={`n-${p}`}>Nombre</th>,
                                    <th key={`t-${p}`}>Taux</th>,
                                ])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                {Object.keys(step).map(p => [
                                    <td key={`v-${p}`}>{step[p]}</td>,
                                    <td key={`p-${p}`}>{incompleteRequests.length > 0 ? (step[p] / incompleteRequests.length *100).toFixed(2): 0} %</td>
                                ] )}  
                            </tr>   
                            <tr className='compare'>
                                {Object.keys(stepCompare).map(p => [
                                    <td key={`vc-${p}`}>{stepCompare[p]}</td>,
                                    <td key={`pc-${p}`}>{incompleteRequestsCompare.length > 0 ? (stepCompare[p] / incompleteRequestsCompare.length *100).toFixed(2) : 0} %</td>
                                ] )}  
                            </tr>   
                          
                        </tbody>
                    </table>

                </div>

                <div>
                    <h1>Par source</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(sources).map(p => <th colSpan={2} key={p}>{p === 'null' ? 'Naturel' : p}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(sources).map(p => [
                                    <th key={`n-${p}`}>Nombre</th>,
                                    <th key={`t-${p}`}>Taux</th>,
                                ])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                {Object.keys(sources).map(p => [
                                    <td key={`v-${p}`}>{sources[p]}</td>,
                                    <td key={`p-${p}`}>{incompleteRequests.length > 0 ? (sources[p] / incompleteRequests.length *100).toFixed(2): 0} %</td>
                                ] )}  
                            </tr>   
                          
                        </tbody>
                    </table>

                </div>

                <div>
                    <h1>Par pathologie</h1>
                    <table className="table table-hover mb-0 analytic-table">
                        <thead>
                            <tr>
                                {Object.keys(pathology).map(p => <th colSpan={2} key={p}>{p}</th>)}
                            </tr>
                            <tr>
                                {Object.keys(pathology).map(p => [
                                    <th key={`n-${p}`}>Nombre</th>,
                                    <th key={`t-${p}`}>Taux</th>,
                                ])}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='data'>
                                {Object.keys(pathology).map(p => [
                                    <td key={`v-${p}`}>{pathology[p]}</td>,
                                    <td key={`p-${p}`}>{incompleteRequests.length > 0 && (pathology[p] / incompleteRequests.length *100).toFixed(2)} %</td>
                                ] )}  
                            </tr> 
                            <tr className='compare'>
                                {Object.keys(pathologyCompare).map(p => [
                                    <td key={`vc-${p}`}>{pathologyCompare[p]}</td>,
                                    <td key={`pc-${p}`}>{incompleteRequestsCompare.length > 0 && (pathologyCompare[p] / incompleteRequestsCompare.length *100).toFixed(2)} %</td>
                                ] )}  
                            </tr>   
                        </tbody>
                    </table>
                </div>

               

                <div>
                    <h1>Détail</h1>
                    <table className="table table-hover mb-0"> 
                        <thead>
                            <tr>
                                <th>Utilisateur</th>
                                <th>Email</th>
                                <th>Date de creation</th>
                                <th>Type</th>
                                <th>Source</th>
                                <th>Étape</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {incompleteRequests.map((r) => (
                                <tr key={r._id}>
                                    <td>
                                        {r.userId?.FName} {r.userId?.LName}
                                    </td>
                                    <td>{r.userId?.email}</td>
                                    <td>{dateFormat(r.createdAt, 'dd/mm/yy HH:MM')}</td>
                                    <td>{r.type}</td>
                                    <td>{r.utm_source}</td>
                                    <td>{r.incompleteTracking + 1}</td>
                                    <td>
                                        <IconTooltip
                                            icon="fas fa-file-medical"
                                            text="Details"
                                            onClick={() =>
                                                props.history.push(
                                                    '/requests/detail/' + r._id
                                                )
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Panel.Body>
        </Panel>
        </div>
    );
};
  
