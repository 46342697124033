import React from 'react';
import { Row, Col, Panel, Modal, Button } from 'react-bootstrap';
import dateFormat from 'dateformat';
import Swal from 'sweetalert2';
import Select from 'react-select';

import '../../Assets/Pages/pharmacist.css'

import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import IconTooltip from '../Ui/IconTooltip';

export default class Pharmacist extends React.Component {
    constructor() {
        super();

        this.state = {
            phcist: [],
            totalPage: 0,
            sort: true,
            page: 1,
            isLoading: true,
            isModify: false,
            pharmacy: [],
            selectValue: {}
        };

        this.swal =  Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        this.handleRemove = this.handleRemove.bind(this);
        this.onSort = this.onSort.bind(this);
        this.sortInt = this.sortInt.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    getPharmacists() {
        fetchApi(`/pharmacist`)
            .then((res) => this.setState({ phcist: res.pharmacists, totalPage: res.countPages, isLoading: false }))
            .catch((err) =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }

    componentDidMount() {
        this.getPharmacie();
        this.getPharmacists();
        
    }

    updatePage(page) {
        this.setState({ page }, () => {
            fetchApi(`/pharmacist?page=${page}`)
                .then((res) => {
                    this.setState({ phcist: res.pharmacists, totalPage: res.countPages });
                })
                .catch((err) =>
                    notif.addNotification(
                        'Erreur',
                        'Error while getting the users: ' + err,
                        'warning'
                    )
                );
        });
    }

    handleRemove(id) {
        const toRemove = this.state.phcist.find(({ _id }) => _id === id);

        const swalWithBootstrapButtons = Swal.mixin({
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            buttonsStyling: false
        });

        swalWithBootstrapButtons({
            title: 'Etes vous sur?',
            text: `Voulez vous vraiment supprimer ${toRemove.name} ? Cette operation est irreversible!`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer!',
            cancelButtonText: 'Non, annuler!',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                fetchApi(`/pharmacist/${id}`, {
                    method: 'DELETE',
                    body: JSON.stringify({
                        id: toRemove._id
                    })
                }).then(() => {
                    this.setState({
                        phcist: this.state.phcist.filter(({ _id }) => _id !== id)
                    });

                    swalWithBootstrapButtons('Supprime!', 'Pharmacist supprime', 'success');
                });
            }
        });
    }

    sortInt(sortKey, sortSecond) {
        const phcist = this.state.phcist;

        if (this.state.sort) {
            phcist.sort((a, b) => a[sortKey][sortSecond] - b[sortKey][sortSecond]);
        } else {
            phcist.sort((a, b) => b[sortKey][sortSecond] - a[sortKey][sortSecond]);
        }

        this.setState({ phcist, sort: !this.state.sort });
    }

    onSort(sortKey) {
        const data = this.state.phcist;
        if (this.state.sort) {
            data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
            this.setState({ sort: false });
        } else {
            data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
            this.setState({ sort: true });
        }
        this.setState({ phcist: data });
    }

    updatePharmacy() {
        fetchApi(`/pharmacist/${this.state.selectValue.toUpdate}/pharmacy`, {
            method: 'PUT',
            body: JSON.stringify({
                pId: this.state.selectValue.value.value
            })
        }).then(() => {
            this.setState({ isModify: false, selectValue: {} }, () => {
                this.getPharmacists();
                notif.addNotification('Success !', 'Pharmacist modifie !');
            })

        });
    }

    handleAdd() {
        this.swal({
            title: 'Ajouter un pharmacien',
            confirmButtonText: 'Ajouter',
            cancelButtonText: 'Annuler',
            showCancelButton: true,
            reverseButtons: true,
            html: `<input type="text" id="name" class="swal2-input" placeholder="Nom ...">
            <input type="text" id="login" class="swal2-input" placeholder="Login ...">
            <input type="text" id="speciality" class="swal2-input" placeholder="Specialite ...">
            <input type="password" id="password" class="swal2-input" placeholder="Mot de passe ...">`,
            preConfirm: () => {
                const name = Swal.getPopup().querySelector('#name').value;
                const login = Swal.getPopup().querySelector('#login').value;
                const speciality = Swal.getPopup().querySelector('#speciality').value;
                const password = Swal.getPopup().querySelector('#password').value;

                if (!name || !login || !speciality || !password) {
                    Swal.showValidationMessage(`Veuillez remplir tout les champs !`);
                }
                return { name, login, speciality, password };
            }
        }).then((res) => {

            fetchApi('/pharmacist/create', {
                method: 'POST',
                body: JSON.stringify({
                    username: res.value.login,
                    name: res.value.name,
                    speciality: res.value.speciality,
                    password: res.value.password
                })
            }).then(() => {
                this.getPharmacists();
                notif.addNotification('Success !', 'Pharmacien ajoute !');
            })
                .catch(() => notif.addNotification('Erreur !', 'Pharmacien non ajoute !'));
        });
    }

    modifyPharmacyModal() {

        return (
            <Modal show={this.state.isModify}>
                <Modal.Header>
                    <Modal.Title>Selectionner une pharmacie</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Select
                        value={this.state.selectValue.value}
                        onChange={(v) => this.setState({ selectValue: { ...this.state.selectValue, value: v } })}
                        options={this.state.pharmacy.map((v) => ({ value: v._id, label: v.name }))}
                        styles={{ option: (s) => ({
                            ...s,
                            color: 'black'
                        })}}
                        role="checkbox"
                        placeholder="Choisir :" 
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.setState({ isModify: false, selectValue: {} })}>Close</Button>
                    <Button onClick={() => this.updatePharmacy()} bsStyle="primary">Valider</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    getPharmacie() {
        fetchApi('/pharmacy/all')
            .then((res) => this.setState({ pharmacy: res.pharmacies }))
            .catch((err) => {
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des pharmacies: ' + err,
                    'warning'
                );
            });
    }

    render() {
        return (
            <div className="pharmacists">
                <button onClick={this.handleAdd} className="add-btn" type="button">Ajouter</button>
                <Row>
                    <Col md={12}>
                        {this.modifyPharmacyModal()}
                        <Panel>
                            <Panel.Heading>
                                <h6>Pharmacistes</h6>
                            </Panel.Heading>
                            <Panel.Body>
                                <div>
                                    <p>
                                        Liste des pharmacistes (Page <strong>{this.state.page}</strong> /{' '}
                                        {this.state.totalPage})
                                    </p>
                                    {this.state.page > 1 && (
                                        <IconTooltip
                                            icon="fa fa-chevron-left"
                                            text="Page précédente"
                                            onClick={() => this.updatePage(this.state.page - 1)}
                                        />
                                    )}
                                    {this.state.page < this.state.totalPage && (
                                        <IconTooltip
                                            icon="fa fa-chevron-right"
                                            text="Page suivante"
                                            onClick={() => this.updatePage(this.state.page + 1)}
                                        />
                                    )}
                                </div>
                                <div className="mT-40" />
                                <div className="table-responsive">
                                    <table className="table table-hover mb-0" style={{ width: '90%'}}>
                                        <thead>
                                            <tr>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('name')}
                                                >
                                                Nom
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th
                                                    className="sortable"
                                                    onClick={() => this.onSort('createdAt')}
                                                >
                                                Date d'ajout
                                                    <i
                                                        className={
                                                            this.state.sort
                                                                ? 'zmdi zmdi-caret-down'
                                                                : 'zmdi zmdi-caret-up'
                                                        }
                                                    />
                                                </th>
                                                <th>Pharmacie</th>
                                                <th>Specialite</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.isLoading ? (
                                                <p>Chargement ...</p>
                                            ) : this.state.phcist.map((d) => (
                                                <tr key={d._id}>
                                                    <td>
                                                        {d.name}
                                                    </td>
                                                    <td>{dateFormat(d.createdAt, 'dd/mm/yy HH:MM')}</td>
                                                    <td>{d.pharmacyId ? d.pharmacyId.name : ''}</td>
                                                    <td>{d.speciality}</td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-plus-square"
                                                            text="Modifier la pharmacie"
                                                            onClick={() => this.setState({ isModify: true, selectValue: {
                                                                toUpdate: d._id,
                                                                value: d.pharmacyId ? this.state.pharmacy.filter((v) => v._id === d.pharmacyId._id).map((v) => ({ value: v._id, label: v.name })) : null
                                                            } })}
                                                        />
                                                    </td>
                                                    <td>
                                                        <IconTooltip
                                                            icon="fa fa-close danger"
                                                            text="Supprimer"
                                                            onClick={() => this.handleRemove(d._id)}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
