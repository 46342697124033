import React from 'react';

import '../../Assets/Ui/CardCount.css';

export const CardCount = props => (
    <div className="CardCount" style={{ backgroundColor: props.background, width: props.width }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 20px 10px 20px' }}>
            <div>
                <p className="subtitle">{props.subtitle}</p>
                <p className="textCounter">{props.number} {props.dif &&  <span>{`(${props.dif > 0 ? '+' : ''}${props.dif})`}</span>}  </p>
            </div>
            <div>
                <i className={props.icon} />
            </div>
        </div>
    </div>
);
