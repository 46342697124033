import React, { useEffect, useState } from 'react';
import { Panel, Row, Col } from 'react-bootstrap';
import { notif } from './Notification';
import { fetchApi } from '../../Network/NetworkService';
import '../../Assets/Ui/RequestCounter.css';
import isoDate from '../../Services/toIsoDate.js';
import ReactEcharts from 'echarts-for-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../../Assets/Ui/UserAnalytics.css';
import Select from 'react-select';
import { Modal } from 'react-bootstrap';

const getYear = () => {
    let year = [2019];
    let now = new Date().getFullYear();
    for (let i=0; year[i] < now; i++) {
        year.push(year[i] + 1)
    }
    return year.reverse();
};

const comparisonArr = [
    {value: 'last-month', label: "Mois précédent"},
    {value: 'last-year', label: "Année précédente"},
    {value: 'personalized', label: "Personnalisé"},
];

const UserAnalytics = (props) => {
    const [usersOfRange, setUsersOfRange] = useState(0);
    const [usersOfRangeNoRequest, setUsersOfRangeNoRequest] = useState(0);
    const [usersOfRangeCompare, setUsersOfRangeCompare] = useState(0);
    const [usersOfRangeNoRequestCompare, setUsersOfRangeNoRequestCompare] = useState(0);
    const [year, setYear] = useState(new Date().getFullYear());
    const [usersNoRequest, setUsersNoRequest] = useState([]);
    const [usersWithRequest, setUsersWithRequest] = useState([]);
    const [numberUsersNoRequest, setNumberUsersNoRequest] = useState(0);
    const [numberUsersWithRequest, setNumberUsersWithRequest] = useState(0);
    const [range, setRange] = useState([new Date(), new Date()]);
    const [rangeCompare, setRangeCompare] = useState([new Date(), new Date()]);
    const [comparison, setComparison] = useState({value: 'last-month', label: 'Mois précédent'});
    const [loading, setLoading] = useState(false);
    
    const countUsers = (yearInput) => {
        setUsersNoRequest([]);
        setUsersWithRequest([]);

        fetchApi(`/users/analytics/stats?start=${isoDate(`01/01/${yearInput}`, 0, 0)}&end=${isoDate(`${yearInput}/12/31`, 23, 59)}&numberR=no-request`)
        .then(res => {
            let users = res.stats.filter(el=>el._id?.year.toString() === yearInput.toString());
            setUsersNoRequest(users);
            setNumberUsersNoRequest(users.reduce((acc, val) => acc + val.count, 0));
        })
        .catch((err) =>
        notif.addNotification(
            'Erreur',
            'Erreur de recuperation des donnees Utilisateur: ' + err,
            'warning'
        )
        );
        fetchApi(`/users/analytics/stats?start=${isoDate(`01/01/${yearInput}`, 0, 0)}&end=${isoDate(`${yearInput}/12/31`, 23, 59)}&numberR=with-request`)
        .then(res => {
            let users = res.stats.filter(el=>el._id?.year.toString() === yearInput.toString());
            setUsersWithRequest(users);
            setNumberUsersWithRequest(users.reduce((acc, val) => acc + val.count, 0));
        })
        .catch((err) =>
            notif.addNotification(
                'Erreur',
                'Erreur de recuperation des donnees Utilisateur: ' + err,
                'warning'
            )
        );
    };
    const countUserOfToday = () => {
        setUsersOfRange(0);
        setUsersOfRangeNoRequest(0);
        setUsersOfRangeCompare(0);
        setUsersOfRangeNoRequestCompare(0);
        setLoading(true);

        let startDate = range[0];
        let endDate= range[1];
        let startDateCompare, endDateCompare;
        if (comparison && comparison.value === 'last-month') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() -30));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() -30));
        };
        if (comparison && comparison.value === 'last-year') {
            startDateCompare = new Date (new Date(range[0]).setDate(new Date(range[0]).getDate() - 365));
            endDateCompare = new Date (new Date(range[1]).setDate(new Date(range[1]).getDate() - 365));
        };
        if (comparison && comparison.value === 'personalized') {
            startDateCompare = rangeCompare[0];
            endDateCompare = rangeCompare[1];
        }
        const apis = [fetchApi(`/users/analytics?start=${isoDate(startDate, 0, 0)}&end=${isoDate(endDate, 23, 59)}`),
                    fetchApi(`/users/analytics?start=${isoDate(startDateCompare, 0, 0)}&end=${isoDate(endDateCompare, 23, 59)}`)]

        Promise.all(apis)
            .then((responses) => {
                let noRequest = responses[0].users.filter(el=>el.request?.length === 0).length;
                setUsersOfRange(responses[0].users?.length);
                setUsersOfRangeNoRequest(noRequest);

                let noRequestCompare = responses[1].users.filter(el=>el.request?.length === 0).length;
                setUsersOfRangeCompare(responses[1].users?.length);
                setUsersOfRangeNoRequestCompare(noRequestCompare);

                setLoading(false);
            })
            .catch((err) => {
                    setLoading(false);
                    notif.addNotification(
                        'Erreur',
                        'Erreur de recuperation des donnees Utilisateur: ' + err,
                        'warning'
                    );
                }
            );
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
        countUsers(e.target.value);
    };

    useEffect(()=>{
        countUserOfToday();
        countUsers(year);
    }, [])

    const getUsersOptions = () => {
        let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {},
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            yAxis: {
              type: 'value'
            },
            xAxis: {
              type: 'category',
              data: []
            },
            series: [
              {
                name: 'Aucune Requête',
                type: 'bar',
                stack: 'total',
                label: {
                  show: true,
                  formatter: value => value.data.percent + '%'
                },
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {color: '#b5b5b5'},
                data: []
              },
              {
                name: 'Avec Requête',
                type: 'bar',
                stack: 'total',
                label: {
                  show: true,
                  formatter: value => value.data.percent + '%'
                },
                emphasis: {
                  focus: 'series'
                },
                itemStyle: {color: '#54d2d2'},
                data: []
              }
            ]
          };
        if (usersNoRequest.length === 0 || usersWithRequest.length === 0) {
            return option;
        }
        const xAxisLegend = [
            ...new Set([
                ...usersNoRequest.map(
                    ({ _id }) => _id.month.toString() + '/' + _id.year.toString()
                )
            ])
        ];

        const dataTotal = usersNoRequest.map( (el, i) => {return {_id: el._id, count: el.count + usersWithRequest[i].count}})

        const dataNoRequest = usersNoRequest.map((el, i) => {return {value: el.count, percent: (el.count / dataTotal[i].count *100).toFixed(2) }});
        const dataWithRequest = usersWithRequest.map((el, i) => {return {value: el.count, percent: (el.count / dataTotal[i].count *100).toFixed(2) }});

        option.series[0].data = dataNoRequest;
        option.series[1].data = dataWithRequest;
        option.xAxis.data = xAxisLegend;
        return option;
    }

    const getUsersOfRangeOptions = () => {
        let option = {
            tooltip: {
              trigger: 'item',
              formatter: (value) => value.name + ' : ' + (value.value *100).toFixed(2) + '%'
            },
            legend: {
              top: '5%',
              left: 'center'
            },
            series: [
                {
                    name: 'Utilisateur',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    labelLine: { show: false },
                    data: []
                }
            ]
        };
        if (!usersOfRange) {
            return option;
        };
        option.series[0].data = [
            { value: usersOfRangeNoRequest / usersOfRange, name: 'Aucune Requête',  itemStyle: {color: '#b5b5b5'} },
            { value: ( usersOfRange - usersOfRangeNoRequest) / usersOfRange, name: 'Avec Requêtes',  itemStyle: {color: '#54d2d2'}, },
        ]
       return option;
    };

    return (
        <div className='user-analytics'>
             <Modal
                show={loading}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="modal-spinner"
            >
                <span className="loader" />
                <p>Récupération des données en cours...</p>
               
            </Modal>
            <Panel className='by-year'>
                <Panel.Heading>
                    <h6>Aperçu par année {'  '}
                        <select value={year} name='year' onChange={handleYearChange}>
                            {getYear().map(el=><option key={el} value={el}>{el}</option>)}
                        </select>
                    </h6>    
                </Panel.Heading>
                <Panel.Body>
                    <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Row>
                                <h2>Total</h2>
                                <p className='total'>{(numberUsersNoRequest + numberUsersWithRequest).toLocaleString()}</p>
                            </Row>
                            <Row>
                                <table className="table table-hover mb-0 user-table">
                                    <thead>
                                        <tr>
                                            <th>Aucune Requête</th>
                                            <th>Avec Requête</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{numberUsersNoRequest.toLocaleString()}</td>
                                            <td>{numberUsersWithRequest.toLocaleString()}</td>
                                        </tr>
                                        <tr>
                                            <td>{(numberUsersNoRequest/(numberUsersNoRequest + numberUsersWithRequest) *100).toFixed(2)} %</td>
                                            <td>{(numberUsersWithRequest/(numberUsersNoRequest + numberUsersWithRequest) *100).toFixed(2)} %</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>                          
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <ReactEcharts
                                option={getUsersOptions()}
                                style={{ height: '350px', width: '100%' }}
                            />  
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>

            <Panel className='by-period'>
                <Panel.Heading className='heading'>
                    <div>
                        <h6> Aperçu     {'  '} </h6>
                        <DateRangePicker onChange={value => setRange(value)} value={range} maxDate={new Date()}/>
                       
                        <h6> par  rapport   {'  '}</h6>
                        <Select
                            value={comparison}
                            onChange={(v) => setComparison(v)}
                            options={comparisonArr}
                            styles={{ option: (s) => ({
                                ...s,
                                color: 'black'
                            })}}
                            role="checkbox"
                            className='filter-status'
                        />
                        {
                            comparison.value === 'personalized' &&
                            <DateRangePicker onChange={value => setRangeCompare(value)} value={rangeCompare} maxDate={new Date()}/>
                        } 
                    </div>
                   
                    <button className='submit-button' onClick={() => {
                        countUserOfToday();
                    }}>
                        Chercher
                    </button>
                </Panel.Heading>
                <Panel.Body>
                <Row>
                        <Col lg={4} md={4} sm={12} xs={12}>
                            <Row>
                                <h2>Total</h2>
                                <p className='total'>{usersOfRange.toLocaleString()}</p>
                            </Row>
                            <Row>
                                <table className="table table-hover mb-0 user-table">
                                    <thead>
                                        <tr>
                                            <th>Aucune Requête</th>
                                            <th>Avec Requête</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{usersOfRangeNoRequest.toLocaleString()} ({(usersOfRangeNoRequest / usersOfRange *100).toFixed(2)} % )</td>
                                            <td>{(usersOfRange - usersOfRangeNoRequest).toLocaleString()} ({(100 - (usersOfRangeNoRequest / usersOfRange *100)).toFixed(2)} %)</td>
                                        </tr>
                                        <tr className='compare'>
                                            <td>{usersOfRangeNoRequestCompare.toLocaleString()} ({(usersOfRangeNoRequestCompare / usersOfRangeCompare *100).toFixed(2)} % )</td>
                                            <td>{(usersOfRangeCompare - usersOfRangeNoRequestCompare).toLocaleString()} ({(100 - (usersOfRangeNoRequestCompare / usersOfRangeCompare *100)).toFixed(2)} %)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Row>                          
                        </Col>
                        <Col lg={8} md={8} sm={12} xs={12}>
                            <ReactEcharts
                                option={getUsersOfRangeOptions()}
                                style={{ height: '350px', width: '100%' }}
                            />   
                        </Col>
                    </Row>
                </Panel.Body>
            </Panel>
        </div>
        
    );
};

export default UserAnalytics;
