import React from 'react';
import { toCamelCase } from '../../Constants';
import dateFormat from 'dateformat';

const RequestsTable = props => {
    const doctorName = (request) => {
        if (!props.user) {
            return `${request.userId.FName} ${request.userId.LName}`;
        }

        return request.doctorId  ? `${request.doctorId.FName} ${request.doctorId.LName}` : 'Aucun';
    };

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>{props.user ? 'Docteur' : 'User'}</th>
                    <th>Creation</th>
                    <th>Messages</th>
                    <th>Statut</th>
                </tr>
            </thead>
            <tbody>
                {props.requests.map(request => (
                    <tr
                        key={request._id}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            props.history.push('/requests/detail/' + request._id)
                        }
                    >
                        <td>{toCamelCase(request.type)}</td>
                        <td>
                            {doctorName(request)}
                        </td>
                        <td>{dateFormat(request.createdAt, 'dd/mm/yy HH:MM')}</td>
                        <td>{request.messenger.length}</td>
                        <td>
                            <span className="label label-success">
                                {request.state === "waiting" && request.notOnlineReason ? "Indisponible à distance" : request.state}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default RequestsTable;
