import React from 'react';
import { fetchApi } from '../../Network/NetworkService';
import { notif } from '../Ui/Notification';
import { Grid, Row, Col, Panel, Tabs, Tab } from 'react-bootstrap';
import ProfilePanel from '../Ui/ProfilePanel';
import { feedbackQuestion } from '../../Constants';

const FeedbackData = props => {
    const renderData = () => {
        const arr = props.data;

        return Object.keys(feedbackQuestion).map((v) => {
            const renderResponse = () => {
                const res = arr[v]
                let text = res

                if (Array.isArray(res)) {
                    text = res.length > 0 ? res.join(', ') : res[0]
                }

                return (
                    <p>
                        <strong>
                            {text} {arr[`${v}Other`] ? ` : ${arr[`${v}Other`]}` : ''}
                        </strong>
                        {arr[`${v}Precise`] && <p><strong>Precision : {arr[`${v}Precise`]}</strong></p>}
                    </p>
                )
            }

            return (
                <tr key={v}>
                    <td>
                        <p>{feedbackQuestion[v]}</p>
                        {renderResponse()}
                    </td>
                </tr>
            )
        })
    }

    return (
        <Row>
            <Col md={12}>
                <table className="table">
                    <tbody>
                        {renderData()}
                    </tbody>
                </table>
            </Col>
        </Row>
    )
};

export default class FeedbackDetails extends React.Component {
    constructor() {
        super();

        this.state = {
            feedback: null,
            user: null,
            fname: '',
            lname: '',
            email: '',
        };

    }

    componentDidMount() {
        fetchApi(`/feedback/${this.props.match.params.id}`)
            .then(res => this.setState({ feedback: res.feedback, user: res.feedback.userId, fname: res.feedback.userId.FName, lname: res.feedback.userId.LName, email: res.feedback.userId.email }))
            .catch(err =>
                notif.addNotification(
                    'Erreur',
                    'Erreur de recuperation des donnees docteur: ' + err,
                    'warning'
                )
            );
    }


    render() {
        if (!this.state.feedback) {
            return <div>Loading...</div>;
        }

        return (
            <div className="pT-25">
                <Row>
                    <Col lg={3} xs={12}>
                        <button
                            onClick={() => {
                                if (this.props.history.location.state?.page) {
                                    this.props.history.push({
                                        pathname: this.props.history.location.state.page,
                                        state: this.props.history.location.state
                                    })
                                } else {
                                    this.props.history.goBack()
                                }   
                            }}
                            className="backBtn"
                        >
                            <i className="icon-arrow-left" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} xs={12}>
                        <ProfilePanel isRequest {...this.state.user}>
                            <Grid fluid={true}>
                                <Row>
                                    <Col md={12}>
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>Sexe</td>
                                                    <td>
                                                        {this.state.feedback.userId.gender}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Age</td>
                                                    <td>
                                                        {!this.state.feedback.userId
                                                            .birth_date ||
                                                            new Date(
                                                                new Date() -
                                                                    new Date(
                                                                        this.state.feedback.userId.birth_date
                                                                    )
                                                            ).getFullYear() -
                                                                1970}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Tel</td>
                                                    <td>
                                                        {this.state.feedback.userId.phone}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Email</td>
                                                    <td> 
                                                        <span style={{ fontSize: 12}}>{this.state.feedback.userId.email}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Grid>
                        </ProfilePanel>
                    </Col>
                    <Col lg={9} xs={12}>
                        <Panel>
                            <Panel.Body style={{ paddingTop: 0 }}>
                                <p>Docteur : {this.state.feedback.doctorId.FName} {this.state.feedback.doctorId.LName}</p>
                                <p>Type: {this.state.feedback.type}</p>
                                <FeedbackData
                                    data={this.state.feedback.data}
                                />
                            </Panel.Body>
                        </Panel>
                    </Col>
                </Row>
            </div>
        );
    }
}
