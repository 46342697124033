import React from 'react';
import { ButtonToolbar, MenuItem, Panel, SplitButton } from 'react-bootstrap';

import '../../Assets/Ui/RequestCounter.css';

const UserCounter = (props) => {

    // const typeOptions = [
    //     { value: 'all', label: 'Tous' },
    //     { value: 'new', label: 'Nouveau Clients' },
    //     { value: 'old', label: 'Ancien Clients' },
    // ]

    const numberRequestOptions = [
        { value: 'all', label: 'Tous' },
        { value: 'no-request', label: 'Aucune requête' },
        { value: 'one-request', label: 'Une requête' },
        { value: 'many-request', label: 'Plusieurs requêtes' },
        { value: 'deleted', label: 'Utilisateur supprimé' },
        { value: 'locked', label: 'Utilisateur bloqué' },
    ];

    const handleMonthChange = (e) => {
        props.setMonth(e.target.value);
    };

    // const handleSelectTypeOption = (e) => {
    //     props.setTypeOption(e);
    // }

    const handleSelectNumberRequest = (e) => {
        props.setNumberRequest(e);
    };

    return (
        <Panel>
            <Panel.Heading>
                <h6>Compteur et filtre</h6>
            </Panel.Heading>
            <Panel.Body>
                <div className="counter">
                    <div className="filter-block">
                        <div className="filter-block-user">
                            <div>
                                <input  
                                    max={`${new Date().getFullYear()}-0${new Date().getMonth() + 1}`}
                                    min="2021-01"
                                    name="month" 
                                    onChange={handleMonthChange} 
                                    type="month"
                                    value={props.month}
                                />
                            </div>
                            {/* <div className="select-block">
                                <ButtonToolbar>
                                    <SplitButton
                                        id="option-list"
                                        rootCloseEvent="mousedown"
                                        title={props.typeOption.label}
                                    >   
                                        {
                                            typeOptions.map((v, i) => (
                                                <MenuItem
                                                    eventKey={v}
                                                    active={v.value === props.typeOption.value}
                                                    onSelect={v.value === props.typeOption.value ? undefined : handleSelectTypeOption}
                                                    key={`item-${i}`}
                                            >
                                                {v.label}
                                            </MenuItem>
                                        ))
                                    }
                                </SplitButton>
                                </ButtonToolbar>
                            </div> */}

                            <div className="select-block">
                                <ButtonToolbar>
                                    <SplitButton
                                        id="option-list"
                                        rootCloseEvent="mousedown"
                                        title={props.numberRequest.label}
                                    >   
                                        {
                                            numberRequestOptions.map((v, i) => (
                                                <MenuItem
                                                    eventKey={v}
                                                    active={v.value === props.numberRequest.value}
                                                    onSelect={v.value === props.numberRequest.value ? undefined : handleSelectNumberRequest}
                                                    key={`item-${i}`}
                                                >
                                                    {v.label}
                                                </MenuItem>
                                            ))
                                        }
                                    </SplitButton>
                                </ButtonToolbar>
                            </div>
                        </div>
                    </div>

                    <div className="nb-block">
                        <Panel>
                            <Panel.Heading>
                                <h3>Total</h3>
                                {props.loading ? (
                                    <span className="loader" />
                                ) : (
                                    <h2>{props.count}</h2>
                                )}
                            </Panel.Heading>
                        </Panel>
                    </div>
                </div>
            </Panel.Body>
        </Panel>
    );
};

export default UserCounter;
