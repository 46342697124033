import React from 'react';
import { Link } from 'react-router-dom';

import '../../Assets/Layout/Sidebar.css';

const MENUS = [
    {
        type: 'title',
        text: 'Home'
    },
    {
        type: 'link',
        href: '/',
        name: 'Accueil',
        icon: 'zmdi zmdi-home'
    },
    {
        type: 'group',
        name: 'Docteurs',
        href: '/doctors', // Base path
        icon: 'zmdi zmdi-hospital',
        elements: [
            {
                name: 'Tous',
                href: '/doctors'
            },
            {
                name: 'En attente',
                href: '/doctors/waiting'
            },
            {
                name: 'Disponible',
                href: '/doctors/active'
            }
        ]
    },
    {
        type: 'link',
        href: '/pharmacist',
        name: 'Pharmaciens',
        icon: 'zmdi zmdi-hospital'
    },
    {
        type: 'group',
        href: '/pharmacy',
        name: 'Pharmacies',
        icon: 'zmdi zmdi-hospital',
        elements: [
            {
                name: 'Tous',
                href: '/pharmacy'
            },
            {
                name: 'Pre-inscription',
                href: '/pharmacy/pre-inscri'
            }
        ]
    },
    {
        type: 'link',
        href: '/users',
        name: 'Utilisateurs',
        icon: 'zmdi zmdi-accounts-alt'
    },
    {
        type: 'link',
        href: '/requests',
        name: 'Requetes',
        icon: 'zmdi zmdi-file'
    },
    {
        type: 'link',
        href: '/feedback',
        name: 'Feedback',
        icon: 'zmdi zmdi-email',
    },
    // {
    //     type: 'group',
    //     href: '/tester',
    //     name: 'Testeurs',
    //     icon: 'zmdi zmdi-truck',
    //     elements: [
    //         {
    //             name: 'Tous',
    //             href: '/testers'
    //         },
    //         {
    //             name: 'En attente',
    //             href: '/testers/waiting'
    //         }
    //     ]
    // },
    // {
    //     type: 'group',
    //     href: '/tests',
    //     name: 'Tests',
    //     icon: 'zmdi zmdi-pin',
    //     elements: [
    //         {
    //             name: 'Tous',
    //             href: '/tests'
    //         },
    //         {
    //             name: 'En attente',
    //             href: '/tests/waiting'
    //         },
    //         {
    //             name: 'À approuver',
    //             href: '/tests/toapproved'
    //         }
    //     ]
    // },
    {
        type: 'link',
        href: '/notification',
        name: 'Notification',
        icon: 'zmdi zmdi-notifications'
    },
    {
        type: 'link',
        href: '/newsletters',
        name: 'Newsletters',
        icon: 'zmdi zmdi-email'
    },
    {
        type: 'link',
        href: '/conversion-tunnel',
        name: 'Tunnel de conversion',
        icon: 'zmdi zmdi-chart'
    },
    {
        type: 'group',
        href: '/analytics',
        name: 'Analytique',
        icon: 'zmdi zmdi-chart',
        elements: [
            {
                name: 'Analyse des requetes',
                href: '/analytics/request'
            },       
            {
                name: 'Analyse des utilisateurs',
                href: '/analytics/user'
            },
            {
                name: 'Analyse des médecins',
                href: '/analytics/doctor'
            },
            {
                name: 'Récurrence clients',
                href: '/analytics/customer-value'
            },
            {
                name: 'COHORTE clients',
                href: '/analytics/cohorte-clients'
            },
            {
                name: 'Tracking Annulé',
                href: '/analytics/tracking-cancel'
            },
            {
                name: 'Tracking Incomplète',
                href: '/analytics/tracking-incomplete'
            },
            {
                name: 'Tracking Waiting',
                href: '/analytics/tracking-waiting'
            },
        ]
    },
    {
        type: 'link',
        href: '/campagne-sdr',
        name: 'Campagne SDR',
        icon: 'zmdi zmdi-email'
    },
    {
        type: 'link',
        href: '/acquisition',
        name: 'Acquisition',
        icon: 'zmdi zmdi-arrow-split'
    },
    {
        type: 'link',
        href: '/suivi',
        name: 'Les suivis',
        icon: 'zmdi zmdi-assignment'
    },
];

const MENUS_PARTNER = [
    {
        type: 'title',
        text: 'Home'
    },
    {
        type: 'link',
        href: '/',
        name: 'Accueil',
        icon: 'zmdi zmdi-home'
    },
    {
        type: 'link',
        href: '/requests',
        name: 'Requetes',
        icon: 'zmdi zmdi-file'
    }
];

const MENUS_VISITOR = [
    {
        type: 'title',
        text: 'Home'
    },
    {
        type: 'group',
        href: '/tester',
        name: 'Testeurs',
        icon: 'zmdi zmdi-truck',
        elements: [
            {
                name: 'Tous',
                href: '/testers'
            },
            {
                name: 'En attente',
                href: '/testers/waiting'
            }
        ]
    },
    {
        type: 'group',
        href: '/tests',
        name: 'Tests',
        icon: 'zmdi zmdi-pin',
        elements: [
            {
                name: 'Tous',
                href: '/tests'
            },
            {
                name: 'En attente',
                href: '/tests/waiting'
            },
            {
                name: 'À approuver',
                href: '/tests/toapproved'
            }
        ]
    }
];

const MENUS_ADMIN_FISCALE = [
    {
        type: 'title',
        text: 'Home'
    },
    {
        type: 'link',
        href: '/',
        name: 'Accueil',
        icon: 'zmdi zmdi-home'
    },
    {
        type: 'link',
        href: '/requests',
        name: 'Requetes',
        icon: 'zmdi zmdi-file'
    }
];

class MenuGroup extends React.Component {
    constructor() {
        super();

        this.state = {
            activeMenu: null,
            menuVisible: false
        };
    }

    componentDidMount() {
        const pathName = window.location.pathname;

        let i;
        for (
            i = 0;
            i < this.props.elements.length &&
            this.props.elements[i].href !== pathName;
            i++
        );

        this.setState({ activePage: i });
    }

    render() {
        return (
            <div
                className="link"
                onClick={() =>
                    this.setState({ menuVisible: !this.state.menuVisible })
                }
            >
                <i className={this.props.icon} />
                <span>{this.props.name}</span>
                <ul
                    style={{
                        display: this.state.menuVisible ? 'initial' : 'none'
                    }}
                >
                    {this.props.elements.map((e, i) => {
                        return (
                            <li key={i}>
                                <Link
                                    to={e.href}
                                    onClick={() => {
                                        this.setState({ activePage: i });
                                        this.props.onClick(this.props.i);
                                    }}
                                >
                                    <span>{e.name}</span>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default class Sidebar extends React.Component {
    constructor() {
        super();

        this.state = {
            activePage: null
        };
    }

    componentDidMount() {
        const pathName = window.location.pathname.match(/\/[^/]*/g)[0];

        let i;
        for (i = 0; i < MENUS.length && MENUS[i].href !== pathName; i++);

        this.setState({ activePage: i });
    }

    renderMenu() {
        if (this.props.role === 4) {
            return MENUS_PARTNER;
        }
        if (this.props.role === 5) {
            return MENUS_ADMIN_FISCALE
        }

        return this.props.role >= 2 ? MENUS : MENUS_VISITOR;
    }

    render() {
        return (
            <div className="Sidebar">
                <ul>
                    {this.renderMenu().map((e, i) => {
                        if (e.type === 'title') {
                            return (
                                <li key={i} className="title">
                                    <span>{e.text}</span>
                                </li>
                            );
                        } else if (e.type === 'link') {
                            return (
                                <li
                                    key={i}
                                    className={
                                        this.state.activePage === i
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <Link
                                        to={e.href}
                                        onClick={() =>
                                            this.setState({ activePage: i })
                                        }
                                    >
                                        <i className={e.icon} />
                                        <span>{e.name}</span>
                                    </Link>
                                </li>
                            );
                        } else if (e.type === 'group') {
                            return (
                                <li
                                    key={i}
                                    className={
                                        this.state.activePage === i
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <MenuGroup
                                        {...e}
                                        onClick={() =>
                                            this.setState({ activePage: i })
                                        }
                                    />
                                </li>
                            );
                        }

                        return null;
                    })}
                </ul>
            </div>
        );
    }
}
