import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Layout from './Components/Layout/Layout';
import Login from './Components/Pages/Login';
import { NotificationManager } from './Components/Ui/Notification';

import './Assets/main.css';
import './Assets/theme.css';

class App extends Component {
    render() {
        return (
            <>
                <NotificationManager />
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <Route path="/" component={Layout} />
                    </Switch>
                </BrowserRouter>
            </>
        );
    }
}

export default App;
