import { Modal } from "react-bootstrap"

export const LoadingModal = (isShow) => {
    return <Modal
        show={isShow}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modal-spinner"
    >
        <span className="loader" />
        <p>Récupération des données en cours...</p>
    </Modal>
}