import React from 'react';
import { Panel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import '../../Assets/Ui/ProfilePanel.css';

export default props => (
    <Panel className="ProfilePanel pa-0">
        <Panel.Body className="pa-0">
            <div className="cover" />
            <div className="profileInfo">
                <div className="profileImage">
                    <img src={props.photoProfil || '/assets/images/profile-picture-placeholder.png'} alt="profilePic" />
                </div>
                <h5 className="blue">
                    {props.FName} {props.LName}
                </h5>
                <h6>{props.favoriteSpecialty}</h6>
            </div>
            <div className="infos">
                {props.children}
                {props.isRequest && (
                    <Link to={`/users/detail/${props._id}`}>Voir le profil</Link>
                )}
            </div>
        </Panel.Body>
    </Panel>
);
