import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Col, Panel, Row } from 'react-bootstrap';
import { CardCount } from './Card';
import isoDate from '../../Services/toIsoDate';

const colors = {
    user: {
        main: '#8051A2',
        areaColor: '#8051A245'
    },
    request: {
        main: '#02ce26',
        areaColor: '#0084166b'
    },
    turnover: {
        main: '#D6AE71',
        areaColor: '#D6AE7150'
    },
    visit: {
        main: '#00cdac',
        areaColor: '#3ae0c4c9'
    }
};

const PartnerDashBoard = (props) => {

    const chartOption = () => {
        const options = {
            tooltip: {
                trigger: 'axis'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    name: 'Demandes',
                    type: 'line',
                    smooth: true,
                    data: [],
                    itemStyle: {
                        color: '#D6AE71'
                    },
                    areaStyle: {
                        color: '#D6AE7150'
                    }
                }
            ]
        };
            
        const xAxisLegend = [
            ...new Set([
                ...props.requests.map(
                    ({ _id }) => _id.month?.toString() + '/' + _id.year?.toString()
                )
            ])
        ];
      
        const xAxisData = [];
      
        for (let i = 0; i < xAxisLegend.length; i++) {
            const year = parseInt(xAxisLegend[i].split('/')[1]);
            const month = parseInt(xAxisLegend[i].split('/')[0]);
      
            const reqs = props.requests.filter(
                ({ _id }) => _id.year === year && _id.month === month
            );
      
            if (!reqs) {
                xAxisData.push(0);
            } else {
                xAxisData.push(reqs.map((v) => v.count).reduce((a, b) => a + b, 0));
            }
        }
      
        options.xAxis.data = xAxisLegend;
        options.series[0].data = xAxisData;
      
      
        return options;
    };

    const requestsValid = props.requestsOfToday?.filter(el => el.state !== "waiting")?.length;
    const recurrence =  props.requestsOfToday?.filter(el => new Date (el.userId?.createdAt) < new Date(isoDate(null, 0, 0)) && el.userId?.request.length > 0)?.length;

    return (
        <div>
            {(props.source === 'skycall') && (
                <p style={{ color: 'gray', wordWrap: 'break-word', width: '90%' }}>Votre access token pour votre api : {localStorage.getItem('feeli_admin_token')}</p>
            )}
            <Row>
                <Col lg={8} md={6} sm={12} xs={12}>
                    <Panel>
                        <Panel.Heading>
                            <h6 style={{ color: 'gray', textTransform: 'initial' }}>{`Demandes${!props.requests.length > 0 ? ' : aucun pour le moment !' : ''}`}</h6>
                        </Panel.Heading>
                        <Panel.Body>
                            <ReactEcharts
                                option={chartOption()}
                                style={{ height: '350px', width: '100%' }}
                            />
                        </Panel.Body>
                    </Panel>
                </Col>
            </Row>

            <Row>
                <Col lg={8} md={6} sm={12} xs={12}>
                    <Panel>
                        <Panel.Heading>
                            <h6 style={{ color: 'gray', textTransform: 'initial' }}>Compteur du {new Date().toLocaleDateString()}</h6>
                        </Panel.Heading>
                        <Panel.Body className='today-counter' style={{display: "flex"}}>
                            <div style={{width: "30%", margin:  "10px"}}>
                                <CardCount
                                    number={props.requestsOfToday.length}
                                    subtitle="Total de requêtes"
                                    icon="fa fa-file"
                                    background={colors.user.main}
                                />
                            </div>
                            <div style={{width: "30%", margin:  "10px"}}>
                                <CardCount
                                    number={requestsValid}
                                    subtitle="Requêtes Validés"
                                    icon="fa fa-credit-card"
                                    background={colors.request.main}
                                />
                            </div>
                            <div style={{width: "30%", margin:  "10px"}}>
                                <CardCount
                                    number={recurrence}
                                    subtitle="Récurrence"
                                    icon="fa fa-heart"
                                    background={colors.turnover.main}
                                />
                            </div>
                        </Panel.Body>
                    </Panel>
                </Col>
            </Row>
        </div>
    );
};

export default PartnerDashBoard;
