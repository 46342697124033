import React from 'react';
import ReactEcharts from 'echarts-for-react';

export default class CohortChart extends React.Component {
    constructor() {
        super();

        this.getSubsOptions = this.getSubsOptions.bind(this);
    }

    getSubsOptions() {
        const options = {
            title: {
                text: 'Cohorte Clients'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: this.props.dataType === "demande" ? ['Total de consultations', 'Consultations réalisée par nouveaux clients', 'Consultations réalisée par ancients clients'] : ['Client Mois X', 'Nouveaux Client', 'Récurrent Client']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#878787'
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#878787',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: 12
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    data: []
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#878787'
                        }
                    },
                    axisLabel: {
                        textStyle: {
                            color: '#878787',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: 12
                        }
                    },
                    splitLine: {
                        show: false
                    }
                }
            ],
            series: [
                {
                    name: this.props.dataType === "demande" ? 'Total de consultations' : 'Client Mois X',
                    type: 'line',
                    data: [],
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#09B1D0'
                        }
                    }
                },
                {
                    name: this.props.dataType === "demande" ? 'Consultations réalisée par nouveaux clients' : 'Nouveaux Client',
                    type: 'line',
                    data: [],
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#8051A2'
                        }
                    }
                },
                {
                    name: this.props.dataType === "demande" ? 'Consultations réalisée par ancients clients' : 'Récurrent Client',
                    type: 'line',
                    data: [],
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#dfcf5a'
                        }
                    }
                }
            ]
        };;
        const xAxisLegend = this.props.months;

        const xAxisDataAll= Object.keys(this.props.data).map(el => this.props.data[el].result.reduce((acc, val) => acc + val, 0));
        const xAxisDataNew = Object.keys(this.props.data).map(el => this.props.data[el].total);
        const xAxisDataOld = Object.keys(this.props.data).map(el => this.props.data[el].result.reduce((acc, val) => acc + val, 0) - this.props.data[el].total);


        options.xAxis[0].data = xAxisLegend;
        options.series[0].data = xAxisDataAll;
        options.series[1].data = xAxisDataNew;
        options.series[2].data = xAxisDataOld;

        return JSON.parse(JSON.stringify(options));
    }

    render() {   
        return (
            <div className="pT-25">
                    <div>     
                        <ReactEcharts
                            option={this.getSubsOptions()}
                            style={{ height: '500px', width: '100%' }}
                        />           
                    </div>
            </div>
        );
    }
}
