import React from 'react';
import { Link } from 'react-router-dom';

import '../../Assets/Layout/Header.css';

import AuthService from '../../Services/AuthService';

export default class Header extends React.Component {
    render() {
        return (
            <div className="Header">
                <Link to="/" className="logo">
                    <img src="/assets/images/logo_feeli_color.png" alt="Logo" />
                    <span>Dashboard</span>
                </Link>
                <div className="right">
                    <ul>
                        <li onClick={() => {
                            new AuthService().logout();
                            this.props.history.push('/login');
                        }} style={{ cursor: 'pointer' }}>
                            Deconnexion
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}
